import transport from './transport'

async function fetchNotifications(includeSilent, createdAt, sort) {
  const url = `/notifications`
  const response = await transport.get(url, {
    params: { includeSilent, createdAt, sort },
  })
  return response.data.items
}

async function readNotification(eventId) {
  const url = `/notifications/${eventId}/read`
  const response = await transport.post(url)

  return response.data
}

export default { fetchNotifications, readNotification }
