import { createStore, createStoreWithoutSagas } from './createStore'

import { fetchFirebaseToken } from './api/session'

import {
  setBaseUrl,
  getBaseUrl,
  registerUnauthorizedCallback,
} from './api/transport'

import {
  actionTypes as annotationActionTypes,
  addAttachments as addAttachmentsAnnotation,
  removeAttachment as removeAttachmentAnnotation,
  selectAttachments,
} from './annotations'

import {
  actionTypes as commentsActionTypes,
  addComment,
  editComment,
  deleteComment,
  requestComments,
  queryResourceStats,
  addReaction,
  removeReaction,
  selectComment,
  selectCommentByUrn,
  selectCommentIds,
  selectCommentsCount,
  selectStats,
} from './comments'

import {
  checkInForResource,
  queryResource,
  selectCheckin,
  fetchCheckinsForResource,
  fetchResourceCheckinsSaga,
  selectResourceIds,
  hasNewSubmission,
  isResourceViewed,
} from './checkins'

import {
  actionTypes as filesActionTypes,
  selectFile,
  isImage,
  selectAssetsByFacetQuery,
  selectFilesByFacetQuery,
  selectAssetsCountByFacetQuery,
  selectFolderData,
  deleteAssets,
  downloadFile,
  downloadFiles,
  queueFilesDownload,
  requestUserAssets,
  requestAssetsFacets,
  selectAssetsFacets,
  selectAssetsFacet,
  selectLastQuery,
  resetQuery,
  requestFiles,
  importAssets,
  createFolder,
  editAsset,
  removeFromFolder,
  moveAssets,
  fetchMissingFiles,
  selectLastCreatedFolderId,
  viewLastCreatedFolder,
} from './files'

import {
  userHasRoles,
  userHasPermission,
  userHasPermissions,
  SESSION_URN,
  PERMISSIONS,
  ROLES,
} from './permissions'

import {
  actionTypes as documentActionTypes,
  requestDocuments,
  requestDocument,
  requestDocumentFacets,
  createDocument,
  copyDocument,
  selectDocuments,
  selectDocumentsCount,
  selectDocument,
  selectDocumentFacets,
  selectDocumentsAreLoading,
  selectDocumentIsLoading,
  selectDocumentFacetsAreLoading,
  selectLastCreatedDocumentId,
  selectDocumentAcls,
  viewLastCreatedDocument,
  deleteDocument,
  updateDocumentFields,
  pinDocument,
  unpinDocument,
  selectCoverImage as selectDocumentCoverImage,
  selectLastCreatedType,
  forwardDocument,
  submitSketchDocument,
} from './documents'

import {
  sendReminder,
  selectAllTaskIds,
  selectAllTasks,
  isTaskViewed,
  actionTypes as taskActionTypes,
} from './tasks'

import {
  add,
  edit,
  remove,
  requestTaskNotes,
  selectNote,
  selectTaskNotes,
} from './taskNotes'

import {
  requestEvents,
  selectEvents,
  selectEventsCount,
  actionTypes as eventsActionTypes,
} from './events'

import {
  requestGroups,
  registerRootGroup,
  selectGroup,
  selectGroupName,
  selectGroupsByParent,
  selectRootGroup,
  selectAll,
} from './groups'

import {
  actionTypes as usersActionTypes,
  currentUserBelongsToGroup,
  requestUsers,
  resetQuery as resetUsersQuery,
  fetchMissingUsers,
  selectAllUserIds,
  selectAllUsers,
  selectUser,
  selectUserName,
  selectUsersByQuery,
  selectUsersCountByQuery,
  updateAvatar,
  updateUserDetails,
} from './users'

import { requestRoles, selectRoles, selectRoleById } from './roles'

import {
  associateTag,
  selectTagIdsByType,
  selectTag,
  createTag,
  selectResourceTagIdsByType,
  unassociateTag,
  requestTags,
  selectTags,
  selectLastCreatedTagId,
  viewLastCreatedTag,
} from './tags'

import { selectIsEnabled } from './features'

import {
  start,
  selectCurrentUser,
  selectCurrentGroupId,
  selectCurrentUserId,
  selectRemoteLogoutUrl,
  selectHasFederatedLogout,
} from './session'

import {
  selectLists,
  selectList,
  selectListsByQuery,
  requestLists,
  actionTypes as distributionListActionTypes,
  saveList,
  editList,
  deleteList,
  fetchRecipients,
} from './distributionLists'

import {
  saga,
  fetchTemplatesFromApi,
  fetchTemplateFromApi,
  requestTemplates,
  reducer,
  createTemplate,
  requestTemplate,
  actionTypes as templatesActionTypes,
  selectTemplatesByQuery,
  selectTemplateCountByQuery,
  selectTemplateById,
  selectLastCreatedTemplateId,
  selectTemplateAudience,
  selectTemplateApprovalType,
  renameTemplate,
  deleteTemplate,
  isTemplateViewed,
  isCurrentUserTemplateCreator,
  createDocumentFromTemplate,
} from './templates'

import { generateUrn, parseUrn } from './helpers/urn'

import { createQueryKey, normaliseId } from './utils'

import {
  actionTypes as configActionTypes,
  configLoaded,
  selectAppVersion,
} from './config'

import {
  bootstrap as requestBootstrap,
  isComplete,
  isError,
  selectBootstrapTime,
  actionTypes as bootstrapActionTypes,
} from './bootstrap'

import {
  actionTypes as markupsActionTypes,
  createNewMarkup,
  removeNewMarkup,
  selectNewMarkup,
  selectMarkups,
  extractMarkupPoint,
} from './markups'

import {
  actionTypes,
  validationTypes,
  createField,
  deleteField,
  updateField,
  createFieldItem,
  deleteFieldItem,
  updateFieldItem,
  reorderFieldItem,
  reorderFields,
  updateAnswer,
  selectFields,
  selectField,
  selectFieldItems,
  selectFieldItem,
  selectHasFieldItemOther,
} from './forms'

import {
  distributeResource,
  actionTypes as distributionActionTypes,
  reducer as distributionReducer,
  updateResourceAudience,
  selectResourceDistribution,
  selectDistributionBreakdown,
  isDistributed,
  scheduleResource,
  updateDistributionFields,
  selectAssignmentViewers,
  requestAssignmentViewers,
} from './distribution'
import {
  resourceTypes,
  selectFacet,
  selectFacets,
  selectResourceTypeById,
  selectResource,
  selectResources,
  selectResourceCount,
  notifyResource,
  fetchResources,
  fetchFacets,
  actionTypes as resourceActionTypes,
} from './resource'

import {
  actionTypes as notificationActionTypes,
  notificationsUpdate,
  readNotification,
  loadNotifications,
  requestNotifications,
  selectNotifications,
  selectNotification,
  selectNotificationsCount,
} from './notifications'

import { requestDashboard, selectDashboard } from './reports'

import {
  actionTypes as complianceActionTypes,
  startCompletion,
  completeCompliance,
  selectComplianceStatus,
  selectComplianceCancellationReason,
  selectComplianceWorkflow,
  selectIncompleteComplianceAssignees,
  cleanupCompliance,
  selectComplianceApprovers,
  selectComplianceApprovalType,
  selectComplianceReadOnlyApprovers,
  selectCompletions,
  selectActions,
  selectFilteredCompletions,
  selectCompletionsByGroup,
  selectCompletion,
  selectCompletionApprovers,
  selectCompletionReadOnlyApprovers,
  selectComplianceEarliestSubmissionInReview,
  selectComplianceBreakdown,
  selectComplianceBreakdownFacets,
  selectRelevantCompletion,
  updateCompletionApprovers,
  addCompletionAttachments,
  removeCompletionAttachment,
  unassignCompletion,
  completeCompletion,
  updateApprovers,
  submitCompletion,
  approveCompletion,
  rejectCompletion,
  cancelCompletion,
  cancelCompliance,
  selectComplianceStartedAt,
  selectComplianceCompletedAt,
  selectCompletionUploads,
  selectAllBreakdownUploads,
  requestBreakdownUploads,
  selectFormValidation,
  selectComplianceProgress,
  updateComplianceField,
} from './compliance'
import { workflows } from './workflows'
import {
  actionTypes as connectActionTypes,
  requestCode,
  checkIn,
  expireConnection,
  requestConnectedUsers,
  requestConnectedGroups,
  selectConnectCode,
  selectConnectedUsers,
  selectConnectedGroups,
  selectLastConnectedGroup,
} from './connect'

const bootstrap = {
  requestBootstrap,
  isComplete,
  isError,
  selectBootstrapTime,
  actionTypes: bootstrapActionTypes,
}

const api = {
  fetchFirebaseToken,
  setBaseUrl,
  getBaseUrl,
  registerUnauthorizedCallback,
}

const session = {
  start,
  selectCurrentUser,
  selectCurrentGroupId,
  selectCurrentUserId,
  selectRemoteLogoutUrl,
  selectHasFederatedLogout,
}

const features = {
  selectIsEnabled,
}

const files = {
  actionTypes: filesActionTypes,
  selectFile,
  isImage,
  selectAssetsByFacetQuery,
  selectFilesByFacetQuery,
  selectAssetsCountByFacetQuery,
  selectFolderData,
  deleteAssets,
  downloadFile,
  downloadFiles,
  queueFilesDownload,
  requestUserAssets,
  requestAssetsFacets,
  selectAssetsFacets,
  selectAssetsFacet,
  selectLastQuery,
  resetQuery,
  requestFiles,
  importAssets,
  createFolder,
  editAsset,
  removeFromFolder,
  moveAssets,
  fetchMissingFiles,
  selectLastCreatedFolderId,
  viewLastCreatedFolder,
}

const permissions = {
  userHasRoles,
  userHasPermission,
  userHasPermissions,
  SESSION_URN,
  PERMISSIONS,
  ROLES,
}

const groups = {
  requestGroups,
  registerRootGroup,
  selectGroup,
  selectGroupName,
  selectGroupsByParent,
  selectRootGroup,
  selectAll,
}

const users = {
  actionTypes: usersActionTypes,
  currentUserBelongsToGroup,
  requestUsers,
  resetQuery: resetUsersQuery,
  fetchMissingUsers,
  selectAllUserIds,
  selectAllUsers,
  selectUser,
  selectUserName,
  selectUsersByQuery,
  selectUsersCountByQuery,
  updateAvatar,
  updateUserDetails,
}

const distributionLists = {
  actionTypes: distributionListActionTypes,
  saveList,
  selectLists,
  selectList,
  selectListsByQuery,
  requestLists,
  editList,
  deleteList,
  fetchRecipients,
}

const templates = {
  actionTypes: templatesActionTypes,
  saga,
  fetchTemplatesFromApi,
  fetchTemplateFromApi,
  requestTemplate,
  requestTemplates,
  reducer,
  selectTemplatesByQuery,
  selectTemplateCountByQuery,
  selectTemplateById,
  selectLastCreatedTemplateId,
  selectTemplateAudience,
  createTemplate,
  selectTemplateApprovalType,
  renameTemplate,
  deleteTemplate,
  isTemplateViewed,
  isCurrentUserTemplateCreator,
  createDocumentFromTemplate,
}

const roles = {
  requestRoles,
  selectRoles,
  selectRoleById,
}

const tags = {
  associateTag,
  selectTagIdsByType,
  selectTag,
  createTag,
  selectResourceTagIdsByType,
  unassociateTag,
  requestTags,
  selectTags,
  selectLastCreatedTagId,
  viewLastCreatedTag,
}

const comments = {
  actionTypes: commentsActionTypes,
  addComment,
  editComment,
  deleteComment,
  requestComments,
  queryResourceStats,
  addReaction,
  removeReaction,
  selectComment,
  selectCommentByUrn,
  selectCommentIds,
  selectCommentsCount,
  selectStats,
}

const taskNotes = {
  requestTaskNotes,
  edit,
  add,
  remove,
  selectNote,
  selectTaskNotes,
}

const annotations = {
  actionTypes: annotationActionTypes,
  addAttachments: addAttachmentsAnnotation,
  removeAttachment: removeAttachmentAnnotation,
  selectAttachments,
}

const documents = {
  actionTypes: documentActionTypes,
  requestDocuments,
  requestDocument,
  requestDocumentFacets,
  createDocument,
  copyDocument,
  selectDocuments,
  selectDocumentsCount,
  selectDocument,
  selectDocumentFacets,
  selectDocumentsAreLoading,
  selectDocumentIsLoading,
  selectDocumentFacetsAreLoading,
  selectDocumentAcls,
  selectLastCreatedDocumentId,
  viewLastCreatedDocument,
  deleteDocument,
  updateDocumentFields,
  pinDocument,
  unpinDocument,
  selectCoverImage: selectDocumentCoverImage,
  selectLastCreatedType,
  forwardDocument,
  submitSketchDocument,
}

const tasks = {
  sendReminder,
  selectAllTaskIds,
  selectAllTasks,
  isTaskViewed,
  actionTypes: {
    ...taskActionTypes,
  },
}

const checkins = {
  checkInForResource,
  queryResource,
  selectCheckin,
  hasNewSubmission,
  fetchCheckinsForResource,
  fetchResourceCheckinsSaga,
  selectResourceIds,
  isResourceViewed,
}

const helpers = {
  generateUrn,
  parseUrn,
  createQueryKey,
  normaliseId,
}

const config = {
  configActionTypes,
  configLoaded,
  selectAppVersion,
}

const markups = {
  actionTypes: markupsActionTypes,
  createNewMarkup,
  removeNewMarkup,
  selectNewMarkup,
  selectMarkups,
  extractMarkupPoint,
}

const forms = {
  actionTypes,
  validationTypes,
  createField,
  deleteField,
  updateField,
  createFieldItem,
  deleteFieldItem,
  updateFieldItem,
  reorderFieldItem,
  reorderFields,
  updateAnswer,
  selectFields,
  selectField,
  selectFieldItems,
  selectFieldItem,
  selectHasFieldItemOther,
}

const notifications = {
  actionTypes: notificationActionTypes,
  notificationsUpdate,
  readNotification,
  loadNotifications,
  requestNotifications,
  selectNotifications,
  selectNotification,
  selectNotificationsCount,
}

const distribution = {
  distributeResource,
  actionTypes: distributionActionTypes,
  reducer: distributionReducer,
  updateResourceAudience,
  selectResourceDistribution,
  selectDistributionBreakdown,
  isDistributed,
  scheduleResource,
  updateDistributionFields,
  selectAssignmentViewers,
  requestAssignmentViewers,
}

const compliance = {
  actionTypes: complianceActionTypes,
  selectRelevantCompletion,
  completeCompliance,
  updateCompletionApprovers,
  addCompletionAttachments,
  removeCompletionAttachment,
  startCompletion,
  selectComplianceStatus,
  selectComplianceCancellationReason,
  selectComplianceWorkflow,
  selectIncompleteComplianceAssignees,
  cleanupCompliance,
  selectComplianceApprovers,
  selectComplianceApprovalType,
  selectComplianceReadOnlyApprovers,
  selectCompletions,
  selectActions,
  selectFilteredCompletions,
  selectCompletionsByGroup,
  selectCompletion,
  selectCompletionApprovers,
  selectCompletionReadOnlyApprovers,
  selectComplianceEarliestSubmissionInReview,
  selectComplianceBreakdown,
  selectComplianceBreakdownFacets,
  selectComplianceProgress,
  unassignCompletion,
  completeCompletion,
  updateApprovers,
  submitCompletion,
  approveCompletion,
  rejectCompletion,
  cancelCompletion,
  cancelCompliance,
  selectComplianceStartedAt,
  selectComplianceCompletedAt,
  selectCompletionUploads,
  selectAllBreakdownUploads,
  requestBreakdownUploads,
  selectFormValidation,
  updateComplianceField,
}

const reports = {
  requestDashboard,
  selectDashboard,
}

const resource = {
  selectFacet,
  selectFacets,
  selectResourceTypeById,
  selectResource,
  selectResources,
  selectResourceCount,
  notifyResource,
  fetchResources,
  fetchFacets,
  actionTypes: resourceActionTypes,
}

const connect = {
  actionTypes: connectActionTypes,
  requestCode,
  checkIn,
  expireConnection,
  requestConnectedUsers,
  requestConnectedGroups,
  selectConnectCode,
  selectConnectedUsers,
  selectConnectedGroups,
  selectLastConnectedGroup,
}

const events = {
  requestEvents,
  selectEvents,
  selectEventsCount,
  eventsActionTypes,
}

export {
  api,
  bootstrap,
  checkins,
  comments,
  config,
  createStore,
  createStoreWithoutSagas,
  files,
  forms,
  groups,
  helpers,
  documents,
  permissions,
  roles,
  features,
  session,
  taskNotes,
  tasks,
  users,
  markups,
  distributionLists,
  templates,
  notifications,
  distribution,
  resourceTypes,
  compliance,
  workflows,
  reports,
  resource,
  connect,
  events,
  annotations,
  tags,
}
