import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import isAfter from 'date-fns/isAfter'
import parseISODate from 'date-fns/parseISO'
import { get as getProp } from 'lodash'
import { actionTypes as bootstrapActionTypes } from './bootstrap'
import { selectIsEnabled } from './features'
import { actionTypes as groupActionTypes, selectGroup } from './groups'
import { actionTypes as userActionTypes, selectUser } from './users'
import { selectCurrentUserId } from './session'
import connectApi from './api/connect'

export const ACTIVE_CONNECTIONS_CHECK_TIMEOUT = 60000 // 60s

const actionTypes = {
  REQUEST_CONNECT_CODE: 'REQUEST_CONNECT_CODE',
  CONNECT_CODE_REQUEST_FAILED: 'CONNECT_CODE_REQUEST_FAILED',
  CONNECT_CODE_GENERATED: 'CONNECT_CODE_GENERATED',
  CONNECT_CODE_DEACTIVATION_FAILED: 'CONNECT_CODE_DEACTIVATION_FAILED',
  CONNECT_CODE_DEACTIVATED: 'CONNECT_CODE_DEACTIVATED',
  REQUEST_CONNECTION_EXPIRY: 'REQUEST_CONNECTION_EXPIRY',
  REQUEST_GROUP_CHECK_IN: 'REQUEST_GROUP_CHECK_IN',
  GROUP_CHECK_IN_FAILED: 'GROUP_CHECK_IN_FAILED',
  GROUP_CHECKED_IN: 'GROUP_CHECKED_IN',
  REQUEST_CONNECTED_USERS: 'REQUEST_CONNECTED_USERS',
  CONNECTED_USERS_REQUEST_FAILED: 'CONNECTED_USERS_REQUEST_FAILED',
  CONNECTED_USERS_LOADED: 'CONNECTED_USERS_LOADED',
  REQUEST_CONNECTED_GROUPS: 'REQUEST_CONNECTED_GROUPS',
  CONNECTED_GROUPS_REQUEST_FAILED: 'CONNECTED_GROUPS_REQUEST_FAILED',
  CONNECTED_GROUPS_LOADED: 'CONNECTED_GROUPS_LOADED',
  USER_CONNECTED: 'USER_CONNECTED',
  CONNECT_CODE_EXPIRED: 'CONNECT_CODE_EXPIRED',
}

const requestCode = params => ({
  type: actionTypes.REQUEST_CONNECT_CODE,
  params,
})

const checkIn = code => ({
  type: actionTypes.REQUEST_GROUP_CHECK_IN,
  code,
})

const expireConnection = userId => ({
  type: actionTypes.REQUEST_CONNECTION_EXPIRY,
  userId,
})

const requestConnectedUsers = query => ({
  type: actionTypes.REQUEST_CONNECTED_USERS,
  query,
})

const requestConnectedGroups = () => ({
  type: actionTypes.REQUEST_CONNECTED_GROUPS,
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CONNECT_CODE_GENERATED: {
      return {
        ...state,
        code: action.code,
      }
    }

    case actionTypes.CONNECTED_USERS_LOADED: {
      return {
        ...state,
        users: action.items,
      }
    }

    case actionTypes.CONNECTED_GROUPS_LOADED: {
      return {
        ...state,
        groups: action.items,
      }
    }

    case actionTypes.GROUP_CHECKED_IN: {
      const { code, group, managedBy, expiresAt } = action
      return {
        ...state,
        lastConnectedGroup: {
          ...group,
          code,
          managedBy,
          expiresAt,
        },
      }
    }

    case actionTypes.USER_CONNECTED: {
      const { code, groupId, userId } = action
      return {
        ...state,
      }
    }

    case actionTypes.CONNECT_CODE_EXPIRED: {
      return {
        ...state,
        groups: (state.groups || []).filter(gId => gId !== action.groupId),
      }
    }

    case actionTypes.CONNECT_CODE_DEACTIVATED: {
      return {
        ...state,
        users: (state.users || []).filter(uId => uId !== action.userId),
      }
    }

    default:
      return state
  }
}

const selectConnectCode = state => getProp(state, 'connect.code')
const selectConnectedUsers = state => getProp(state, 'connect.users')
const selectConnectedGroups = state => getProp(state, 'connect.groups')
const selectConnectedGroup = (state, id) => getProp(state, `connect.${id}`)
const selectLastConnectedGroup = state =>
  getProp(state, 'connect.lastConnectedGroup')

function* requestCodeSaga({ params }) {
  const { expiresAt, canViewGroup, codeExpiresAt, userId, groupId } = params
  try {
    const code = yield call(connectApi.generateCode, {
      expiresAt,
      canViewGroup,
      codeExpiresAt,
      userId,
      groupId,
    })
    yield put({
      type: actionTypes.CONNECT_CODE_GENERATED,
      code,
      canViewGroup,
    })
  } catch (error) {
    yield put({
      type: actionTypes.CONNECT_CODE_REQUEST_FAILED,
      params,
      error,
    })
  }
}

function* checkInSaga({ code }) {
  try {
    const { group, createdBy: managedBy, createdAt, expiresAt } = yield call(
      connectApi.checkIn,
      code,
    )
    yield put({
      type: actionTypes.GROUP_CHECKED_IN,
      code,
      group,
      managedBy,
      createdAt,
      expiresAt,
    })
  } catch (error) {
    yield put({
      type: actionTypes.GROUP_CHECK_IN_FAILED,
      code,
      error,
    })
  }
}

function* checkGroupConnectionExpiry(groupId) {
  try {
    const connectedGroup = yield select(selectGroup, groupId)
    if (
      connectedGroup &&
      isAfter(new Date(), parseISODate(connectedGroup.expiresAt))
    ) {
      const userId = yield select(selectCurrentUserId)
      yield put({
        type: actionTypes.CONNECT_CODE_EXPIRED,
        userId,
        code: connectedGroup.connectCode,
        groupId,
      })
    }
  } catch (error) {
    console.error('Error checking group connection expiration', error)
  }
}

function* checkUserConnectionExpiry(userId) {
  try {
    const connectedUser = yield select(selectUser, userId)
    if (
      connectedUser &&
      isAfter(new Date(), parseISODate(connectedUser.expiresAt))
    ) {
      yield put({
        type: actionTypes.CONNECT_CODE_DEACTIVATED,
        code: connectedUser.connectCode,
        userId,
      })
    }
  } catch (error) {
    console.error('Error checking user connection expiration', error)
  }
}

// checks periodically if connections have expired in order to refresh the UI, only if connect is enabled
// (prevent stale data from a separate tenant to be kept on the page more for longer than needed)
function* checkConnections() {
  const isConnectEnabled = yield select(selectIsEnabled, 'connect')
  if (!isConnectEnabled) return
  try {
    const connectedGroupsIds = yield select(selectConnectedGroups)
    if (connectedGroupsIds?.length) {
      yield all(
        connectedGroupsIds.map(id => fork(checkGroupConnectionExpiry, id)),
      )
    }

    const connectedUsersIds = yield select(selectConnectedUsers)
    if (connectedUsersIds?.length) {
      yield all(
        connectedUsersIds.map(id => fork(checkUserConnectionExpiry, id)),
      )
    }
  } catch (error) {
    console.error('error while checking connections expiration', error)
  } finally {
    yield delay(ACTIVE_CONNECTIONS_CHECK_TIMEOUT) // wait
    yield fork(checkConnections)
  }
}

function* expireUserConnectionSaga({ userId }) {
  const user = yield select(selectUser, userId)
  const code = user.connectCode
  try {
    yield call(connectApi.deactivateCode, code)
    yield put({
      type: actionTypes.CONNECT_CODE_DEACTIVATED,
      code,
      userId,
    })
    yield put({
      type: userActionTypes.USER_DELETED,
      userId: user._id,
    })
  } catch (error) {
    yield put({
      type: actionTypes.CONNECT_CODE_DEACTIVATION_FAILED,
      userId,
      code,
      error,
    })
  }
}

function* fetchConnectedUsersSaga({ query }) {
  try {
    const { items, total } = yield call(connectApi.fetchConnectedUsers, query)
    yield put({
      type: userActionTypes.USERS_LOADED,
      users: items,
      total,
    })
    yield put({
      type: actionTypes.CONNECTED_USERS_LOADED,
      items: items?.map(({ _id }) => _id),
      total,
    })
  } catch (error) {
    yield put({
      type: actionTypes.CONNECTED_USERS_REQUEST_FAILED,
      query,
      error,
    })
  }
}

function* fetchConnectedGroupsSaga() {
  try {
    const { items, total } = yield call(connectApi.fetchConnectedGroups)
    yield put({
      type: actionTypes.CONNECTED_GROUPS_LOADED,
      items: items?.map(({ _id }) => _id),
      total,
    })
    yield put({
      type: groupActionTypes.GROUPS_LOADED,
      groups: items,
      total,
    })
  } catch (error) {
    yield put({
      type: actionTypes.CONNECTED_GROUPS_REQUEST_FAILED,
      error,
    })
  }
}

function* saga() {
  yield takeEvery(actionTypes.REQUEST_CONNECT_CODE, requestCodeSaga)
  yield takeEvery(actionTypes.REQUEST_GROUP_CHECK_IN, checkInSaga)
  yield takeEvery(
    actionTypes.REQUEST_CONNECTION_EXPIRY,
    expireUserConnectionSaga,
  )
  yield takeEvery(actionTypes.REQUEST_CONNECTED_USERS, fetchConnectedUsersSaga)
  yield takeEvery(
    actionTypes.REQUEST_CONNECTED_GROUPS,
    fetchConnectedGroupsSaga,
  )
  yield takeLatest(
    bootstrapActionTypes.APPLICATION_BOOTSTRAPPED,
    checkConnections,
  )
}

const processConnectMessage = message => {
  switch (message?.type) {
    case 'USER_CONNECTED': {
      const { originatedBy: userId, code, groupId } = message.params
      return [
        {
          type: actionTypes.USER_CONNECTED,
          userId,
          code,
          groupId,
        },
      ]
    }

    case 'CONNECT_CODE_EXPIRED': {
      const { originatedBy: userId, code, groupId } = message.params
      return [
        {
          type: actionTypes.CONNECT_CODE_EXPIRED,
          userId,
          code,
          groupId,
        },
      ]
    }
  }
}

export {
  actionTypes,
  requestCode,
  checkIn,
  expireConnection,
  requestConnectedUsers,
  requestConnectedGroups,
  reducer,
  selectConnectCode,
  selectConnectedUsers,
  selectConnectedGroups,
  selectConnectedGroup,
  selectLastConnectedGroup,
  checkConnections,
  checkGroupConnectionExpiry,
  checkUserConnectionExpiry,
  saga,
  requestCodeSaga,
  checkInSaga,
  expireUserConnectionSaga,
  fetchConnectedUsersSaga,
  fetchConnectedGroupsSaga,
  processConnectMessage,
}
