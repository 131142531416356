import { call, put, takeEvery } from 'redux-saga/effects'
import { keyBy, get as getProp } from 'lodash'
import rolesApi from './api/roles'

const ROLES_REQUESTED = 'ROLES_REQUESTED'
const ROLES_LOADED = 'ROLES_LOADED'
const ROLES_REQUEST_FAILED = 'ROLES_REQUEST_FAILED'

const actionTypes = {
  ROLES_REQUESTED,
  ROLES_LOADED,
  ROLES_REQUEST_FAILED,
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case ROLES_LOADED: {
      return {
        ...state,
        ...keyBy(action.roles, g => `${g._id}`),
      }
    }

    default:
      return state
  }
}

const requestRoles = () => ({
  type: ROLES_REQUESTED,
})

const selectRoles = state => (state.roles ? Object.values(state.roles) : [])
const selectRoleById = (state, roleId) => getProp(state, `roles.${roleId}`)

function* fetchRolesFromApi() {
  try {
    const roles = yield call(rolesApi.fetchRoles)
    yield put({
      type: ROLES_LOADED,
      roles,
    })
  } catch (e) {
    yield put({
      type: ROLES_REQUEST_FAILED,
      error: e.message,
    })
  }
}

function* saga() {
  yield takeEvery(ROLES_REQUESTED, fetchRolesFromApi)
}

export {
  actionTypes,
  fetchRolesFromApi,
  reducer,
  requestRoles,
  saga,
  selectRoles,
  selectRoleById,
}
