import { call, put, takeEvery, select } from 'redux-saga/effects'
import { keyBy, omit, get as getProp, union, escapeRegExp } from 'lodash'
import distributionListsApi from './api/distributionLists'
import { fetchMissingUsers } from './users'

const DISTRIBUTION_LISTS_REQUESTED = 'DISTRIBUTION_LISTS_REQUESTED'
const DISTRIBUTION_LISTS_REQUEST_FAILED = 'DISTRIBUTION_LISTS_REQUEST_FAILED'
const DISTRIBUTION_LISTS_LOADED = 'DISTRIBUTION_LISTS_LOADED'
const EDIT_DISTRIBUTION_LIST_REQUESTED = 'EDIT_DISTRIBUTION_LIST_REQUESTED'
const EDIT_DISTRIBUTION_LIST_REQUEST_FAILED =
  'EDIT_DISTRIBUTION_LIST_REQUEST_FAILED'
const DISTRIBUTION_LIST_UPDATED = 'DISTRIBUTION_LIST_UPDATED'
const SAVE_DISTRIBUTION_LIST_REQUESTED = 'SAVE_DISTRIBUTION_LIST_REQUESTED'
const SAVE_DISTRIBUTION_LIST_REQUEST_FAILED =
  'SAVE_DISTRIBUTION_LIST_REQUEST_FAILED'
const DISTRIBUTION_LIST_SAVED = 'DISTRIBUTION_LIST_SAVED'
const DELETE_DISTRIBUTION_LIST_REQUESTED = 'DELETE_DISTRIBUTION_LIST_REQUESTED'
const DELETE_DISTRIBUTION_LIST_REQUEST_FAILED =
  'DELETE_DISTRIBUTION_LIST_REQUEST_FAILED'
const DISTRIBUTION_LIST_DELETED = 'DISTRIBUTION_LIST_DELETED'
const FETCH_RECIPIENTS_REQUESTED = 'FETCH_RECIPIENTS_REQUESTED'
const RECIPIENTS_LOADED = 'RECIPIENTS_LOADED'
const FETCH_RECIPIENTS_REQUEST_FAILED = 'FETCH_RECIPIENTS_REQUEST_FAILED'

const actionTypes = {
  DISTRIBUTION_LISTS_REQUESTED,
  DISTRIBUTION_LISTS_REQUEST_FAILED,
  DISTRIBUTION_LISTS_LOADED,
  EDIT_DISTRIBUTION_LIST_REQUESTED,
  EDIT_DISTRIBUTION_LIST_REQUEST_FAILED,
  DISTRIBUTION_LIST_UPDATED,
  SAVE_DISTRIBUTION_LIST_REQUESTED,
  SAVE_DISTRIBUTION_LIST_REQUEST_FAILED,
  DISTRIBUTION_LIST_SAVED,
  DELETE_DISTRIBUTION_LIST_REQUESTED,
  DELETE_DISTRIBUTION_LIST_REQUEST_FAILED,
  DISTRIBUTION_LIST_DELETED,
  FETCH_RECIPIENTS_REQUESTED,
  RECIPIENTS_LOADED,
  FETCH_RECIPIENTS_REQUEST_FAILED,
}
// reducer
const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DISTRIBUTION_LISTS_LOADED: {
      const listIds = action.distributionLists.map(l => l._id)
      return {
        ...state,
        ...keyBy(
          action.distributionLists.map(l => ({ ...l, type: action.listType })),
          l => l._id,
        ),
        [action.listType]: union(state[action.listType], listIds),
      }
    }

    case actionTypes.DISTRIBUTION_LIST_UPDATED: {
      const { id, name } = action
      return {
        ...state,
        [id]: {
          ...state[id],
          name,
        },
      }
    }

    case actionTypes.DISTRIBUTION_LIST_SAVED: {
      const { distributionList } = action
      return {
        ...state,
        [distributionList._id]: distributionList,
        [distributionList.type]: [
          distributionList._id,
          ...(state[distributionList.type] || []),
        ],
      }
    }

    case actionTypes.DISTRIBUTION_LIST_DELETED: {
      const { id } = action
      return {
        ...omit(state, [id]),
        user: state.user && state.user.filter(listId => listId !== id),
        group: state.group && state.group.filter(listId => listId !== id),
      }
    }
    default:
      return state
  }
}

// action creators
const requestLists = listType => ({
  type: actionTypes.DISTRIBUTION_LISTS_REQUESTED,
  listType,
})

const saveList = (listType, name, recipients) => ({
  type: actionTypes.SAVE_DISTRIBUTION_LIST_REQUESTED,
  listType,
  name,
  recipients,
})

const editList = (id, name) => ({
  type: actionTypes.EDIT_DISTRIBUTION_LIST_REQUESTED,
  id,
  name,
})

const deleteList = id => ({
  type: actionTypes.DELETE_DISTRIBUTION_LIST_REQUESTED,
  id,
})

const fetchRecipients = (listId, onLoaded) => ({
  type: actionTypes.FETCH_RECIPIENTS_REQUESTED,
  listId,
  onLoaded,
})

// selector
const selectLists = (state, listType) =>
  getProp(state, `distributionLists.${listType}`)

const selectList = (state, id) => getProp(state, `distributionLists.${id}`)

const selectListsByQuery = (state, listType, query) => {
  const allLists = selectLists(state, listType)
  if (!query || query === '' || !allLists) return allLists
  return allLists.reduce((acc, id) => {
    const list = selectList(state, id)
    if (
      query
        .trim()
        .split(' ')
        .map(term => new RegExp(escapeRegExp(term), 'ig'))
        .every(term => term.test(list.name))
    ) {
      acc.push(id)
    }
    return acc
  }, [])
}

// sagas
function* fetchListsFromApi({ listType }) {
  try {
    const distributionLists = yield call(
      distributionListsApi.fetchDistributionLists,
      listType,
    )
    const userIds = distributionLists.map(l => l.createdBy)
    yield put({
      type: actionTypes.DISTRIBUTION_LISTS_LOADED,
      distributionLists,
      listType,
    })
    yield put(fetchMissingUsers(userIds))
  } catch (e) {
    yield put({
      type: actionTypes.DISTRIBUTION_LISTS_REQUEST_FAILED,
      error: e.message,
    })
  }
}

function* fetchRecipientsFromApi({ listId, onLoaded }) {
  try {
    const { recipients, type } = yield select(selectList, listId)
    yield put({
      type: actionTypes.RECIPIENTS_LOADED,
      recipients,
      listId,
    })
    if (type === 'user') {
      yield put(fetchMissingUsers(recipients))
    }
    if (onLoaded) onLoaded(recipients)
  } catch (e) {
    yield put({
      type: actionTypes.FETCH_RECIPIENTS_REQUEST_FAILED,
      listId,
      error: e.message,
    })
  }
}

function* editListSaga({ id, name }) {
  try {
    yield call(distributionListsApi.editDistributionList, id, name)
    yield put({
      type: actionTypes.DISTRIBUTION_LIST_UPDATED,
      id,
      name,
    })
  } catch (e) {
    yield put({
      type: actionTypes.EDIT_DISTRIBUTION_LIST_REQUEST_FAILED,
      id,
      name,
      error: e.message,
    })
  }
}

function* deleteListSaga({ id }) {
  try {
    yield call(distributionListsApi.deleteDistributionList, id)
    yield put({
      type: actionTypes.DISTRIBUTION_LIST_DELETED,
      id,
    })
  } catch (e) {
    yield put({
      type: actionTypes.DELETE_DISTRIBUTION_LIST_REQUEST_FAILED,
      id,
      error: e.message,
    })
  }
}

function* saveListSaga({ listType, name, recipients }) {
  try {
    const distributionList = yield call(
      distributionListsApi.saveDistributionList,
      listType,
      name,
      recipients,
    )
    yield put({
      type: actionTypes.DISTRIBUTION_LIST_SAVED,
      distributionList,
    })
  } catch (e) {
    yield put({
      type: actionTypes.SAVE_DISTRIBUTION_LIST_REQUEST_FAILED,
      listType,
      name,
      recipients,
      error: e.message,
    })
  }
}

function* saga() {
  yield takeEvery(actionTypes.DISTRIBUTION_LISTS_REQUESTED, fetchListsFromApi)
  yield takeEvery(actionTypes.EDIT_DISTRIBUTION_LIST_REQUESTED, editListSaga)
  yield takeEvery(
    actionTypes.DELETE_DISTRIBUTION_LIST_REQUESTED,
    deleteListSaga,
  )
  yield takeEvery(actionTypes.SAVE_DISTRIBUTION_LIST_REQUESTED, saveListSaga)
  yield takeEvery(
    actionTypes.FETCH_RECIPIENTS_REQUESTED,
    fetchRecipientsFromApi,
  )
}

export {
  deleteList,
  editList,
  saveList,
  selectLists,
  selectList,
  selectListsByQuery,
  requestLists,
  fetchListsFromApi,
  editListSaga,
  deleteListSaga,
  saveListSaga,
  actionTypes,
  reducer,
  saga,
  fetchRecipientsFromApi,
  fetchRecipients,
}
