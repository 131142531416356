export const rollupDistribution = (items = [], groups) => {
  const rolledUpGroups = items.reduce((acc, item) => {
    const { group, users = [] } = item
    acc[group] = {
      group: groups[group],
      users,
    }
    // rollup the counts for each ancestor group too
    groups[group]?.ancestors?.forEach(ancestorGroup => {
      acc[ancestorGroup] = {
        group: groups[ancestorGroup],
        users: [],
      }
    })
    return acc
  }, {})

  // as a read optimization, sort the child groups for each group in the breakdown
  // and store the sorted list for each parent group.
  // We need all the groups rolled up first
  return Object.entries(rolledUpGroups).reduce((acc, [groupId, value]) => {
    const group = groups[groupId]
    const children = (group?.children || [])
      .filter(id => !!rolledUpGroups[id])
      .map(id => groups[id])
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(g => g._id)
    acc[groupId] = {
      ...value,
      children,
    }
    return acc
  }, {})
}
