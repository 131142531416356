import { get as getProp, union, difference } from 'lodash'
import { RESOURCE_TYPES } from '@concrete/resource'
import {
  ROLES as authRoles,
  PERMISSIONS as permissions,
  WORKFLOW as workflow,
} from '@concrete/authorization'
import { selectConnectedGroups } from './connect'
import { selectCurrentUser, selectCurrentGroupId } from './session'
import { parseUrn } from './helpers/urn'

// To be used in reducers to update permissions
// without a server round-trip

export const setPermission = (permission, resource) => ({
  acls: union(
    getProp(resource, 'acls', []),
    !workflow.includes(permission) ? [permission] : [],
  ),
  workflow: union(
    getProp(resource, 'workflow', []),
    workflow.includes(permission) ? [permission] : [],
  ),
})

export const setPermissions = (perms = [], resource) =>
  perms.reduce((acc, p) => {
    const newPermissions = setPermission(p, acc)
    return newPermissions
  }, resource)

export const removePermission = (permission, resource) => ({
  acls: getProp(resource, 'acls', []).filter(p => p !== permission),
  workflow: getProp(resource, 'workflow', []).filter(p => p !== permission),
})

export const removePermissions = (perms = [], resource) => ({
  acls: difference(getProp(resource, 'acls', []), perms),
  workflow: difference(getProp(resource, 'workflow', []), perms),
})

export const removeAndSetPermissions = (
  toRemove = [],
  toAdd = [],
  resource,
) => {
  const resAfterRemoval = removePermissions(toRemove, resource)
  return setPermissions(toAdd, resAfterRemoval)
}

export const getPermissions = resource => ({
  acls: getProp(resource, 'acls', []),
  workflow: getProp(resource, 'workflow', []),
})

export const userHasPermission = (state, permission, resourceUrn) => {
  const [value] = userHasPermissions(state, [permission], resourceUrn)
  return value
}

export const userHasPermissions = (
  state,
  perms = [],
  resourceUrn,
  completionId,
) => {
  const { type, id } = parseUrn(resourceUrn)
  const resourcePath = getResourcePath(type, id)
  const completionAcls = getProp(state, `completions.${completionId}/acls`, [])
  const docAcls = getProp(state, `documents.${type}.${id}/acls`, [])
  const resourceAcls = completionId ? completionAcls : docAcls
  const assignedTo = getProp(state, `completions.${completionId}.assignedTo`)
  const currentGroups = [
    selectCurrentGroupId(state),
    ...(selectConnectedGroups(state) || []),
  ]
  const distributionItems = getProp(
    state,
    `distribution.${type}.${id}.items`,
    [],
  )
  const distributionItemAcls =
    distributionItems.find(item =>
      assignedTo
        ? item.users?.includes(assignedTo)
        : currentGroups.includes(item.group),
    )?.acl || []

  const templateAcls = getProp(state, `templates.${id}.acl`, [])
  const assetAcls = getProp(state, `files.${id}.acl`, [])
  return perms.map(permission => {
    const acls = getProp(state, `${resourcePath}.acls`, [])
    const workflow = getProp(state, `${resourcePath}.workflow`, [])
    return [
      ...distributionItemAcls,
      ...resourceAcls,
      ...acls,
      ...workflow,
      ...templateAcls,
      ...assetAcls,
    ].includes(permission)
  })
}

function getResourcePath(resourceType, id) {
  if (!id) {
    return resourceType
  }

  switch (resourceType) {
    case RESOURCE_TYPES.ASSETS:
      return `files.${id}`
    default:
      return `${resourceType}.${id}`
  }
}

export const userHasRoles = (state, rolesToCheck = []) => {
  const { roles = [] } = selectCurrentUser(state)
  return rolesToCheck.map(r => roles.includes(r))
}

export const SESSION_URN = 'urn:concrete:session'
export const ROLES = authRoles
export const PERMISSIONS = permissions
