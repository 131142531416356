import { generateUrn, RESOURCE_TYPES } from '@concrete/resource'
import { normaliseId } from '../utils'

const { FORMS, TEMPLATES } = RESOURCE_TYPES

export const mapItemsToProperties = items => {
  return items.reduce(
    (acc, item) => {
      const {
        _id,
        compliance,
        distribution,
        tags: tagItems = [],
        createdBy,
        coverImage,
        type,
      } = item
      const urn = generateUrn(type, _id)

      const document = item
      delete document.distribution
      delete document.compliance
      delete document.tags

      createdBy && acc.userIds.push(createdBy)
      coverImage && acc.files.push(normaliseId(coverImage))
      acc.topics.push(`${type}.${_id}`)

      const assignedUsers = (compliance?.items || []).filter(
        c => !!c.assignedTo,
      )
      distribution &&
        distribution.archivedUsers &&
        acc.userIds.push(...distribution.archivedUsers)
      if (!!assignedUsers.length)
        acc.userIds = [...acc.userIds, ...assignedUsers.map(c => c.assignedTo)]

      acc.documentItems.push(document)
      if (compliance) acc.complianceItems.push(compliance)
      acc.distributionItems.push(distribution)
      acc.tags[urn] = tagItems

      return acc
    },
    {
      userIds: [],
      files: [],
      topics: [],
      tags: {},
      documentItems: [],
      complianceItems: [],
      distributionItems: [],
    },
  )
}

export const keyPropertiesByResourceType = items => {
  return items.reduce(
    (acc, item) => {
      const type = item.type === TEMPLATES ? FORMS : item.type

      acc.itemsKeyedByType[type] = {
        ...acc.itemsKeyedByType[type],
        [item._id]: item,
      }

      acc.aclsKeyedByType[type] = {
        ...acc.aclsKeyedByType[type],
        [`${item._id}/acls`]: item?.acl,
      }

      acc.idsKeyedByType[type] = (acc.idsKeyedByType[type] || []).concat([
        item._id,
      ])

      return acc
    },
    { itemsKeyedByType: {}, aclsKeyedByType: {}, idsKeyedByType: {} },
  )
}
