import { takeEvery, put, call, select } from 'redux-saga/effects'
import { isBefore, parseISO } from 'date-fns'
import { get as getProp } from 'lodash'
import moment from 'moment'
import {
  decodeUrn,
  RESOURCE_STATUSES,
  RESOURCE_TYPES,
} from '@concrete/resource'
import documentsApi from './api/documents'
import resourceApi from './api/resource'
import {
  selectCompletion,
  actionTypes as complianceActionTypes,
} from './compliance'
import { selectAll } from './groups'
import { rollupDistribution } from './helpers/distribution'
import { selectCurrentGroupId } from './session'
import { fetchMissingUsers } from './users'

const { FORMS, TEMPLATES } = RESOURCE_TYPES
const { SCHEDULED } = RESOURCE_STATUSES

const DISTRIBUTE_RESOURCE_REQUESTED = 'DISTRIBUTE_RESOURCE_REQUESTED'
const SCHEDULE_RESOURCE_REQUESTED = 'SCHEDULE_RESOURCE_REQUESTED'
const RESOURCE_DISTRIBUTED = 'RESOURCE_DISTRIBUTED'
const RESOURCE_SCHEDULED = 'RESOURCE_SCHEDULED'
const DISTRIBUTE_RESOURCE_FAILED = 'DISTRIBUTE_RESOURCE_FAILED'
const SCHEDULE_RESOURCE_FAILED = 'SCHEDULE_RESOURCE_FAILED'
const AUDIENCE_UPDATED = 'AUDIENCE_UPDATED'
const UPDATE_AUDIENCE_FAILED = 'UPDATE_AUDIENCE_FAILED'
const UPDATE_RESOURCE_AUDIENCE_REQUESTED = 'UPDATE_RESOURCE_AUDIENCE_REQUESTED'
const DISTRIBUTION_LOADED = 'DISTRIBUTION_LOADED'
const DISTRIBUTED_AUDIENCE_UPDATED = 'DISTRIBUTED_AUDIENCE_UPDATED'
const FACETS_DISTRIBUTION_LOADED = 'FACETS_DISTRIBUTION_LOADED'
const UPDATE_DISTRIBUTION_FIELDS_REQUESTED =
  'UPDATE_DISTRIBUTION_FIELDS_REQUESTED'
const DISTRIBUTION_FIELDS_UPDATED = 'DISTRIBUTION_FIELDS_UPDATED'
const DISTRIBUTION_FIELDS_UPDATE_FAILED = 'DISTRIBUTION_FIELDS_UPDATE_FAILED'
const ASSIGNMENT_VIEWERS_REQUESTED = 'ASSIGNMENT_VIEWERS_REQUESTED'
const ASSIGNMENT_VIEWERS_LOADED = 'ASSIGNMENT_VIEWERS_LOADED'
const ASSIGNMENT_VIEWERS_FAILED = 'ASSIGNMENT_VIEWERS_FAILED'

const actionTypes = {
  DISTRIBUTE_RESOURCE_REQUESTED,
  SCHEDULE_RESOURCE_REQUESTED,
  RESOURCE_DISTRIBUTED,
  RESOURCE_SCHEDULED,
  DISTRIBUTE_RESOURCE_FAILED,
  SCHEDULE_RESOURCE_FAILED,
  AUDIENCE_UPDATED,
  UPDATE_RESOURCE_AUDIENCE_REQUESTED,
  UPDATE_AUDIENCE_FAILED,
  DISTRIBUTION_LOADED,
  DISTRIBUTED_AUDIENCE_UPDATED,
  FACETS_DISTRIBUTION_LOADED,
  UPDATE_DISTRIBUTION_FIELDS_REQUESTED,
  DISTRIBUTION_FIELDS_UPDATED,
  DISTRIBUTION_FIELDS_UPDATE_FAILED,
  ASSIGNMENT_VIEWERS_REQUESTED,
  ASSIGNMENT_VIEWERS_FAILED,
  ASSIGNMENT_VIEWERS_LOADED,
}

const distributeResource = (id, resourceType, commitPayload = {}) => ({
  type: actionTypes.DISTRIBUTE_RESOURCE_REQUESTED,
  id,
  resourceType,
  commitPayload,
})

const scheduleResource = (resourceType, id, commitPayload = {}) => ({
  type: actionTypes.SCHEDULE_RESOURCE_REQUESTED,
  id,
  resourceType,
  commitPayload,
})

const requestAssignmentViewers = (resourceType, resourceId, completionId) => ({
  type: actionTypes.ASSIGNMENT_VIEWERS_REQUESTED,
  resourceType,
  resourceId,
  completionId,
})

const updateResourceAudience = (
  resourceType,
  id,
  groups,
  users,
  distribute = false,
  callback,
  showToast = true,
) => ({
  type: UPDATE_RESOURCE_AUDIENCE_REQUESTED,
  resourceType,
  id,
  groups,
  users,
  distribute,
  callback,
  showToast,
})

const updateDistributionFields = (resourceType, id, fields) => ({
  type: actionTypes.UPDATE_DISTRIBUTION_FIELDS_REQUESTED,
  resourceType,
  id,
  fields,
})

function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.FACETS_DISTRIBUTION_LOADED: {
      const { items, pinned } = action
      const newState = items.reduce((acc, item) => {
        const { urn } = item
        const { resourceType, id } = decodeUrn(urn)

        let type = resourceType
        if (type === TEMPLATES) type = FORMS // TODO: need to sort this out
        if (!!pinned) type = pinned

        acc[type] = {
          ...(state[type] || {}),
          ...acc[type],
          [id]: item,
        }
        return acc
      }, {})
      return {
        ...state,
        ...newState,
      }
    }

    case actionTypes.RESOURCE_DISTRIBUTED:
    case actionTypes.DISTRIBUTION_LOADED: {
      const { resourceId, resourceType, distribution, breakdown } = action
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [resourceId]: distribution,
          [`${resourceId}/breakdown`]: breakdown,
        },
      }
    }

    case actionTypes.DISTRIBUTED_AUDIENCE_UPDATED:
    case actionTypes.AUDIENCE_UPDATED: {
      const { id, resourceType, groups, users, strategy } = action
      const resourceTypeState = state[resourceType] || {}
      const resourceState = resourceTypeState[id] || {}
      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [id]: {
            ...resourceState,
            strategy,
            audience: {
              groups: groups || [],
              users: users || [],
            },
          },
        },
      }
    }

    case actionTypes.ASSIGNMENT_VIEWERS_LOADED: {
      const {
        resourceType,
        resourceId,
        assignedTo,
        group,
        items,
        viewers,
      } = action
      const resourceState = state[resourceType] || {}
      const distribution = {
        ...(resourceState[resourceId] || {}),
      }
      if (assignedTo || group) {
        distribution.items = items?.map(i => {
          if (
            i.users.includes(assignedTo) ||
            (i.group === group && !i.users.length)
          ) {
            i.viewers = viewers
          }
          return i
        })
      } else {
        distribution.viewers = viewers
      }

      return {
        ...state,
        [resourceType]: {
          ...resourceState,
          [resourceId]: distribution,
        },
      }
    }

    case actionTypes.DISTRIBUTION_FIELDS_UPDATED: {
      const { resourceType, id, fields } = action
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [id]: {
            ...state[resourceType][id],
            ...fields,
          },
        },
      }
    }

    default:
      return state
  }
}

// TODO: refactor all generic action creators and selectors to accept the resource/document type
// as the first argument and the id as the second (second and third, respectively, for selectors after the state of course)
const selectResourceDistribution = (state, resourceType, id) =>
  getProp(state, `distribution.${resourceType}.${id}`)

function selectDistributionItem(state, resourceType, id, assignedTo, groupId) {
  const items = getProp(state, `${resourceType}.${id}.items`, [])
  return items?.find(
    i =>
      i.users.includes(assignedTo) || (i.group === groupId && !i.users.length),
  )
}

const selectDistributionBreakdown = (state, resourceType, id) =>
  getProp(state, `distribution.${resourceType}.${id}/breakdown`)

const isDistributed = (state, resourceType, id) => {
  const distribution = getProp(state, `distribution.${resourceType}.${id}`)
  return !!(
    distribution?.sentAt &&
    distribution?.sentBy &&
    isBefore(parseISO(distribution?.sentAt), new Date(Date.now()))
  )
}

const selectAssignmentViewers = (
  state,
  resourceType,
  resourceId,
  completionId,
) => {
  if (completionId) {
    const { assignedTo, group } = getProp(
      state,
      `completions.${completionId}`,
      {},
    )
    const item = selectDistributionItem(
      state.distribution,
      resourceType,
      resourceId,
      assignedTo,
      group,
    )
    return item?.viewers
  }

  return getProp(state, `distribution.${resourceType}.${resourceId}.viewers`)
}

function* distributeSaga({ id, resourceType, commitPayload = {} }) {
  try {
    const groupId = yield select(selectCurrentGroupId)
    const currentDistribution = yield select(
      selectResourceDistribution,
      resourceType,
      id,
    )
    const { audience } = commitPayload
    const { distribution, compliance, acl } = yield call(
      resourceApi.distribute,
      id,
      resourceType,
      audience,
    )
    const groups = yield select(selectAll)
    const breakdown = rollupDistribution(distribution.items, groups)
    yield put({
      type: actionTypes.RESOURCE_DISTRIBUTED,
      resourceId: id,
      resourceType,
      groupId,
      distribution,
      breakdown,
      previousDistribution: currentDistribution,
    })
    if (compliance) {
      yield put({
        type: complianceActionTypes.LOAD_COMPLETIONS_REQUESTED,
        id,
        acl,
        resourceType,
        compliance,
        groups,
      })
    }
    if (commitPayload?.callback) {
      yield call(commitPayload.callback)
    }
  } catch (error) {
    yield put({
      type: actionTypes.DISTRIBUTE_RESOURCE_FAILED,
      id,
      resourceType,
      error,
    })
  }
}

function* scheduleSaga({ id, resourceType, commitPayload }) {
  try {
    const groupId = yield select(selectCurrentGroupId)
    yield call(resourceApi.schedule, resourceType, id)
    yield put({
      type: actionTypes.RESOURCE_SCHEDULED,
      id,
      resourceType,
      groupId,
      commitPayload,
    })
    if (commitPayload?.callback) {
      yield call(commitPayload.callback)
    }
  } catch (error) {
    yield put({
      type: actionTypes.SCHEDULE_RESOURCE_FAILED,
      id,
      resourceType,
      error,
    })
  }
}

function* processUpdateAudience(action) {
  const {
    id,
    groups,
    users,
    resourceType,
    distribute,
    callback,
    showToast,
  } = action
  try {
    const currentDistribution = yield select(
      selectResourceDistribution,
      resourceType,
      id,
    )

    const { distribution, acl } = yield call(
      documentsApi.updateDocumentFields,
      resourceType,
      id,
      { audience: { groups, users } },
    )
    if (distribute) {
      yield put(
        distributeResource(id, resourceType, {
          isDistributed: distribute,
          callback,
        }),
      )
    }
    const type = distribute
      ? actionTypes.DISTRIBUTED_AUDIENCE_UPDATED
      : actionTypes.AUDIENCE_UPDATED
    const { strategy } = distribution
    yield put({
      type,
      id,
      resourceType,
      groups,
      users,
      strategy,
      acl,
      previousAudience: currentDistribution?.audience,
      showToast,
    })
  } catch (e) {
    yield put({
      type: UPDATE_AUDIENCE_FAILED,
      error: e.message,
      id,
      resourceType,
      audience: { groups, users },
    })
  }
}

function* assignmentViewersSaga(action) {
  const { resourceType, resourceId, completionId } = action
  try {
    const viewers = yield call(
      resourceApi.fetchAssignmentViewers,
      resourceType,
      resourceId,
      completionId,
    )
    let completion = {}
    if (completionId) {
      completion = yield select(selectCompletion, completionId)
    }

    const distribution = yield select(
      selectResourceDistribution,
      resourceType,
      resourceId,
    )
    const { items } = distribution || {}

    yield put({
      type: actionTypes.ASSIGNMENT_VIEWERS_LOADED,
      resourceType,
      resourceId,
      assignedTo: completion?.assignedTo,
      group: completion?.group,
      items,
      viewers,
    })

    yield put(fetchMissingUsers(viewers))
  } catch (e) {
    yield put({
      type: ASSIGNMENT_VIEWERS_FAILED,
      error: e.message,
      resourceType,
      resourceId,
      completionId,
    })
  }
}

function* updateDistributionFieldsSaga({ resourceType, id, fields }) {
  try {
    const { distribution, acl } = yield call(
      documentsApi.updateDocumentFields,
      resourceType,
      id,
      fields,
    )
    if (
      distribution?.status === SCHEDULED &&
      !!fields.sentAt &&
      moment().isAfter(moment.utc(fields.sentAt))
    ) {
      yield put(distributeResource(id, resourceType))
    }
    yield put({
      type: actionTypes.DISTRIBUTION_FIELDS_UPDATED,
      resourceType,
      id,
      fields,
      acl,
    })
  } catch (error) {
    yield put({
      type: actionTypes.DISTRIBUTION_FIELDS_UPDATE_FAILED,
      resourceType,
      id,
      error: error.message,
    })
  }
}

function* saga() {
  yield takeEvery(actionTypes.DISTRIBUTE_RESOURCE_REQUESTED, distributeSaga)
  yield takeEvery(actionTypes.SCHEDULE_RESOURCE_REQUESTED, scheduleSaga)
  yield takeEvery(
    actionTypes.UPDATE_RESOURCE_AUDIENCE_REQUESTED,
    processUpdateAudience,
  )
  yield takeEvery(
    actionTypes.UPDATE_DISTRIBUTION_FIELDS_REQUESTED,
    updateDistributionFieldsSaga,
  )
  yield takeEvery(
    actionTypes.ASSIGNMENT_VIEWERS_REQUESTED,
    assignmentViewersSaga,
  )
}

export {
  actionTypes,
  distributeResource,
  scheduleResource,
  distributeSaga,
  scheduleSaga,
  saga,
  reducer,
  updateResourceAudience,
  selectResourceDistribution,
  selectDistributionBreakdown,
  isDistributed,
  processUpdateAudience,
  selectDistributionItem,
  updateDistributionFields,
  updateDistributionFieldsSaga,
  selectAssignmentViewers,
  requestAssignmentViewers,
  assignmentViewersSaga,
}
