import { call, put, select, takeEvery } from 'redux-saga/effects'
import moment from 'moment'
import { get as getProp, union } from 'lodash'
import { FACET_NAMES, TAG_TYPES, RESOURCE_TYPES } from '@concrete/resource'
import eventsApi from './api/events'
import { createQueryKey, removeResourceFromQueryKey } from './utils'
import { actionTypes as tagActionTypes, selectTag } from './tags'
import { actionTypes as documentActionTypes } from './documents'
import { actionTypes as distributionActionTypes } from './distribution'

const { OVERVIEW, ALL } = FACET_NAMES
const { CATEGORIES } = TAG_TYPES
const { EVENTS } = RESOURCE_TYPES

const DEFAULT_END_TIME = '23:59'
const DEFAULT_START_TIME = '00:01'
const DEFAULT_EVENT_DURATION = 60 // minutes

const actionTypes = {
  EVENTS_REQUESTED: 'EVENTS_REQUESTED',
  EVENTS_LOADED: 'EVENTS_LOADED',
  EVENTS_REQUEST_FAILED: 'EVENTS_REQUEST_FAILED',
  EVENT_UPDATED: 'EVENT_UPDATED',
  EVENT_CATEGORY_LOADED: 'EVENT_CATEGORY_LOADED',
  EVENT_CATEGORY_FAILED: 'EVENT_CATEGORY_FAILED',
}

// action creators
const requestEvents = (resourceType, facet, query = null) => ({
  type: actionTypes.EVENTS_REQUESTED,
  resourceType,
  facet,
  query,
})

const updateEvent = (id, fields, categories) => ({
  type: actionTypes.EVENT_UPDATED,
  id,
  fields,
  categories,
})

export const formatEvent = (resourceType, event) => {
  const allDayEvent =
    event.allDay ||
    (event?.allDay === undefined &&
      !event?.startDate &&
      moment(event?.dueAt)
        .utc()
        .local()
        .format('HH:mm') === DEFAULT_END_TIME) ||
    (moment(event?.startDate)
      .utc()
      .local()
      .format('HH:mm') === DEFAULT_START_TIME &&
      moment(event?.endDate)
        .utc()
        .local()
        .format('HH:mm') === DEFAULT_END_TIME)

  const category = event?.tags?.find(c => c?.tagType === CATEGORIES)
  const borderColor = event.borderColor || category?.color
  let start
  if (event?.startDate) start = new Date(event?.startDate)
  else if (event?.dueAt)
    start = moment(event.dueAt)
      .subtract(DEFAULT_EVENT_DURATION, 'minute')
      .toDate()

  const isScheduledEvent =
    !!event?.distribution?.recurrence ||
    event?.distribution?.status === 'scheduled'

  return {
    id: event.id || event._id,
    title: event.title || event.name,
    allDay: allDayEvent,
    start,
    end: event?.endDate ? new Date(event?.endDate) : new Date(event?.dueAt),
    selectable: !isScheduledEvent,
    important: event.important || !!event?.priority,
    resourceType,
    borderColor,
    isDraft: !event?.distribution?.sentAt || isScheduledEvent,
    isRecurring: !!event?.distribution?.recurrence,
  }
}

// reducer
function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.EVENTS_LOADED: {
      const { resourceType, events, total, facet = OVERVIEW, query } = action
      if (!events?.length) return state
      const formattedEvents = {}
      events.forEach(event => {
        formattedEvents[event._id] = formatEvent(resourceType, event)
      })

      let newState = {
        ...state,
        ...formattedEvents,
        all: union(state.all, Object.keys(formattedEvents)),
      }
      if (query) {
        const queryKey = createQueryKey(query)
        newState[`query/${resourceType}/${facet}/${queryKey}/count`] = total
        newState[`query/${resourceType}/${facet}/${queryKey}`] = Object.keys(
          formattedEvents,
        )
        newState.lastQuery = `query/${resourceType}/${facet}/${queryKey}`
      }
      return newState
    }

    case documentActionTypes.DOCUMENT_CREATED: {
      const { resourceType, item } = action
      if (resourceType !== EVENTS) return state
      const { _id } = item
      const query = state.lastQuery
      const newState = {
        ...state,
        [_id]: formatEvent(resourceType, item),
        all: [...(state?.all || []), _id],
        [query]: [...(state?.[query] || []), _id],
        [`${query}/count`]: (state?.[`${query}/count`] || 0) + 1,
      }
      return newState
    }

    case distributionActionTypes.DISTRIBUTED_AUDIENCE_UPDATED: {
      const { id, resourceType } = action
      if (resourceType !== EVENTS) return state
      const newState = {
        ...state,
        [id]: {
          ...state[id],
          isDraft: false,
        },
      }
      return newState
    }

    case actionTypes.EVENT_CATEGORY_LOADED: {
      const { category, id } = action
      const newState = {
        ...state,
        [id]: {
          ...state[id],
          borderColor: category?.color,
        },
      }
      return newState
    }

    case actionTypes.EVENT_UPDATED:
    case documentActionTypes.UPDATE_DOCUMENT_FIELDS_REQUESTED: {
      const { id, fields } = action
      const event = state[id]
      if (!event || !(fields.startDate || fields.endDate || fields.dueDate))
        return state
      const newEvent = { ...event, ...fields }
      return {
        ...state,
        [id]: {
          ...formatEvent(event.resourceType, newEvent),
        },
      }
    }

    case documentActionTypes.DELETE_DOCUMENT_REQUESTED: {
      const { id } = action
      const event = state[id]
      if (!event) return state
      return {
        ...state,
        [id]: undefined,
        ...removeResourceFromQueryKey(state, [ALL, OVERVIEW], id),
      }
    }

    default:
      return state
  }
}

const getQueryKey = (resourceType, query, facet) => {
  const queryKey = createQueryKey(query)
  return `query/${resourceType}/${facet}/${queryKey}`
}

// selectors
const selectEvents = (state, resourceType, query, facet) => {
  const ids =
    getProp(state, ['events', `${getQueryKey(resourceType, query, facet)}`]) ||
    []
  return ids.map(id => getProp(state, `events.${id}`))
}

const selectEventsCount = (state, resourceType, query, facet = OVERVIEW) =>
  getProp(state, ['events', `${getQueryKey(resourceType, query, facet)}/count`])

// sagas
function* fetchEventsSaga(action) {
  const { resourceType, facet, query } = action
  try {
    const { items: events, total } = yield call(
      eventsApi.fetchEvents,
      resourceType,
      query,
      facet,
    )
    yield put({
      type: actionTypes.EVENTS_LOADED,
      resourceType,
      query,
      facet,
      events,
      total,
    })
  } catch (e) {
    yield put({
      type: actionTypes.EVENTS_REQUEST_FAILED,
      query,
      facet,
      error: e.message,
    })
  }
}

function* fetchEventCategoriesSaga(action) {
  const { tagType, tagId, resourceType, resourceId } = action
  if (resourceType !== EVENTS) return null
  try {
    const category = yield select(selectTag, tagType, tagId)
    yield put({
      type: actionTypes.EVENT_CATEGORY_LOADED,
      category,
      id: resourceId,
    })
  } catch (e) {
    yield put({
      type: actionTypes.EVENT_CATEGORY_FAILED,
      tagType,
      tagId,
      error: e.message,
    })
  }
}

function* saga() {
  yield takeEvery(actionTypes.EVENTS_REQUESTED, fetchEventsSaga)
  yield takeEvery(
    tagActionTypes.ASSOCIATE_TAG_REQUESTED,
    fetchEventCategoriesSaga,
  )
}

export {
  requestEvents,
  updateEvent,
  selectEvents,
  selectEventsCount,
  saga,
  fetchEventsSaga,
  fetchEventCategoriesSaga,
  reducer,
  actionTypes,
}
