import transport from './transport'

async function fetchRoles() {
  const url = '/roles'
  const { data } = await transport.get(url)

  const cleanedRoles = data.roles.map(role => ({
    _id: role._id,
    name: role.name,
  }))

  return cleanedRoles
}

export default { fetchRoles }
