import {
  RESOURCE_TYPES,
  RESOURCE_STATUSES,
  FACET_NAMES,
} from '@concrete/resource'
import { takeEvery, throttle, put, call, select } from 'redux-saga/effects'
import { union, keyBy, get as getProp } from 'lodash'
import templatesApi from './api/templates'
import { createQueryKey } from './utils'
import { selectCurrentUserId } from './session'
import { isBefore, parseISO } from 'date-fns'
import { removeQueries } from './helpers/tasks'
import { rollupDistribution } from './helpers/distribution'
import { normaliseForm } from './helpers/forms'
import { normaliseTask } from './helpers/tasks'
import { removeNullValues } from './helpers/templates'
import { selectAll } from './groups'
import { fetchMissingUsers } from './users'
import { selectIsEnabled } from './features'
import { selectCheckin } from './checkins'
import { actionTypes as documentActionTypes } from './documents'
import { actionTypes as distributionActionTypes } from './distribution'
import {
  actionTypes as complianceActionTypes,
  selectComplianceApprovers,
  selectComplianceApprovalType,
} from './compliance'
export const TEMPLATES_THROTTLE_INTERVAL = 500

const { PUBLISHED } = FACET_NAMES

const { FORMS, TEMPLATES, STORIES, TASKS, ANNOUNCEMENTS } = RESOURCE_TYPES
const { TODO } = RESOURCE_STATUSES

// actions
const TEMPLATE_REQUESTED = 'TEMPLATE_REQUESTED'
const TEMPLATE_REQUEST_FAILED = 'TEMPLATE_REQUEST_FAILED'
const TEMPLATES_REQUESTED = 'TEMPLATES_REQUESTED'
const TEMPLATES_REQUEST_FAILED = 'TEMPLATES_REQUEST_FAILED'
const TEMPLATE_LOADED = 'TEMPLATE_LOADED'
const TEMPLATES_LOADED = 'TEMPLATES_LOADED'
const RENAME_TEMPLATE_REQUESTED = 'RENAME_TEMPLATE_REQUESTED'
const RENAME_TEMPLATE_REQUESTED_FAILED = 'RENAME_TEMPLATE_REQUESTED_FAILED'
const TEMPLATE_NAME_UPDATED = 'TEMPLATE_NAME_UPDATED'
const CREATE_TEMPLATE_REQUESTED = 'CREATE_TEMPLATE_REQUESTED'
const TEMPLATE_CREATED = 'TEMPLATE_CREATED'
const CREATE_TEMPLATE_REQUEST_FAILED = 'CREATE_TEMPLATE_REQUEST_FAILED'
const DELETE_TEMPLATE_REQUESTED = 'DELETE_TEMPLATE_REQUESTED'
const DELETE_TEMPLATE_REQUEST_FAILED = 'DELETE_TEMPLATE_REQUEST_FAILED'
const TEMPLATE_DELETED = 'TEMPLATE_DELETED'
const TEMPLATE_DISTRIBUTED = 'TEMPLATE_DISTRIBUTED'
const CREATE_DOCUMENT_FROM_TEMPLATE_REQUESTED =
  'CREATE_DOCUMENT_FROM_TEMPLATE_REQUESTED'
const CREATE_FROM_TEMPLATE_REQUEST_FAILED =
  'CREATE_FROM_TEMPLATE_REQUEST_FAILED'

const actionTypes = {
  TEMPLATE_REQUESTED,
  TEMPLATES_REQUESTED,
  TEMPLATES_REQUEST_FAILED,
  TEMPLATE_REQUEST_FAILED,
  TEMPLATE_LOADED,
  TEMPLATES_LOADED,
  RENAME_TEMPLATE_REQUESTED,
  RENAME_TEMPLATE_REQUESTED_FAILED,
  TEMPLATE_NAME_UPDATED,
  CREATE_TEMPLATE_REQUEST_FAILED,
  TEMPLATE_CREATED,
  DELETE_TEMPLATE_REQUESTED,
  DELETE_TEMPLATE_REQUEST_FAILED,
  TEMPLATE_DELETED,
  TEMPLATE_DISTRIBUTED,
  CREATE_TEMPLATE_REQUESTED,
  CREATE_DOCUMENT_FROM_TEMPLATE_REQUESTED,
  CREATE_FROM_TEMPLATE_REQUEST_FAILED,
}

// reducer
const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TEMPLATES_LOADED: {
      const { templates, query, total, currentUser } = action
      const templateIds = templates.map(t => t._id)
      const normalisedTemplates = templates.map(t =>
        normaliseTask(t, currentUser),
      )
      const newState = {
        ...state,
        ...keyBy(normalisedTemplates, t => t._id),
      }
      const { facet = PUBLISHED } = query
      newState[`query/${facet}/${createQueryKey(query)}`] = union(
        state[`query/${facet}/${createQueryKey(query)}`],
        templateIds,
      )
      newState[`query/${facet}/${createQueryKey(query)}/count`] = total
      return newState
    }

    case actionTypes.TEMPLATE_LOADED: {
      const { template, id, acls } = action
      return {
        ...state,
        [id]: {
          ...template,
        },
        [`${id}/acls`]: acls,
      }
    }

    case actionTypes.TEMPLATE_NAME_UPDATED: {
      const { id, templateName } = action
      return {
        ...state,
        [id]: {
          ...state[id],
          templateName,
        },
      }
    }

    case actionTypes.TEMPLATE_DELETED: {
      const { id } = action
      return Object.keys(state).reduce((acc, key) => {
        if (key.startsWith('query/') && !key.endsWith('/count')) {
          acc[key] = state[key].filter(templateId => templateId !== id)
          acc[`${key}/count`] = acc[key].length
        } else if (id !== key && !key.endsWith('/count')) {
          acc[key] = state[key]
        }
        return acc
      }, {})
    }

    case actionTypes.TEMPLATE_CREATED: {
      const { template } = action
      const newState = removeQueries(state)
      return {
        ...newState,
        lastCreatedFormId: template._id,
        [template._id]: {
          ...template,
        },
      }
    }

    case actionTypes.TEMPLATE_DISTRIBUTED: {
      const { id } = action
      return {
        ...state,
        lastCreatedFormId: undefined,
        [id]: {
          ...state[id],
          isDistributed: true,
          createdBy: state[id].createdBy,
          _id: id,
          status: TODO,
        },
      }
    }

    default:
      return state
  }
}

// action creators

const requestTemplate = id => ({
  type: actionTypes.TEMPLATE_REQUESTED,
  templateId: id,
})

const requestTemplates = query => ({
  type: actionTypes.TEMPLATES_REQUESTED,
  query,
})

const createDocumentFromTemplate = (resourceType, id, callback) => ({
  type: actionTypes.CREATE_DOCUMENT_FROM_TEMPLATE_REQUESTED,
  resourceType,
  templateId: id,
  callback,
})

const renameTemplate = (id, templateName) => ({
  type: actionTypes.RENAME_TEMPLATE_REQUESTED,
  id,
  templateName,
})

const createTemplate = (
  resource,
  distribution,
  templateName,
  commitPayload = {},
) => ({
  type: CREATE_TEMPLATE_REQUESTED,
  resource,
  distribution,
  templateName,
  commitPayload,
})

const deleteTemplate = id => ({
  type: actionTypes.DELETE_TEMPLATE_REQUESTED,
  id,
})

// selectors

const selectTemplatesByQuery = (state, query, facet = 'all') =>
  state.templates[`query/${facet}/${createQueryKey(query)}`]

const selectTemplateById = (state, id) => getProp(state, `templates.${id}`)

const selectLastCreatedTemplateId = state => state.templates.lastCreatedFormId

const selectTemplateCountByQuery = (state, query, facet) =>
  state.templates[`query/${facet}/${createQueryKey(query)}/count`]

const selectTemplateAudience = (state, id) =>
  getProp(state, `templates.${id}.audience`)

const selectTemplateApprovalType = (state, id) =>
  getProp(state, `templates.${id}.approvalType`)

const isTemplateViewed = (state, templateId, dateMarker) => {
  const template = state.templates[templateId]
  if (!template) return false
  const { createdAt } = template
  const checkin = selectCheckin(state, TEMPLATES, templateId)
  const { viewed } = checkin
  return !!viewed || isBefore(parseISO(createdAt), parseISO(dateMarker))
}

const isCurrentUserTemplateCreator = (state, templateId) => {
  const template = state.templates[templateId]
  if (!template) return false
  const currentUserId = selectCurrentUserId(state)
  return currentUserId && template.createdBy === currentUserId
}

// sagas
function* fetchTemplatesFromApi(action) {
  const { query } = action
  const formsEnabled = yield select(selectIsEnabled, FORMS)
  const storiesEnabled = yield select(selectIsEnabled, STORIES)
  const currentUser = yield select(selectCurrentUserId)
  try {
    const { items, total } = yield call(templatesApi.fetchTemplates, query)
    const enabledTypes = [
      formsEnabled && FORMS,
      storiesEnabled && STORIES,
      TASKS,
      ANNOUNCEMENTS,
    ]
    const templates = items.filter(({ type }) => enabledTypes.includes(type))
    const userIds = templates.map(t => t.createdBy)
    yield put({
      type: actionTypes.TEMPLATES_LOADED,
      templates,
      query,
      total,
      currentUser,
    })
    yield put(fetchMissingUsers(userIds))
  } catch (e) {
    yield put({
      type: actionTypes.TEMPLATES_REQUEST_FAILED,
      error: e.message,
      query,
    })
  }
}

function* fetchTemplateFromApi({ templateId }) {
  try {
    const groups = yield select(selectAll)
    const { item, compliance, distribution, acl, _id: resourceId } = yield call(
      templatesApi.fetchTemplate,
      templateId,
    )
    yield put({
      type: actionTypes.TEMPLATE_LOADED,
      template: normaliseForm(item),
      id: resourceId,
      acls: acl,
    })
    const breakdown = rollupDistribution(distribution.items, groups)
    yield put({
      type: distributionActionTypes.DISTRIBUTION_LOADED,
      resourceId,
      resourceType: TEMPLATES,
      breakdown,
      distribution,
    })
    yield put({
      type: complianceActionTypes.LOAD_COMPLETIONS_REQUESTED,
      resourceId,
      resourceType: TEMPLATES,
      compliance,
      groups,
    })
  } catch (e) {
    yield put({
      type: actionTypes.TEMPLATE_REQUEST_FAILED,
      error: e.message,
      templateId,
    })
  }
}

function* createDocumentFromTemplateSaga(action) {
  const { templateId, resourceType, callback } = action
  try {
    const { item, distribution } = yield call(
      templatesApi.utiliseTemplate,
      templateId,
    )
    yield put({
      type: documentActionTypes.DOCUMENT_CREATED_FROM_TEMPLATE,
      item,
      resourceType,
    })
    yield put({
      type: distributionActionTypes.DISTRIBUTION_LOADED,
      resourceId: item._id,
      resourceType,
      distribution,
    })
    if (!!callback) {
      yield call(callback, { resourceType, id: item?._id })
    }
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_FROM_TEMPLATE_REQUEST_FAILED,
      templateId,
      error,
    })
  }
}

function* renameTemplateSaga({ id, templateName }) {
  try {
    yield call(templatesApi.renameTemplate, id, templateName)
    yield put({
      type: actionTypes.TEMPLATE_NAME_UPDATED,
      id,
      templateName,
    })
  } catch (e) {
    yield put({
      type: actionTypes.RENAME_TEMPLATE_REQUESTED_FAILED,
      id,
      templateName,
      error: e.message,
    })
  }
}

function* deleteTemplateSaga({ id }) {
  try {
    yield call(templatesApi.deleteTemplate, id)
    yield put({
      type: actionTypes.TEMPLATE_DELETED,
      id,
    })
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_TEMPLATE_REQUEST_FAILED,
      id,
      error,
    })
  }
}

function* createTemplateSaga(action) {
  const { resource, distribution, templateName, commitPayload } = action
  try {
    const approvers = yield select(
      selectComplianceApprovers,
      resource.type,
      resource._id,
    )
    const approvalType = yield select(
      selectComplianceApprovalType,
      resource.type,
      resource._id,
    )
    const { item: template, _id } = yield call(templatesApi.createTemplate, {
      document: removeNullValues(resource),
      distribution,
      compliance: { approvers, approvalType },
      templateName,
    })
    yield put({
      type: actionTypes.TEMPLATE_CREATED,
      template: {
        ...template,
        _id,
        templateName,
      },
      showToast: commitPayload.showToast,
    })
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_TEMPLATE_REQUEST_FAILED,
      error,
    })
  }
}

function* saga() {
  yield throttle(
    TEMPLATES_THROTTLE_INTERVAL,
    actionTypes.TEMPLATES_REQUESTED,
    fetchTemplatesFromApi,
  )
  yield takeEvery(actionTypes.RENAME_TEMPLATE_REQUESTED, renameTemplateSaga)
  yield takeEvery(actionTypes.CREATE_TEMPLATE_REQUESTED, createTemplateSaga)
  yield takeEvery(actionTypes.DELETE_TEMPLATE_REQUESTED, deleteTemplateSaga)
  yield takeEvery(actionTypes.TEMPLATE_REQUESTED, fetchTemplateFromApi)
  yield takeEvery(
    actionTypes.CREATE_DOCUMENT_FROM_TEMPLATE_REQUESTED,
    createDocumentFromTemplateSaga,
  )
}

export {
  saga,
  fetchTemplatesFromApi,
  fetchTemplateFromApi,
  requestTemplate,
  requestTemplates,
  reducer,
  actionTypes,
  selectTemplatesByQuery,
  selectTemplateById,
  selectLastCreatedTemplateId,
  selectTemplateCountByQuery,
  selectTemplateAudience,
  selectTemplateApprovalType,
  renameTemplate,
  renameTemplateSaga,
  deleteTemplate,
  deleteTemplateSaga,
  isTemplateViewed,
  isCurrentUserTemplateCreator,
  createTemplate,
  createTemplateSaga,
  createDocumentFromTemplate,
  createDocumentFromTemplateSaga,
}
