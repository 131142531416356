import transport from './transport'

async function fetchGroups() {
  const url = '/groups/all'

  const { data } = await transport.get(url)
  return data.groups.map(group => ({
    _id: group._id,
    ancestors: group.ancestors,
    name: group.name,
    belongsTo: group.belongsTo,
    children: group.children,
    isArchived: group.isArchived,
    isStore: !group.children || !group.children.length,
    reportsBoardId: group.reportsBoardId,
  }))
}

export default { fetchGroups }
