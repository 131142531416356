import getProp from 'lodash/get'
import { actionTypes } from './session'

const ENABLE_ALL_FEATURES = 'ENABLE_ALL_FEATURES'

const enableAllFeatures = () => ({
  type: ENABLE_ALL_FEATURES,
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SESSION_STARTED: {
      const featureList = action.user.features || []
      const features = featureList.reduce(
        (acc, feature) => ({
          ...acc,
          [feature]: true,
        }),
        {},
      )

      return {
        ...state,
        ...features,
      }
    }

    case ENABLE_ALL_FEATURES: {
      return {
        ...state,
        all: true,
      }
    }

    default:
      return state
  }
}

function selectIsEnabled(state, feature) {
  const allFeaturesEnabled = getProp(state, 'features.all')
  const thisFeatureEnabled = getProp(state, `features.${feature}`, false)
  return allFeaturesEnabled || thisFeatureEnabled
}

export { enableAllFeatures, reducer, selectIsEnabled }
