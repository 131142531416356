import transport from './transport'

async function createField(urn, field) {
  const url = `/v3/documents/${urn}/fields`
  const response = await transport.post(url, field)
  return response.data
}

async function deleteField(urn, fieldId) {
  const url = `/v3/documents/${urn}/fields/${fieldId}`
  const response = await transport.delete(url)
  return response.data
}

async function updateField(urn, id, key, value) {
  const url = `/v3/documents/${urn}/fields/${id}`
  const response = await transport.patch(url, { [key]: value })
  return response.data
}

async function createItem(urn, fieldId, item) {
  const url = `/v3/documents/${urn}/fields/${fieldId}/items`
  const response = await transport.post(url, item)
  return response.data
}

async function deleteItem(urn, fieldId, itemId) {
  const url = `/v3/documents/${urn}/fields/${fieldId}/items/${itemId}`
  const response = await transport.delete(url)
  return response.data
}

async function updateItem(urn, fieldId, itemId, key, value) {
  const url = `/v3/documents/${urn}/fields/${fieldId}/items/${itemId}`
  const response = await transport.patch(url, { [key]: value })
  return response.data
}

async function reorderItem(urn, fieldId, itemIds) {
  const url = `/v3/documents/${urn}/fields/${fieldId}/items/position`
  const response = await transport.post(url, { itemIds })
  return response.data
}

async function updateAnswer(urn, completionId, fieldId, itemId, value) {
  const url = `/v3/documents/${urn}/completions/${completionId}/fields/${fieldId}/items/${itemId}/respond`
  const response = await transport.post(url, { response: value })
  return response.data
}

async function reorderFields(urn, fieldIds) {
  const url = `/v3/documents/${urn}/fields/position`
  const response = await transport.post(url, { fieldIds })
  return response.data
}

export default {
  createField,
  deleteField,
  updateField,
  createItem,
  deleteItem,
  updateItem,
  reorderItem,
  updateAnswer,
  reorderFields,
}
