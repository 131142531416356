import getProp from 'lodash/get'
import { normaliseUser, extractAvatar } from './helpers/users'
import { actionTypes as usersActionTypes } from './users'

const actionTypes = {
  SESSION_STARTED: 'SESSION_STARTED',
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SESSION_STARTED:
      return { ...state, ...normaliseUser(action.user) }

    case usersActionTypes.USER_DETAILS_UPDATED: {
      const { userId, userDetails } = action
      if (userId !== state._id) return state
      return {
        ...state,
        ...userDetails,
        avatar: extractAvatar(userDetails),
      }
    }
    default:
      return state
  }
}

const start = user => ({
  type: actionTypes.SESSION_STARTED,
  user,
})

function selectCurrentUser(state) {
  return getProp(state, 'session')
}

function selectCurrentUserId(state) {
  return getProp(state, 'session._id')
}

function selectCurrentGroupId(state) {
  return getProp(state, 'session.relations[0].group')
}

function selectRemoteLogoutUrl(state) {
  return state.session.remoteLogoutUrl
}

function selectHasFederatedLogout(state) {
  return state.session.hasFederatedLogout
}

export {
  reducer,
  start,
  selectCurrentUser,
  selectCurrentGroupId,
  selectCurrentUserId,
  selectRemoteLogoutUrl,
  selectHasFederatedLogout,
  actionTypes,
}
