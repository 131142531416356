import { call, put, takeEvery } from 'redux-saga/effects'
import { keyBy, map } from 'lodash'
import { isBefore, parseISO } from 'date-fns'
import checkinApi from './api/checkins'
import { selectResource } from './resource'
import { selectComplianceLatestSubmission } from './compliance'

const CHECKIN_REQUESTED = 'CHECKIN_REQUESTED'
const CHECKIN_REQUEST_FAILED = 'CHECKIN_REQUEST_FAILED'
const CHECKIN_LOADED = 'CHECKIN_LOADED'
const CHECKIN_QUERY_REQUESTED = 'CHECKIN_QUERY_REQUESTED'
const CHECKIN_QUERY_LOADED = 'CHECKIN_QUERY_LOADED'
const FETCH_RESOURCE_CHECKINS_REQUESTED = 'FETCH_RESOURCE_CHECKINS_REQUESTED'
const RESOURCE_CHECKINS_FETCHED = 'RESOURCE_CHECKINS_FETCHED'
const FETCH_RESOURCE_CHECKINS_FAILED = 'FETCH_RESOURCE_CHECKINS_FAILED'

const actionTypes = {
  CHECKIN_REQUESTED,
  CHECKIN_REQUEST_FAILED,
  CHECKIN_LOADED,
  CHECKIN_QUERY_REQUESTED,
  CHECKIN_QUERY_LOADED,
  FETCH_RESOURCE_CHECKINS_REQUESTED,
  RESOURCE_CHECKINS_FETCHED,
  FETCH_RESOURCE_CHECKINS_FAILED,
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKIN_QUERY_LOADED: {
      return {
        ...state,
        ...keyBy(action.topics, t => t.topic),
      }
    }

    case CHECKIN_REQUESTED: {
      const existingTopic = state[action.topic] || {}
      return {
        ...state,
        [action.topic]: {
          ...existingTopic,
          [action.facet]: new Date().toISOString(),
        },
      }
    }

    case RESOURCE_CHECKINS_FETCHED: {
      const { result, query } = action
      const resourceTopics = map(result, 'topic')
      const resourceIds = resourceTopics.map(res => res.split('.').pop())
      return {
        ...state,
        [query.resourceFilter]: resourceIds,
      }
    }

    default:
      return state
  }
}

const checkInForResource = (id, resource, facet) => ({
  type: CHECKIN_REQUESTED,
  topic: `${resource}.${id}`,
  facet,
})

const fetchCheckinsForResource = (resource, limit, sort) => ({
  type: FETCH_RESOURCE_CHECKINS_REQUESTED,
  resource,
  limit,
  sort,
})

const queryResource = (resourceIds, resource) => {
  const topics = resourceIds.map(resourceId => `${resource}.${resourceId}`)
  return {
    type: CHECKIN_QUERY_REQUESTED,
    topics,
  }
}

function* checkin({ topic, facet }) {
  try {
    const result = yield call(checkinApi.checkin, topic, facet)
    yield put({
      type: CHECKIN_LOADED,
      topic,
      facet,
      ...result,
    })
  } catch (error) {
    console.error(error)
    yield put({
      type: CHECKIN_REQUEST_FAILED,
      topic,
      facet,
      error,
    })
  }
}

function* fetchResourceCheckinsSaga({ resource, limit, sort }) {
  const query = { resourceFilter: resource, limit, sort }
  try {
    const result = yield call(checkinApi.fetchResourceCheckins, query)
    yield put({
      type: RESOURCE_CHECKINS_FETCHED,
      query,
      result,
    })
  } catch (e) {
    console.error(e)
    yield put({
      type: FETCH_RESOURCE_CHECKINS_FAILED,
      error: e.message,
    })
  }
}

function* checkinQuery({ topics }) {
  try {
    const result = yield call(checkinApi.queryCheckins, topics)
    yield put({
      type: CHECKIN_QUERY_LOADED,
      topics: result,
    })
  } catch (e) {
    console.error(e)
  }
}

function* saga() {
  yield takeEvery(CHECKIN_REQUESTED, checkin)
  yield takeEvery(CHECKIN_QUERY_REQUESTED, checkinQuery)
  yield takeEvery(FETCH_RESOURCE_CHECKINS_REQUESTED, fetchResourceCheckinsSaga)
}

const selectCheckin = (state, type, id) => {
  const checkins = state.checkins
  if (checkins) {
    return checkins[`${type}.${id}`] || {}
  }

  return {}
}

const selectResourceIds = (state, resource) => {
  const resourceIds = state.checkins && state.checkins[`${resource}`]
  return resourceIds ? resourceIds : []
}

function isResourceViewed(state, resourceType, resourceId, dateMarker) {
  const resource = selectResource(state, resourceType, resourceId)
  if (!resource) return false
  const { createdAt } = resource
  const { viewed } = selectCheckin(state, resourceType, resourceId) || {}
  return !!viewed || isBefore(parseISO(createdAt), parseISO(dateMarker))
}

function hasNewSubmission(state, resourceType, resourceId) {
  const { viewed } = selectCheckin(state, resourceType, resourceId) || {}
  const latestSubmission = selectComplianceLatestSubmission(
    state,
    resourceType,
    resourceId,
  )
  return isBefore(parseISO(viewed), parseISO(latestSubmission))
}

export {
  actionTypes,
  checkin,
  checkInForResource,
  hasNewSubmission,
  checkinQuery,
  queryResource,
  reducer,
  saga,
  selectCheckin,
  fetchCheckinsForResource,
  fetchResourceCheckinsSaga,
  selectResourceIds,
  isResourceViewed,
}
