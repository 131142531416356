import { call, put, takeEvery } from 'redux-saga/effects'
import { get as getProp } from 'lodash'
import { isBefore, parseISO } from 'date-fns'
import tasksApi from './api/tasks'
import { removePermission, PERMISSIONS } from './permissions'
import { selectCheckin } from './checkins'
import { resourceTypes } from './resource'

// actions -----
const actionTypes = {
  TASK_REMINDER_REQUESTED: 'TASK_REMINDER_REQUESTED',
  TASK_REMINDER_SENT: 'TASK_REMINDER_SENT',
  TASK_REMINDER_FAILED: 'TASK_REMINDER_FAILED',
  TASK_UPLOAD_STATUS_UPDATED: 'TASK_UPLOAD_STATUS_UPDATED',
}

const { TASKS } = resourceTypes

// reducer -----
function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.TASK_REMINDER_SENT: {
      if (!state[action.taskId]) return state

      const now = new Date().toISOString()
      let updatedChildren = {}
      if (action.sendToAll) {
        const children = state[`${action.taskId}/children`]

        updatedChildren = children.reduce((o, childId) => {
          const child = state[`${childId}`]
          o[`${childId}`] = {
            ...child,
            reminder: { by: null, date: now },
            ...removePermission(PERMISSIONS.AUDIENCE_REMIND, child),
          }
          return o
        }, {})
      }

      return {
        ...state,
        ...updatedChildren,
        [`${action.taskId}`]: {
          ...state[`${action.taskId}`],
          reminder: { by: null, date: now },
          totals: { ...state[`${action.taskId}`].totals, remindable: 0 },
          ...removePermission(
            PERMISSIONS.AUDIENCE_REMIND,
            state[action.taskId],
          ),
        },
      }
    }

    case actionTypes.TASK_UPLOAD_STATUS_UPDATED: {
      if (!state[action.taskId]) return state

      return {
        ...state,
        [`${action.taskId}`]: {
          ...state[`${action.taskId}`],
          'doerAttachments/status': {
            ...getProp(state, `${action.taskId}.doerAttachments/status`),
            [action.uploadId]: {
              rejectionReason: action.rejectionReason,
              status: action.status,
              reviewedBy: action.reviewedBy,
            },
          },
        },
      }
    }

    default:
      return state
  }
}

const sendReminder = (taskId, completionId, reminderReason) => ({
  type: actionTypes.TASK_REMINDER_REQUESTED,
  taskId,
  completionId,
  reminderReason,
})

// selectors -----
const selectAllTaskIds = state => state.tasks.all
const selectAllTasks = state => state.tasks

function isTaskViewed(state, resourceType, taskId, dateMarker) {
  const task = state.documents[resourceType][taskId]
  if (!task) return false
  const { createdAt } = task
  const checkin = selectCheckin(state, TASKS, taskId)
  const { viewed } = checkin

  return Boolean(viewed) || isBefore(parseISO(createdAt), parseISO(dateMarker))
}

// sagas -----
function* sendReminderForTask(action) {
  const { taskId, completionId, reminderReason } = action
  try {
    const { tasks } = yield call(
      tasksApi.sendReminderForTask,
      taskId,
      completionId,
      reminderReason,
    )
    yield put({
      type: actionTypes.TASK_REMINDER_SENT,
      taskId,
      completionId,
      reminderReason,
    })
  } catch (error) {
    console.error(error)
    yield put({
      type: actionTypes.TASK_REMINDER_FAILED,
      taskId,
      completionId,
      reminderReason,
      error,
    })
  }
}

function* saga() {
  yield takeEvery(actionTypes.TASK_REMINDER_REQUESTED, sendReminderForTask)
}

export {
  isTaskViewed,
  sendReminder,
  selectAllTaskIds,
  selectAllTasks,
  sendReminderForTask,
  actionTypes,
  reducer,
  saga,
}
