import transport from './transport'

async function fetchUsers(query = null) {
  let response
  if (query) {
    response = await transport.post('/v2/users', query)
  } else {
    response = await transport.get('/v2/users')
  }
  return response.data
}

async function getUsersById(userIds) {
  const response = await transport.post('/v2/users/query', { ids: userIds })
  return response.data
}

async function updateAvatar(userId, profileImg) {
  await transport.patch(`/users/${userId}`, { profileImg })
}

async function updateUserDetails(userId, userDetails) {
  await transport.patch(`/users/${userId}`, userDetails)
}

export default { fetchUsers, getUsersById, updateAvatar, updateUserDetails }
