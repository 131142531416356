import {
  actionChannel,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { get as getProp, mapKeys } from 'lodash'
import { generateUrn, parseUrn } from './helpers/urn'
import commentsApi from './api/comments'
import { fetchMissingUsers } from './users'
import { selectIsEnabled } from './features'

const COMMENT_ADDED = 'COMMENT_ADDED'
const COMMENT_ADD_FAILED = 'COMMENT_ADD_FAILED'
const COMMENTS_REQUESTED = 'COMMENTS_REQUESTED'
const COMMENTS_LOADED = 'COMMENTS_LOADED'
const COMMENTS_RETRIEVE_FAILED = 'COMMENTS_RETRIEVE_FAILED'
const COMMENTS_STATS_REQUESTED = 'COMMENTS_STATS_REQUESTED'
const COMMENTS_STATS_LOADED = 'COMMENTS_STATS_LOADED'
const COMMENTS_STATS_RETRIEVE_FAILED = 'COMMENTS_STATS_RETRIEVE_FAILED'
const NOTIFY_COMMENT_ADDED_FAILED = 'NOTIFY_COMMENT_ADDED_FAILED'
const COMMENT_EDIT = 'COMMENT_EDIT'
const COMMENT_EDIT_FAILED = 'COMMENT_EDIT_FAILED'
const COMMENT_DELETE_REQUESTED = 'COMMENT_DELETE_REQUESTED'
const COMMENT_DELETED = 'COMMENT_DELETED'
const COMMENT_DELETE_FAILED = 'COMMENT_DELETE_FAILED'
const REACTION_ADDED = 'REACTION_ADDED'
const REACTION_ADD_FAILED = 'REACTION_ADD_FAILED'
const REACTION_REMOVED = 'REACTION_REMOVED'
const REACTION_REMOVE_FAILED = 'REACTION_REMOVE_FAILED'
const COMMENT_REQUESTED = 'COMMENT_REQUESTED'
const COMMENT_REQUEST_FAILED = 'COMMENT_REQUEST_FAILED'

const actionTypes = {
  COMMENT_ADDED,
  COMMENT_EDIT,
  COMMENT_EDIT_FAILED,
  COMMENT_DELETE_REQUESTED,
  COMMENT_DELETED,
  COMMENT_DELETE_FAILED,
  COMMENT_ADD_FAILED,
  COMMENT_REQUESTED,
  COMMENT_REQUEST_FAILED,
  COMMENTS_REQUESTED,
  COMMENTS_LOADED,
  COMMENTS_RETRIEVE_FAILED,
  COMMENTS_STATS_REQUESTED,
  COMMENTS_STATS_LOADED,
  COMMENTS_STATS_RETRIEVE_FAILED,
  NOTIFY_COMMENT_ADDED_FAILED,
  REACTION_ADDED,
  REACTION_ADD_FAILED,
  REACTION_REMOVED,
  REACTION_REMOVE_FAILED,
}

const selectCommentIds = (state, subject) =>
  getProp(state, `comments.${subject}`)

const selectCommentsCount = (state, subject) =>
  getProp(state, `comments.${subject}/count`)

const selectStats = (state, subject) =>
  getProp(state, `comments.${subject}/stats`)

const selectComment = (state, commentId) =>
  getProp(state, `comments.${commentId}`)

const selectCommentByUrn = (state, subject) => {
  const parsed = parseUrn(subject)
  return parsed && parsed.id && state.comments[parsed.id]
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case COMMENT_ADDED: {
      const {
        comment: { id, subject, body, createdBy, entities, metadata },
      } = action
      const previousComments = state[subject] || []
      const previousNumReplies = getProp(
        state,
        `${subject}/stats.numReplies`,
        0,
      )
      const { userReplies = {} } = state[`${subject}/stats`] || {}
      const previousCommentId = previousComments.length
        ? previousComments[previousComments.length - 1]
        : undefined
      // Add the comment data to the state, and add this comment to the list of comments for that subject
      return {
        ...state,
        [id]: {
          subject,
          body,
          id,
          entities,
          metadata,
          urn: generateUrn('comments', id),
          createdBy,
          createdAt: new Date(),
          previousCommentId,
        },
        [subject]: [...new Set([...previousComments, id])],
        [`${subject}/stats`]: {
          ...state[`${subject}/stats`],
          numReplies: previousNumReplies + 1,
          userReplies: {
            ...userReplies,
            [createdBy]: (userReplies[createdBy] || 0) + 1,
          },
        },
      }
    }

    case COMMENT_DELETED: {
      const { createdBy, id, subject } = action.comment
      if (!state[id]) return state
      const previousNumReplies = getProp(
        state,
        `${subject}/stats.numReplies`,
        0,
      )
      const userReplies = state[`${subject}/stats`].userReplies || {}
      return {
        ...state,
        [id]: {
          ...state[id],
          body: '',
          deletedAt: new Date(),
        },
        [`${subject}/stats`]: {
          ...state[`${subject}/stats`],
          numReplies: previousNumReplies - 1,
          userReplies: {
            ...userReplies,
            [createdBy]: userReplies[createdBy] - 1 || 0,
          },
        },
      }
    }

    case COMMENTS_LOADED: {
      const { subject, comments, total } = action
      const commentsAndStats = comments.reduce((acc, comment, index) => {
        acc[comment.id] = {
          ...comment,
          previousCommentId: comments[index - 1]?.id,
        }
        acc[`${comment.urn}/stats`] = {
          numReplies: comment.numReplies,
          numReactions: comment.numReactions,
          userReactions: comment.userReactions,
          userReplies: comment.userReplies,
        }
        return acc
      }, {})
      const previousComments = state[subject] || []

      if (previousComments.length) {
        const firstPreviousComment = state[previousComments[0]]
        commentsAndStats[firstPreviousComment?.id] = {
          ...firstPreviousComment,
          previousCommentId: comments[comments.length - 1]?.id,
        }
      }

      // Add the comment data to the state, and add this comment to the list of comments for that subject
      return {
        ...state,
        ...commentsAndStats,
        [subject]: [
          ...new Set([...comments.map(c => c.id), ...previousComments]),
        ],
        [`${subject}/count`]: total,
      }
    }

    case COMMENTS_STATS_LOADED: {
      const { stats } = action
      return {
        ...state,
        ...mapKeys(stats, (value, subject) => `${subject}/stats`),
      }
    }

    case COMMENT_EDIT: {
      const { id, body, entities, metadata } = action.comment

      return {
        ...state,
        [id]: {
          ...state[id],
          body,
          entities,
          metadata,
        },
      }
    }

    case REACTION_ADDED: {
      const { subject, reactionType, userId } = action
      const numReactions = getProp(state, `${subject}/stats.numReactions`, {})
      const userReactions = getProp(state, `${subject}/stats.userReactions`, {})

      return {
        ...state,
        [`${subject}/stats`]: {
          ...state[`${subject}/stats`],
          numReactions: {
            ...numReactions,
            [reactionType]: (numReactions[reactionType] || 0) + 1,
          },
          userReactions: {
            ...userReactions,
            [reactionType]: {
              ...userReactions[reactionType],
              [userId]: (userReactions[reactionType]?.[userId] || 0) + 1,
            },
          },
        },
      }
    }

    case REACTION_REMOVED: {
      const { subject, reactionType, userId } = action
      const numReactions = getProp(state, `${subject}/stats.numReactions`, {})
      if (!numReactions[reactionType]) return state
      const userReactions = getProp(state, `${subject}/stats.userReactions`, {})

      return {
        ...state,
        [`${subject}/stats`]: {
          ...state[`${subject}/stats`],
          numReactions: {
            ...numReactions,
            [reactionType]: numReactions[reactionType] - 1,
          },
          userReactions: {
            ...userReactions,
            [reactionType]: {
              ...userReactions[reactionType],
              [userId]: userReactions[reactionType]?.[userId] - 1,
            },
          },
        },
      }
    }

    default:
      return state
  }
}

const addComment = (comment, optimistic) => ({
  type: COMMENT_ADDED,
  comment,
  optimistic,
})

const editComment = (comment, optimistic) => ({
  type: COMMENT_EDIT,
  comment,
  optimistic,
})

const deleteComment = comment => ({
  type: COMMENT_DELETE_REQUESTED,
  comment,
})

const addReaction = (subject, reactionType, userId, optimistic) => ({
  type: REACTION_ADDED,
  subject,
  reactionType,
  userId,
  optimistic,
})

const removeReaction = (subject, reactionType, userId, optimistic) => ({
  type: REACTION_REMOVED,
  subject,
  reactionType,
  userId,
  optimistic,
})

function* addCommentSaga({ comment, optimistic }) {
  try {
    if (!optimistic) {
      yield call(commentsApi.addComment, comment)
    }
  } catch (e) {
    yield put({
      type: COMMENT_ADD_FAILED,
      comment,
      error: e.message,
    })
  }
}

function* editCommentSaga({ comment, optimistic }) {
  try {
    if (!optimistic) {
      yield call(commentsApi.editComment, comment)
    }
  } catch (e) {
    yield put({
      type: COMMENT_EDIT_FAILED,
      error: e.message,
    })
  }
}

function* deleteCommentSaga({ comment }) {
  try {
    const c = yield select(selectComment, comment.id)
    if (c) {
      yield call(commentsApi.deleteComment, comment)
      yield put({
        type: COMMENT_DELETED,
        comment: c,
      })
    }
  } catch (e) {
    yield put({
      type: COMMENT_DELETE_FAILED,
      comment,
      error: e.message,
    })
  }
}

const requestComments = (subject, query = {}) => ({
  type: COMMENTS_REQUESTED,
  subject,
  query,
})

const queryResourceStats = (ids, type) => ({
  type: COMMENTS_STATS_REQUESTED,
  subjects: ids.map(id => generateUrn(type, id)),
})

function* fetchCommentsSaga({ subject, query }) {
  const chatEnabled = yield select(selectIsEnabled, 'chat')
  if (chatEnabled) {
    try {
      const { items: comments, total } = yield call(
        commentsApi.fetchComments,
        subject,
        query,
      )
      yield put({
        type: COMMENTS_LOADED,
        subject,
        comments,
        total,
      })
      yield put({
        type: COMMENTS_STATS_REQUESTED,
        subjects: [subject],
      })
      const createdByUserIds = [...new Set(comments.map(c => c.createdBy))]
      yield put(fetchMissingUsers(createdByUserIds))
    } catch (e) {
      yield put({
        type: COMMENTS_RETRIEVE_FAILED,
        subject,
        query,
        error: e.message,
      })
    }
  }
}

function* fetchCommentSaga({ id, successAction, optimistic }) {
  try {
    const comment = yield call(commentsApi.getComment, id)
    yield put({
      type: successAction,
      comment,
      optimistic,
    })
    yield put(fetchMissingUsers([comment.createdBy]))
  } catch (e) {
    yield put({
      type: COMMENT_REQUEST_FAILED,
      id,
      error: e.message,
    })
  }
}

function* fetchCommentsStatsSaga({ subjects }) {
  const chatEnabled = yield select(selectIsEnabled, 'chat')
  if (chatEnabled) {
    try {
      const stats = yield call(commentsApi.fetchStatsBulk, subjects)
      yield put({
        type: COMMENTS_STATS_LOADED,
        stats,
      })
    } catch (e) {
      yield put({
        type: COMMENTS_STATS_RETRIEVE_FAILED,
        error: e.message,
      })
    }
  }
}

function* addReactionSaga({ subject, reactionType, optimistic }) {
  try {
    if (!optimistic) {
      yield call(commentsApi.addReaction, subject, reactionType)
      yield call(commentsApi.notifyReaction, {
        subject,
        type: reactionType,
        quantity: 1,
      })
    }
  } catch (error) {
    yield put({
      type: REACTION_ADD_FAILED,
      subject,
      reactionType,
      error,
    })
  }
}

function* removeReactionSaga({ subject, reactionType, optimistic }) {
  try {
    if (!optimistic) {
      yield call(commentsApi.removeReaction, subject, reactionType)
      yield call(commentsApi.notifyReaction, {
        subject,
        type: reactionType,
        quantity: 0,
      })
    }
  } catch (error) {
    yield put({
      type: REACTION_REMOVE_FAILED,
      subject,
      reactionType,
      error,
    })
  }
}

function* watchCommentRequests() {
  const requestChan = yield actionChannel(actionTypes.COMMENT_REQUESTED)
  while (true) {
    const action = yield take(requestChan)
    // Note that we're using a blocking call so that new comments won't be fetched out of order
    yield call(fetchCommentSaga, action)
  }
}

function* saga() {
  yield takeEvery(COMMENT_ADDED, addCommentSaga)
  yield takeEvery(COMMENT_DELETE_REQUESTED, deleteCommentSaga)
  yield takeEvery(COMMENTS_REQUESTED, fetchCommentsSaga)
  yield takeEvery(COMMENTS_STATS_REQUESTED, fetchCommentsStatsSaga)
  yield takeEvery(COMMENT_EDIT, editCommentSaga)
  yield takeEvery(REACTION_ADDED, addReactionSaga)
  yield takeEvery(REACTION_REMOVED, removeReactionSaga)
  yield fork(watchCommentRequests)
}

const processCommentMessage = message => {
  switch (message?.type) {
    // TODO: import notification types from one-packages
    case 'TASK_COMMENT_NOTIFICATION':
    case 'TASK_MENTION_NOTIFICATION':
    case 'FORM_COMMENT_NOTIFICATION':
    case 'FORM_MENTION_NOTIFICATION':
    case 'STORY_COMMENT_NOTIFICATION':
    case 'STORY_MENTION_NOTIFICATION':
    case 'EVENT_COMMENT_NOTIFICATION':
    case 'EVENT_MENTION_NOTIFICATION': {
      // comment is to handle legacy messages
      const { commentId, comment } = message?.params
      const id = commentId || comment?.id
      return [
        {
          type: actionTypes.COMMENT_REQUESTED,
          id,
          successAction: actionTypes.COMMENT_ADDED,
          optimistic: true,
        },
      ]
    }

    case 'TASK_COMMENT_DELETE_NOTIFICATION':
    case 'FORM_COMMENT_DELETE_NOTIFICATION':
    case 'STORY_COMMENT_DELETE_NOTIFICATION':
    case 'EVENT_COMMENT_DELETE_NOTIFICATION': {
      // comment is to handle legacy messages
      const { commentId, comment } = message?.params
      const id = commentId || comment?.id
      return [
        {
          type: actionTypes.COMMENT_REQUESTED,
          id,
          successAction: actionTypes.COMMENT_DELETED,
        },
      ]
    }

    case 'TASK_COMMENT_EDIT_NOTIFICATION':
    case 'FORM_COMMENT_EDIT_NOTIFICATION':
    case 'STORY_COMMENT_EDIT_NOTIFICATION':
    case 'EVENT_COMMENT_EDIT_NOTIFICATION': {
      // comment is to handle legacy messages
      const { commentId, comment } = message?.params
      const id = commentId || comment?.id
      return [
        {
          type: actionTypes.COMMENT_REQUESTED,
          id,
          successAction: actionTypes.COMMENT_EDIT,
          optimistic: true,
        },
      ]
    }

    case 'STORY_REACTION_NOTIFICATION': {
      const {
        reaction: { type: reactionType, subject, quantity = 1 },
        originatedBy,
      } = message?.params
      return [
        {
          type:
            quantity > 0
              ? actionTypes.REACTION_ADDED
              : actionTypes.REACTION_REMOVED,
          reactionType,
          subject,
          userId: originatedBy._id,
          optimistic: true,
        },
      ]
    }
  }
}

export {
  actionTypes,
  addComment,
  addCommentSaga,
  fetchCommentsSaga,
  fetchCommentSaga,
  fetchCommentsStatsSaga,
  addReaction,
  addReactionSaga,
  removeReaction,
  removeReactionSaga,
  reducer,
  requestComments,
  saga,
  selectCommentIds,
  selectCommentsCount,
  selectComment,
  selectStats,
  queryResourceStats,
  selectCommentByUrn,
  editComment,
  editCommentSaga,
  deleteComment,
  deleteCommentSaga,
  watchCommentRequests,
  processCommentMessage,
}
