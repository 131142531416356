import { mapValues, pick } from 'lodash'
import { decodeUrn } from '@concrete/resource'
import transport from './transport'
import { parseUrn } from '../helpers/urn'

// engagement api is very strict about the payload 😒
const ENGAGEMENT_FIELD = ['subject', 'id', 'body', 'entities', 'metadata']

async function addComment(comment) {
  const { resourceType } = decodeUrn(comment.subject)
  const urn = `urn:concrete:comments:${comment.id}`
  const completionUrl = comment.completionId
    ? `/completions/${comment.completionId}`
    : ''
  await transport.post(
    `/v3/${resourceType}/${comment.resourceId}${completionUrl}/comments`,
    pick(comment, ENGAGEMENT_FIELD),
  )

  return {
    ...comment,
    urn,
  }
}

async function editComment(comment) {
  const { resourceType } = decodeUrn(comment.subject)
  const completionUrl = comment.completionId
    ? `/completions/${comment.completionId}`
    : ''
  await transport.patch(
    `/v3/${resourceType}/${comment.resourceId}${completionUrl}/comments/${comment.id}`,
    pick(comment, ENGAGEMENT_FIELD),
  )
}

async function deleteComment(comment) {
  const { resourceType } = decodeUrn(comment.subject)
  const completionUrl = comment.completionId
    ? `/completions/${comment.completionId}`
    : ''
  await transport.delete(
    `/v3/${resourceType}/${comment.resourceId}${completionUrl}/comments/${comment.id}`,
  )
}

async function fetchComments(subject, { page, pageSize }) {
  const url = `/engagement/${subject}/comments`
  const result = await transport.get(url, { params: { page, pageSize } })

  const { items, total } = result.data
  return {
    items: items.map(r => ({
      ...r,
      createdAt: new Date(r.createdAt),
    })),
    total,
  }
}

async function getComment(id) {
  const url = `/engagement/comments/${id}`
  const result = await transport.get(url)
  return result.data
}

async function fetchStatsBulk(subjects) {
  const url = '/engagement/stats/query'
  const result = await transport.post(url, {
    subjects,
  })

  const stats = result.data
  return {
    ...mapValues(stats, r => ({
      ...r,
      latestComment: r.latestComment ? new Date(r.latestComment) : undefined,
    })),
  }
}

async function notifyReaction({ subject, type, quantity }) {
  const urn = parseUrn(subject)
  await transport.post(`/tasks/${urn.id}/reactions/notify`, {
    subject,
    type,
    quantity,
  })
}

async function addReaction(subject, type) {
  await transport.post(`/engagement/${subject}/reactions`, {
    type,
  })

  return {
    subject,
    type,
  }
}

async function removeReaction(subject, type) {
  await transport.delete(`/engagement/${subject}/reactions`, {
    data: {
      type,
    },
  })
}

export default {
  addComment,
  fetchComments,
  getComment,
  fetchStatsBulk,
  notifyReaction,
  editComment,
  deleteComment,
  addReaction,
  removeReaction,
}
