import { call, put, takeEvery } from 'redux-saga/effects'
import pick from 'lodash/pick'
import { get as getProp, keyBy } from 'lodash'
import groupsApi from './api/groups'
import { actionTypes as connectActionTypes } from './connect'
import { getParentGroupId } from './helpers/groups'

const GROUPS_REQUESTED = 'GROUPS_REQUESTED'
const GROUPS_REQUEST_FAILED = 'GROUPS_REQUEST_FAILED'
const GROUPS_LOADED = 'GROUPS_LOADED'
const ROOT_GROUP_REGISTERED = 'ROOT_GROUP_REGISTERED'

const actionTypes = {
  GROUPS_REQUESTED,
  GROUPS_LOADED,
  ROOT_GROUP_REGISTERED,
  GROUPS_REQUEST_FAILED,
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case GROUPS_LOADED: {
      return {
        ...state,
        ...keyBy(action.groups, g => `${g._id}`),
      }
    }

    case ROOT_GROUP_REGISTERED: {
      return {
        ...state,
        rootGroup: action.group,
      }
    }

    case connectActionTypes.CONNECT_CODE_EXPIRED: {
      return {
        ...state,
        [action.groupId]: undefined,
      }
    }

    default:
      return state
  }
}

const requestGroups = () => ({
  type: GROUPS_REQUESTED,
})

const registerRootGroup = group => ({
  type: ROOT_GROUP_REGISTERED,
  group,
})

const selectAll = state => state.groups
const selectGroup = (state, groupId) => state.groups[groupId]
const selectGroupName = (state, groupId) => state.groups[groupId].name
const selectRootGroup = state => state.groups.rootGroup
const selectGroupsByParent = (state, parentGroupId) => {
  const parentGroup = state.groups[parentGroupId]
  if (parentGroup && parentGroup.children) {
    const childrenGroups = pick(state.groups, parentGroup.children)
    return Object.values(childrenGroups)
  }

  return []
}

function selectBreadcrumbs(state, groupId, breadcrumb = []) {
  const group = state.groups[`${groupId}`]
  if (group) {
    const parentGroupId = getParentGroupId(group)
    const breadcrumbWithThisGroup = breadcrumb.slice()
    breadcrumbWithThisGroup.unshift({
      groupId: group._id,
      groupName: group.name,
    })

    if (parentGroupId) {
      return selectBreadcrumbs(state, parentGroupId, breadcrumbWithThisGroup)
    }

    return breadcrumbWithThisGroup
  }

  return breadcrumb
}

function* fetchGroupsFromApi() {
  try {
    const groups = yield call(groupsApi.fetchGroups)
    yield put({
      type: GROUPS_LOADED,
      groups,
    })
  } catch (e) {
    yield put({
      type: GROUPS_REQUEST_FAILED,
      error: e.message,
    })
  }
}

function* saga() {
  yield takeEvery(GROUPS_REQUESTED, fetchGroupsFromApi)
}

export {
  actionTypes,
  fetchGroupsFromApi,
  reducer,
  requestGroups,
  registerRootGroup,
  saga,
  selectAll,
  selectGroup,
  selectGroupName,
  selectGroupsByParent,
  selectRootGroup,
  selectBreadcrumbs,
}
