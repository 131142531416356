import transport from './transport'
import { RESOURCE_TYPES } from '@concrete/resource'

const { TASKS, ANNOUNCEMENTS } = RESOURCE_TYPES

async function fetchDocuments(query) {
  const { facet, resourceType } = query
  const url = `/v3/${
    resourceType === ANNOUNCEMENTS ? TASKS : resourceType
  }/facets/${facet}`
  const conf = {
    params: query,
  }
  const response = await transport.get(url, conf)
  return response.data
}

async function fetchCalendarDocuments(query) {
  const { facet, resourceType } = query
  const url = `/v3/${
    resourceType === ANNOUNCEMENTS ? TASKS : resourceType
  }/facets/${facet}/calendar`
  const conf = {
    params: query,
  }
  const response = await transport.get(url, conf)
  return response.data
}

async function createDocument(resourceType, documentBody) {
  const url = `/v3/${resourceType}`
  const response = await transport.post(url, documentBody)
  return response.data
}

async function deleteDocument(resourceType, id) {
  const url = `/v3/${resourceType}/${id}`
  const response = await transport.delete(url)
  return response.data
}

async function submitSketchDocument(resourceType, id) {
  const url = `/v3/${resourceType}/${id}/submit`
  const response = await transport.post(url)
  return response.data
}

async function cancelDocument(resourceType, id, cancellationReason, notify) {
  const url = `/v3/${resourceType}/${id}/cancel`
  const response = await transport.post(url, { cancellationReason, notify })
  return response.data
}

async function fetchDocument(resourceType, id) {
  const url = `/v3/${resourceType}/${id}`
  const response = await transport.get(url)
  return response.data
}

async function fetchFacetsMetadata(resourceType, group) {
  const url = `/v3/${
    resourceType === ANNOUNCEMENTS ? TASKS : resourceType
  }/facets`
  const response = await transport.get(url, { params: { group } })
  return response.data
}

async function fetchDocumentFacetsCounts(resourceType) {
  const url = `/v3/${
    resourceType === ANNOUNCEMENTS ? TASKS : resourceType
  }/facets/count`
  const response = await transport.get(url)
  return response.data
}

async function updateDocumentFields(resourceType, id, fields) {
  const url = `/v3/${resourceType}/${id}`
  const response = await transport.patch(url, fields)
  return response.data
}

async function pinDocument(resourceType, id, pinnedOn, pinnedUntil) {
  const url = `/v3/${resourceType}/${id}/pin`
  const response = await transport.post(url, { pinnedOn, pinnedUntil })
  return response.data
}

async function unpinDocument(resourceType, id) {
  const url = `/v3/${resourceType}/${id}/pin`
  const response = await transport.delete(url)
  return response.data
}

async function copyDocument(resourceType, id, documentBody) {
  const url = `/v3/${resourceType}/${id}/copy`
  const response = await transport.post(url, documentBody)
  return response.data
}

async function forwardDocument(resourceType, id, audience) {
  const url = `/v3/${resourceType}/${id}/forward`
  const response = await transport.post(url, {
    audience,
  })
  return response.data
}

export default {
  fetchFacetsMetadata,
  fetchDocuments,
  fetchCalendarDocuments,
  fetchDocument,
  createDocument,
  deleteDocument,
  updateDocumentFields,
  pinDocument,
  unpinDocument,
  fetchDocumentFacetsCounts,
  copyDocument,
  cancelDocument,
  forwardDocument,
  submitSketchDocument,
}
