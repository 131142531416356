import {
  RESOURCE_TYPES as resourceTypes,
  FACET_NAMES,
  generateUrn,
} from '@concrete/resource'
import { call, put, takeEvery, select } from 'redux-saga/effects'
import { actionTypes as checkinsActionTypes } from './checkins'
import { actionTypes as commentActionTypes, selectComment } from './comments'
import {
  actionTypes as fileActionTypes,
  selectAssetsFacet,
  selectFile,
  selectAssetsFacets,
  selectAssetsByFacetQuery,
  selectAssetsCountByFacetQuery,
  selectAssetsAreLoading,
} from './files'
import { selectTemplateById } from './templates'
import {
  selectDocumentFacets,
  selectDocumentFacet,
  selectDocument,
  selectDocuments,
  selectDocumentsCount,
  selectDocumentsAreLoading,
  actionTypes as documentActionTypes,
} from './documents'
import { selectEventsCount } from './events'
import api from './api/resource'
import { createQueryPages, createQueryKey } from './utils'
import { get as getProp } from 'lodash'
import { actionTypes as distributionActionTypes } from './distribution'
import { actionTypes as complianceActionTypes } from './compliance'
import { mapItemsToProperties } from './helpers/documents'
import { mapItemsToProperties as mapAssetItemsToProperties } from './helpers/assets'
import { selectAll } from './groups'
import { actionTypes as userActionTypes } from './users'
import { actionTypes as tagActionTypes } from './tags'

const { ALL } = FACET_NAMES
const {
  ALL: ALL_RESOURCES,
  ASSETS,
  COMMENTS,
  FORMS,
  TEMPLATES,
  STORIES,
} = resourceTypes

const actionTypes = {
  NOTIFY_RESOURCE_REQUESTED: 'NOTIFY_RESOURCE_REQUESTED',
  RESOURCE_NOTIFIED: 'RESOURCE_NOTIFIED',
  NOTIFY_RESOURCE_FAILED: 'NOTIFY_RESOURCE_FAILED',
  RESOURCES_REQUESTED: 'RESOURCES_REQUESTED',
  RESOURCES_LOADED: 'RESOURCES_LOADED',
  REQUEST_RESOURCES_FAILED: 'REQUEST_RESOURCES_FAILED',
  FACETS_REQUESTED: 'FACETS_REQUESTED',
  FACETS_LOADED: 'FACETS_LOADED',
  FACETS_REQUESTED_FAILED: 'FACETS_REQUESTED_FAILED',
}

// action  creators
const notifyResource = (resourceType, id, message = '') => ({
  type: actionTypes.NOTIFY_RESOURCE_REQUESTED,
  resourceType,
  id,
  message,
})

const fetchResources = query => ({
  type: actionTypes.RESOURCES_REQUESTED,
  query,
})

const fetchFacets = (resourceType, group) => ({
  type: actionTypes.FACETS_REQUESTED,
  resourceType,
  group,
})

const getQueryKey = query => {
  const { facet } = query
  const queryKey = createQueryKey(query)
  return `query/${facet}/${queryKey}`
}

function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.RESOURCES_REQUESTED: {
      const { query } = action
      return {
        ...state,
        [`${getQueryKey(query)}/status`]: {
          loading: true,
        },
      }
    }

    case actionTypes.RESOURCES_LOADED: {
      const { query, items = [], total } = action
      const { facet = ALL } = query
      return {
        ...state,
        ...createQueryPages(
          state,
          facet,
          query,
          items.map(i => i._id),
          total,
        ),
        ...items.reduce((acc, item) => {
          acc[item._id] = item.type
          return acc
        }, {}),
        [`${getQueryKey(query)}/status`]: {
          loading: false,
        },
      }
    }

    case actionTypes.FACETS_LOADED: {
      const { resourceType, group, facets } = action
      const keyedFacets = facets.reduce((acc, facet) => {
        acc[facet.key] = facet
        return acc
      }, {})

      return {
        ...state,
        [`${resourceType}/facets/${group}`]: {
          ...(state[`${resourceType}/facets/${group}`] || []),
          ...keyedFacets,
        },
        [`${resourceType}/facets/${group}/all`]: [
          ...new Set([
            ...(state[`${resourceType}/facets/${group}/all`] || []),
            ...Object.keys(keyedFacets),
          ]),
        ],
      }
    }

    case fileActionTypes.FILES_DETAILS_LOADED: {
      const { files } = action
      const filesArray = files.map(({ item, acl, tags }) => ({
        ...item,
        acl,
        tags,
      }))
      return {
        ...state,
        ...Object.values(filesArray).reduce((acc, { _id }) => {
          if (_id) acc[_id] = 'assets'
          return acc
        }, {}),
      }
    }

    default: {
      return state
    }
  }
}

// selectors
const selectPinnedCount = (state, query) => {
  const queryKey = getQueryKey(query)
  return getProp(state, `resource.pinned.${queryKey}/count`)
}

const selectPinnedAreLoading = (state, query) => {
  const queryKey = getQueryKey(query)
  return getProp(state, `resource.pinned.${queryKey}/status.loading`)
}

const selectFacet = (state, resourceType, facet, groupId) => {
  switch (resourceType) {
    case ASSETS:
      return selectAssetsFacet(state, facet)
    case ALL:
      return getProp(
        state,
        `resource.${resourceType}/facets/${groupId}.${facet}`,
      )
    default:
      return selectDocumentFacet(state, resourceType, groupId, facet)
  }
}

const selectResourceTypeById = (state, id) => {
  const type = getProp(state, ['resource', id])
  switch (type) {
    case TEMPLATES:
      return FORMS
    default:
      return type
  }
}

const selectResource = (state, resourceType, id) => {
  switch (resourceType) {
    case ASSETS:
      return selectFile(state, id)
    case COMMENTS:
      return selectComment(state, id)
    case TEMPLATES:
      return selectTemplateById(state, id)
    default:
      return selectDocument(state, resourceType, id)
  }
}

const selectFacets = (state, resourceType, groupId) => {
  switch (resourceType) {
    case ASSETS:
      return selectAssetsFacets(state)
    default:
      // TODO: at the moment x-web expects an array of facets objects
      // but we should always return arrays of ids and have a selector for the individual
      // entity. Refactor x-web to use keys
      const facetKeys = selectDocumentFacets(state, resourceType, groupId)
      return facetKeys?.map(key =>
        selectDocumentFacet(state, resourceType, groupId, key),
      )
  }
}

const selectResourceCount = (state, query) => {
  const { resourceType, facet, layout, ...restOfQuery } = query
  if (facet === 'pinned') return selectPinnedCount(state, query)
  switch (resourceType) {
    case ASSETS:
      return selectAssetsCountByFacetQuery(state, restOfQuery, facet)
    case ALL_RESOURCES:
      const queryKey = getQueryKey(query)
      return getProp(state, `resource.${queryKey}/count`)
    default:
      return layout === 'calendar'
        ? (selectEventsCount(state, resourceType, query, facet) || 0) +
            (selectEventsCount(
              state,
              resourceType,
              { ...query, startEnd: query.dueDate, dueDate: null },
              facet,
            ) || 0) +
            (selectEventsCount(
              state,
              'events',
              { ...query, resourceType: 'events' },
              facet,
            ) || 0) +
            (selectEventsCount(
              state,
              'events',
              {
                ...query,
                startEnd: query.dueDate,
                dueDate: null,
                resourceType: 'events',
              },
              facet,
            ) || 0)
        : selectDocumentsCount(state, query)
  }
}

const selectResourcesLoading = (state, query) => {
  const { resourceType, facet, ...restOfQuery } = query
  if (facet === 'pinned') return selectPinnedAreLoading(state, query)
  switch (resourceType) {
    case ASSETS:
      return selectAssetsAreLoading(state, restOfQuery, facet)
    case ALL_RESOURCES:
      const queryKey = getQueryKey(query)
      return getProp(state, `resource.${queryKey}/status.loading`)
    default:
      return selectDocumentsAreLoading(state, query)
  }
}

const selectResources = (state, query) => {
  const { resourceType, facet, ...restOfQuery } = query
  const count = selectResourceCount(state, query)
  const loading = selectResourcesLoading(state, query)
  let items
  switch (resourceType) {
    case ASSETS:
      items = selectAssetsByFacetQuery(state, restOfQuery, facet)
      break
    case ALL_RESOURCES:
      const queryKey = getQueryKey(query)
      items = getProp(state, ['resource', queryKey])
      break
    default:
      items = selectDocuments(state, query)
  }
  return { count, items, loading }
}

// sagas
function* notifyResourceSaga({ resourceType, id, message }) {
  try {
    yield call(api.notify, resourceType, id, message)
    yield put({
      type: actionTypes.RESOURCE_NOTIFIED,
    })
  } catch (error) {
    yield put({
      type: actionTypes.NOTIFY_RESOURCE_FAILED,
      id,
      resourceType,
      message,
      error,
    })
  }
}

function* requestResourcesSaga({ query }) {
  try {
    const { items = [], total = 0 } = yield call(api.fetchResources, query)

    const documents = []
    const assets = []
    items.forEach(i => {
      if (i.type === ASSETS) {
        assets.push(i)
      } else {
        documents.push(i)
      }
    })
    const storyIds = documents.filter(d => d.type === STORIES).map(s => s._id)

    const {
      userIds,
      files,
      topics,
      tags,
      complianceItems,
      distributionItems,
      documentItems,
    } = mapItemsToProperties(documents)

    const {
      assetItems,
      topics: assetTopics,
      distributionItems: assetDistributionItems,
      coverImages,
      tags: assetTags,
    } = mapAssetItemsToProperties(assets)

    const groups = yield select(selectAll)

    if (storyIds.length) {
      yield put({
        type: commentActionTypes.COMMENTS_STATS_REQUESTED,
        subjects: storyIds.map(id => generateUrn(STORIES, id)),
      })
    }
    yield put({
      type: userActionTypes.FETCH_MISSING_USERS,
      userIds: [...new Set(userIds.concat(assetItems.map(l => l.createdBy)))],
    })
    yield put({
      type: fileActionTypes.FETCH_MISSING_FILES,
      ids: [...new Set(files.concat(coverImages))],
    })

    const mergedTopics = topics.concat(assetTopics)
    if (mergedTopics.length) {
      yield put({
        type: checkinsActionTypes.CHECKIN_QUERY_REQUESTED,
        topics: mergedTopics,
      })
    }
    yield put({
      type: documentActionTypes.DOCUMENTS_LOADED,
      query,
      total,
      items: documentItems,
    })
    yield put({
      type: fileActionTypes.USER_ASSETS_LOADED,
      facet: ALL,
      query,
      items: assetItems,
      total,
    })
    yield put({
      type: distributionActionTypes.FACETS_DISTRIBUTION_LOADED,
      items: distributionItems.concat(assetDistributionItems),
    })
    yield put({
      type: complianceActionTypes.FACETS_COMPLIANCE_LOADED,
      items: complianceItems,
      groups,
    })
    yield put({
      type: tagActionTypes.FACETS_RESOURCE_TAGS_LOADED,
      tags: { ...tags, ...assetTags },
    })
    yield put({
      type: actionTypes.RESOURCES_LOADED,
      items,
      total,
      query,
    })
  } catch (e) {
    yield put({
      type: actionTypes.REQUEST_RESOURCES_FAILED,
      error: e.message,
    })
  }
}

function* fetchFacetsSaga({ resourceType, group }) {
  try {
    const facets = yield call(api.fetchFacets, resourceType, group)
    yield put({
      type: actionTypes.FACETS_LOADED,
      resourceType,
      group,
      facets,
    })
  } catch (e) {
    yield put({
      type: actionTypes.FACETS_REQUESTED_FAILED,
      error: e,
    })
  }
}

function* saga() {
  yield takeEvery(actionTypes.NOTIFY_RESOURCE_REQUESTED, notifyResourceSaga)
  yield takeEvery(actionTypes.RESOURCES_REQUESTED, requestResourcesSaga)
  yield takeEvery(actionTypes.FACETS_REQUESTED, fetchFacetsSaga)
}

export {
  resourceTypes,
  actionTypes,
  reducer,
  generateUrn,
  selectFacet,
  selectResourceTypeById,
  selectResource,
  selectFacets,
  selectPinnedCount,
  selectPinnedAreLoading,
  selectResources,
  selectResourcesLoading,
  selectResourceCount,
  saga,
  notifyResource,
  notifyResourceSaga,
  fetchResources,
  requestResourcesSaga,
  fetchFacets,
  fetchFacetsSaga,
}
