import transport from './transport'

async function completeCompletion(resourceType, resourceId, completionId) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/completions/${completionId}/complete`,
  )
  return response.data
}

async function completeCompliance(resourceType, resourceId) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/complete`,
  )
  return response.data
}

async function approveCompletion(resourceType, resourceId, completionId) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/completions/${completionId}/approve`,
  )
  return response.data
}

async function rejectCompletion(
  resourceType,
  resourceId,
  completionId,
  reason,
) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/completions/${completionId}/reject`,
    {
      notify: true,
      reason,
    },
  )
  return response.data
}

async function submitCompletion(resourceType, resourceId, completionId) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/completions/${completionId}/submit`,
  )
  return response.data
}

async function cancelCompletion(
  resourceType,
  resourceId,
  completionId,
  reason,
  notify,
) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/completions/${completionId}/cancel`,
    {
      reason,
      notify,
    },
  )
  return response.data
}

async function cleanupArchived(resourceType, resourceId) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/cleanupArchived`,
  )
  return response.data
}

async function cancelCompliance(resourceType, resourceId, reason, notify) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/cancel`,
    {
      reason,
      notify,
    },
  )
  return response.data
}

async function unassign(resourceType, resourceId) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/unassign`,
  )
  return response.data
}

async function editApprovers(
  resourceType,
  resourceId,
  approvers,
  approvalType,
) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/approvers`,
    {
      approvers,
      approvalType,
    },
  )
  return response.data
}

async function editCompletionApprovers(
  resourceType,
  resourceId,
  completionId,
  approvers,
  approvalType,
) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/completions/${completionId}/approvers`,
    {
      approvers,
      approvalType,
    },
  )
  return response.data
}

async function addCompletionAttachments(
  resourceType,
  resourceId,
  completionId,
  attachments,
) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/completions/${completionId}/attachments`,
    { attachments },
  )
  return response.data
}

async function deleteCompletionAttachment(
  resourceType,
  resourceId,
  completionId,
  attachmentId,
) {
  const response = await transport.delete(
    `/v3/${resourceType}/${resourceId}/completions/${completionId}/attachments/${attachmentId}`,
  )
  return response.data
}

async function startCompliance(resourceType, resourceId) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/completions/start`,
  )
  return response.data
}

async function startCompletion(resourceType, resourceId, completionId) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/completions/${completionId}/start`,
  )
  return response.data
}

export default {
  completeCompletion,
  completeCompliance,
  unassign,
  cancelCompletion,
  cancelCompliance,
  startCompliance,
  startCompletion,
  editApprovers,
  editCompletionApprovers,
  addCompletionAttachments,
  deleteCompletionAttachment,
  submitCompletion,
  approveCompletion,
  rejectCompletion,
  cleanupArchived,
}
