import { takeEvery, call, put, select } from 'redux-saga/effects'
import { union, get as getProp, pickBy } from 'lodash'
import usersApi from './api/users'
import { actionTypes as taskNotesActionTypes } from './taskNotes'
import { selectCurrentUser, actionTypes as sessionActionTypes } from './session'
import { normaliseUser } from './helpers/users'
import { createQueryKey } from './utils'

const actionTypes = {
  USERS_REQUESTED: 'USERS_REQUESTED',
  USERS_REQUEST_FAILED: 'USERS_REQUEST_FAILED',
  USERS_LOADED: 'USERS_LOADED',
  FETCH_MISSING_USERS: 'FETCH_MISSING_USERS',
  USER_AVATAR_UPDATED: 'USER_AVATAR_UPDATED',
  USER_AVATAR_UPDATE_FAILED: 'USER_AVATAR_UPDATE_FAILED',
  USER_UPDATE_DETAILS_REQUESTED: 'USER_UPDATE_DETAILS_REQUESTED',
  USER_DETAILS_UPDATED: 'USER_DETAILS_UPDATED',
  USER_UPDATE_DETAILS_REQUEST_FAILED: 'USER_UPDATE_DETAILS_REQUEST_FAILED',
  USER_DELETED: 'USER_DELETED',
  RESET_USERS_QUERY: 'RESET_USERS_QUERY',
}

const requestUsers = (query = null) => ({
  type: actionTypes.USERS_REQUESTED,
  query,
})

const updateAvatar = (userId, avatar) => ({
  type: actionTypes.USER_AVATAR_UPDATED,
  userId,
  avatar,
})

const updateUserDetails = (userId, userDetails) => ({
  type: actionTypes.USER_UPDATE_DETAILS_REQUESTED,
  userId,
  userDetails,
})

const fetchMissingUsers = userIds => ({
  type: actionTypes.FETCH_MISSING_USERS,
  userIds,
})

const resetQuery = query => ({
  type: actionTypes.RESET_USERS_QUERY,
  query,
})

const preserveComputedFields = (state, users) =>
  users.reduce((processedUsers, user) => {
    if (state[user._id] && !processedUsers[user._id]) {
      processedUsers[user._id] = state[user._id]
    }
    const previousCopyOfUser = processedUsers[user._id] || {}
    processedUsers[user._id] = {
      ...previousCopyOfUser,
      ...user,
      groupId: user.groupId || previousCopyOfUser.groupId,
      roleId: user.roleId || previousCopyOfUser.roleId,
      avatar: getProp(user, 'profileImg')
        ? user.avatar
        : previousCopyOfUser.avatar,
    }

    return processedUsers
  }, {})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.USERS_LOADED: {
      const userIds = action.users.map(u => u._id)
      const normalisedUsers = action.users.map(u => normaliseUser(u))
      const newState = {
        ...state,
        ...preserveComputedFields(state, normalisedUsers),
        all: union(state.all, userIds),
      }
      if (action.query) {
        newState[`query/${createQueryKey(action.query)}`] = union(
          state[`query/${createQueryKey(action.query)}`],
          userIds,
        )
        newState[`query/${createQueryKey(action.query)}/count`] = action.total
      }
      return newState
    }
    case actionTypes.RESET_USERS_QUERY: {
      return {
        ...state,
        [`query/${createQueryKey(action.query)}`]: undefined,
        [`query/${createQueryKey(action.query)}/count`]: undefined,
      }
    }

    case actionTypes.USER_AVATAR_UPDATED: {
      return {
        ...state,
        [action.userId]: {
          ...state[action.userId],
          avatar: action.avatar,
        },
      }
    }

    case actionTypes.USER_DETAILS_UPDATED: {
      return {
        ...state,
        [action.userId]: {
          ...normaliseUser(action.userDetails, state[action.userId]),
        },
      }
    }

    case actionTypes.USER_DELETED: {
      return {
        ...state,
        [action.userId]: undefined,
      }
    }

    case sessionActionTypes.SESSION_STARTED: {
      const normalisedUser = normaliseUser(action.user)
      const newState = {
        ...state,
        all: [...(state.all || []), action.user._id],
        [action.user._id]: normalisedUser,
      }

      return newState
    }

    case taskNotesActionTypes.TASK_NOTES_LOADED: {
      const normalisedUsers = action.taskNotes.map(u =>
        normaliseUser(u.createdBy),
      )
      const userIds = normalisedUsers.map(u => u._id)
      const newState = {
        ...state,
        ...preserveComputedFields(state, normalisedUsers),
        all: union(state.all, userIds),
      }

      return newState
    }
    default:
      return state
  }
}

const selectAllUserIds = state => state.users.all
const selectAllUsers = state => state.users
const selectUsersByQuery = (state, query) =>
  state.users[`query/${createQueryKey(query)}`]
const selectUsersCountByQuery = (state, query) =>
  state.users[`query/${createQueryKey(query)}/count`]

const selectUser = (state, id) => getProp(state, `users.${id}`)
const selectUserName = (state, id) => {
  const user = selectUser(state, id)
  if (!user) return undefined
  const name = user.name
  const fullName = `${user.firstName} ${user.lastName}`
  return name || fullName
}

const selectMissingUsers = (state, userIds) => {
  const allUsers = selectAllUsers(state)
  return userIds.filter(id => !allUsers[id] || allUsers[id].incomplete)
}

const currentUserBelongsToGroup = (state, groupId) => {
  const currentUser = selectCurrentUser(state)
  if (currentUser && currentUser.relations) {
    return currentUser.relations.some(relation => relation.group === groupId)
  }
  return false
}

function* fetchUsersFromApi(action) {
  const { query } = action
  const normalisedQuery = pickBy(query, value => value)
  try {
    const { users, metadata } = query
      ? yield call(usersApi.fetchUsers, normalisedQuery)
      : yield call(usersApi.fetchUsers)
    yield put({
      type: actionTypes.USERS_LOADED,
      query,
      users,
      total: metadata.total,
    })
  } catch (e) {
    yield put({
      type: actionTypes.USERS_REQUEST_FAILED,
      query,
      error: e.message,
    })
  }
}

function* updateAvatarSaga({ userId, avatar }) {
  try {
    yield call(usersApi.updateAvatar, userId, avatar)
  } catch (e) {
    yield put({
      type: actionTypes.USER_AVATAR_UPDATE_FAILED,
      error: e.message,
    })
  }
}

function* updateUserDetailsSaga({ userId, userDetails }) {
  try {
    yield call(usersApi.updateUserDetails, userId, userDetails)
    yield put({
      type: actionTypes.USER_DETAILS_UPDATED,
      userId,
      userDetails,
    })
  } catch (e) {
    yield put({
      type: actionTypes.USER_UPDATE_DETAILS_REQUEST_FAILED,
      userId,
      userDetails,
      error: e.message,
    })
  }
}

// Convenience, to fill in any users that we don't already know of yet.
function* fetchMissingUsersSaga({ userIds }) {
  const missingUserIds = yield select(selectMissingUsers, userIds)
  if (missingUserIds.length) {
    try {
      const users = Object.values(
        yield call(usersApi.getUsersById, missingUserIds),
      )
      yield put({
        type: actionTypes.USERS_LOADED,
        users,
      })
    } catch (e) {
      yield put({
        type: actionTypes.USERS_REQUEST_FAILED,
        error: e.message,
      })
    }
  }
}

function* saga() {
  yield takeEvery(actionTypes.USERS_REQUESTED, fetchUsersFromApi)
  yield takeEvery(actionTypes.USER_AVATAR_UPDATED, updateAvatarSaga)
  yield takeEvery(actionTypes.FETCH_MISSING_USERS, fetchMissingUsersSaga)
  yield takeEvery(
    actionTypes.USER_UPDATE_DETAILS_REQUESTED,
    updateUserDetailsSaga,
  )
}

export {
  actionTypes,
  currentUserBelongsToGroup,
  fetchUsersFromApi,
  fetchMissingUsers,
  fetchMissingUsersSaga,
  reducer,
  requestUsers,
  updateUserDetails,
  resetQuery,
  saga,
  selectAllUserIds,
  selectAllUsers,
  selectMissingUsers,
  selectUser,
  selectUserName,
  selectUsersByQuery,
  selectUsersCountByQuery,
  updateAvatar,
  updateAvatarSaga,
  updateUserDetailsSaga,
}
