import { call, put, takeEvery } from 'redux-saga/effects'
import { get as getProp } from 'lodash'
import tagsApi from './api/tags'
import { keyTagsByType, getResourceTags } from './helpers/tags'
import { generateUrn, RESOURCE_TYPES } from '@concrete/resource'
import { createQueryPages, createQueryKey } from './utils'
import { FACET_NAMES } from '@concrete/resource'

const { ALL } = FACET_NAMES
const { ASSETS } = RESOURCE_TYPES

const RESOURCE_TAGS_LOADED = 'RESOURCE_TAGS_LOADED'
const TAGS_LOADED = 'TAGS_LOADED'
const TAGS_REQUESTED = 'TAGS_REQUESTED'
const TAGS_REQUEST_FAILED = 'TAGS_REQUEST_FAILED'
const ASSOCIATE_TAG_REQUESTED = 'ASSOCIATE_TAG_REQUESTED'
const ASSOCIATE_TAG_FAILED = 'ASSOCIATE_TAG_FAILED'
const UNASSOCIATE_TAG_REQUESTED = 'UNASSOCIATE_TAG_REQUESTED'
const UNASSOCIATE_TAG_FAILED = 'UNASSOCIATE_TAG_FAILED'
const FACETS_RESOURCE_TAGS_LOADED = 'FACETS_RESOURCE_TAGS_LOADED'
const CREATE_TAG_REQUESTED = 'CREATE_TAG_REQUESTED'
const CREATE_TAG_FAILED = 'CREATE_TAG_FAILED'
const TAG_CREATED = 'TAG_CREATED'
const TAG_VIEWED = 'TAG_VIEWED'

const actionTypes = {
  RESOURCE_TAGS_LOADED,
  TAGS_LOADED,
  TAGS_REQUESTED,
  TAGS_REQUEST_FAILED,
  ASSOCIATE_TAG_REQUESTED,
  ASSOCIATE_TAG_FAILED,
  UNASSOCIATE_TAG_REQUESTED,
  UNASSOCIATE_TAG_FAILED,
  FACETS_RESOURCE_TAGS_LOADED,
  CREATE_TAG_REQUESTED,
  CREATE_TAG_FAILED,
  TAG_CREATED,
  TAG_VIEWED,
}

const getQueryKey = query => {
  const { facet = ALL } = query || {}
  const queryKey = createQueryKey(query)
  return `query/${facet}/${queryKey}`
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case TAGS_REQUESTED: {
      const { query } = action
      return {
        ...state,
        [`${getQueryKey(query)}/status`]: {
          loading: true,
        },
      }
    }

    case TAGS_LOADED: {
      const { tags, tagType, query, total } = action
      const { facet = ALL } = query || {}
      const tagsById = tags.reduce((acc, tag) => {
        acc[tag._id] = tag
        return acc
      }, {})
      const currentState = state[tagType] || {}
      const all = currentState?.all || []
      const queryPages = query
        ? createQueryPages(
            state,
            facet,
            query,
            tags.map(i => i._id),
            total,
          )
        : {}
      return {
        ...state,
        ...queryPages,
        [tagType]: {
          ...state[tagType],
          ...tagsById,
          all: [...new Set([...all, ...Object.keys(tagsById)])],
        },
        [`${getQueryKey(query)}/status`]: {
          loading: false,
        },
      }
    }

    case TAG_CREATED: {
      const { tagType, _id, values } = action
      const currentState = state[tagType] || {}
      return {
        ...state,
        lastCreatedTagId: _id,
        [tagType]: {
          ...currentState,
          [_id]: {
            _id,
            tagType,
            ...values,
          },
        },
      }
    }

    case TAG_VIEWED: {
      return {
        ...state,
        lastCreatedTagId: undefined,
      }
    }

    case ASSOCIATE_TAG_REQUESTED: {
      const { tagId, tagType, resourceType, resourceId, currentTagId } = action
      // TODO: Remove logic when folders become tags
      const type = resourceType === 'folders' ? ASSETS : resourceType
      const urn = generateUrn(type, resourceId)
      const currentState = state[urn] || {}
      const currentTags = currentState[tagType] || []
      return {
        ...state,
        [urn]: {
          ...currentState,
          [tagType]: [...currentTags.filter(t => t !== currentTagId), tagId],
        },
      }
    }

    case UNASSOCIATE_TAG_REQUESTED: {
      const { tagId, tagType, resourceType, resourceId } = action
      if (!tagId) return state
      // TODO: Remove logic when folders become tags
      const type = resourceType === 'folders' ? ASSETS : resourceType
      const urn = generateUrn(type, resourceId)
      const currentState = state[urn] || {}
      const currentTags = currentState?.[tagType] || []
      return {
        ...state,
        [urn]: {
          ...currentState,
          [tagType]: currentTags.filter(c => c !== tagId),
        },
      }
    }

    case RESOURCE_TAGS_LOADED: {
      const { tags = [], resourceType, resourceId } = action
      if (!tags.length) return state
      const urn = generateUrn(resourceType, resourceId)
      const tagsByType = keyTagsByType(tags)

      const newState = Object.keys(tagsByType).reduce((acc, tagType) => {
        const currentState = state[tagType] || {}
        acc[tagType] = {
          ...currentState,
          ...tagsByType[tagType],
        }
        return acc
      }, {})

      return {
        ...state,
        ...newState,
        ...getResourceTags({ [urn]: tags }),
      }
    }

    case actionTypes.FACETS_RESOURCE_TAGS_LOADED: {
      const { tags } = action
      const tagsByType = keyTagsByType(Object.values(tags)?.flatMap(t => t))

      const newState = Object.keys(tagsByType).reduce((acc, tagType) => {
        const currentState = state[tagType] || {}
        acc[tagType] = {
          ...currentState,
          ...tagsByType[tagType],
        }
        return acc
      }, {})

      return {
        ...state,
        ...newState,
        ...getResourceTags(tags),
      }
    }

    default:
      return state
  }
}

const associateTag = (
  tagType,
  tagId,
  resourceType,
  resourceId,
  currentTagId,
) => ({
  type: ASSOCIATE_TAG_REQUESTED,
  tagType,
  tagId,
  resourceType,
  resourceId,
  currentTagId,
})

const unassociateTag = (tagType, tagId, resourceType, resourceId) => ({
  type: UNASSOCIATE_TAG_REQUESTED,
  tagType,
  tagId,
  resourceType,
  resourceId,
})

const requestTags = (tagType, query = null) => ({
  type: TAGS_REQUESTED,
  tagType,
  query,
})

const createTag = (tagType, values) => ({
  type: CREATE_TAG_REQUESTED,
  tagType,
  values,
})

const viewLastCreatedTag = () => ({
  type: TAG_VIEWED,
})

const selectTags = (state, query) => {
  const loading = selectTagsAreLoading(state, query)
  const items = selectTagsByQuery(state, query)
  const count = selectTagsCount(state, query)
  return { count, items, loading }
}

const selectTagsByQuery = (state, query) =>
  getProp(state, ['tags', getQueryKey(query)])

const selectTagsAreLoading = (state, query) =>
  getProp(state, `tags.${getQueryKey(query)}/status.loading`)

const selectTagsCount = (state, query) =>
  getProp(state, `tags.${getQueryKey(query)}/count`)

const selectLastCreatedTagId = state => state?.tags?.lastCreatedTagId

const selectTagIdsByType = (state, tagType) => {
  if (!state?.tags?.[tagType]) return []
  return state.tags[tagType]?.all || []
}

const selectTag = (state, tagType, tagId) => {
  return state.tags?.[tagType]?.[tagId]
}

const selectResourceTagIdsByType = (
  state,
  tagType,
  resourceType,
  resourceId,
) => {
  const urn = generateUrn(resourceType, resourceId)
  return state?.tags?.[urn]?.[tagType] || []
}

function* fetchTagsFromApi(action) {
  const { tagType, query } = action
  try {
    const { items, total } = yield call(tagsApi.fetchTags, tagType, query)
    yield put({
      type: TAGS_LOADED,
      tagType,
      tags: items,
      query,
      total,
    })
  } catch (e) {
    yield put({
      type: TAGS_REQUEST_FAILED,
      error: e.message,
    })
  }
}

function* associateTagSaga(action) {
  const { tagType, tagId, resourceType, resourceId, currentTagId } = action
  try {
    if (!!currentTagId) {
      yield call(
        tagsApi.unassociateTag,
        tagType,
        currentTagId,
        resourceType,
        resourceId,
      )
    }
    yield call(tagsApi.associateTag, tagType, tagId, resourceType, resourceId)
  } catch (e) {
    yield put({
      type: ASSOCIATE_TAG_FAILED,
      error: e.message,
    })
  }
}

function* unassociateTagSaga(action) {
  const { tagType, tagId, resourceType, resourceId } = action
  if (!!tagId) {
    try {
      yield call(
        tagsApi.unassociateTag,
        tagType,
        tagId,
        resourceType,
        resourceId,
      )
    } catch (e) {
      yield put({
        type: UNASSOCIATE_TAG_FAILED,
        error: e.message,
      })
    }
  }
}

function* createTagSaga(action) {
  const { tagType, values } = action
  try {
    const { _id } = yield call(tagsApi.createTag, tagType, values)
    yield put({
      type: TAG_CREATED,
      tagType,
      _id,
      values,
    })
  } catch (e) {
    yield put({
      type: CREATE_TAG_FAILED,
      error: e.message,
    })
  }
}

function* saga() {
  yield takeEvery(TAGS_REQUESTED, fetchTagsFromApi)
  yield takeEvery(ASSOCIATE_TAG_REQUESTED, associateTagSaga)
  yield takeEvery(UNASSOCIATE_TAG_REQUESTED, unassociateTagSaga)
  yield takeEvery(CREATE_TAG_REQUESTED, createTagSaga)
}

export {
  actionTypes,
  createTag,
  createTagSaga,
  fetchTagsFromApi,
  reducer,
  saga,
  selectTagIdsByType,
  selectTag,
  associateTag,
  selectResourceTagIdsByType,
  unassociateTag,
  unassociateTagSaga,
  associateTagSaga,
  requestTags,
  selectTags,
  selectTagsCount,
  selectTagsAreLoading,
  selectTagsByQuery,
  selectLastCreatedTagId,
  viewLastCreatedTag,
}
