import { takeEvery, call, all, put, select, retry } from 'redux-saga/effects'
import { keyBy, get as getProp, mapValues, omit } from 'lodash'
import { RESOURCE_TYPES, generateUrn } from '@concrete/resource'
import assetsApi from './api/assets'
import { getBaseUrl } from './api/transport'
import filesApi from './api/uploads'
import { isBootstrapping } from './bootstrap'
import { actionTypes as checkinsActionTypes } from './checkins'
import { actionTypes as resourceActionTypes } from './resource'
import { fetchMissingUsers } from './users'
import { createQueryKey, createQueryPages, sortFilesInFacet } from './utils'
import {
  removeAssetsFromQueryKey,
  mapItemsToProperties,
} from './helpers/assets'
import { actionTypes as complianceActionTypes } from './compliance'
import { actionTypes as distributionActionTypes } from './distribution'
import { actionTypes as tagActionTypes } from './tags'

const BATCH_DOWNLOAD_SIZE = 30
const RETRY_DOWNLOAD_DELAY = 2000
const DOWNLOAD_RETRIES = 3

const actionTypes = {
  ASSETS_IMPORT_REQUESTED: 'ASSETS_IMPORT_REQUESTED',
  ASSETS_IMPORTED: 'ASSETS_IMPORTED',
  ASSETS_IMPORT_FAILED: 'ASSETS_IMPORT_FAILED',
  FILE_DOWNLOAD_REQUESTED: 'FILE_DOWNLOAD_REQUESTED',
  FILE_DOWNLOAD_SUCCESS: 'FILE_DOWNLOAD_SUCCESS',
  FILE_DOWNLOAD_FAILED: 'FILE_DOWNLOAD_FAILED',
  FILES_DOWNLOAD_REQUESTED: 'FILES_DOWNLOAD_REQUESTED',
  FILES_DOWNLOAD_SUCCESS: 'FILES_DOWNLOAD_SUCCESS',
  FILES_DOWNLOAD_FAILED: 'FILES_DOWNLOAD_FAILED',
  USER_ASSETS_REQUESTED: 'USER_ASSETS_REQUESTED',
  USER_ASSETS_LOADED: 'USER_ASSETS_LOADED',
  USER_ASSETS_REQUEST_FAILED: 'USER_ASSETS_REQUEST_FAILED',
  ASSETS_FACETS_REQUESTED: 'ASSETS_FACETS_REQUESTED',
  ASSETS_FACETS_REQUEST_FAILED: 'ASSETS_FACETS_REQUEST_FAILED',
  ASSETS_FACETS_LOADED: 'ASSETS_FACETS_LOADED',
  FILES_DETAILS_REQUESTED: 'FILES_DETAILS_REQUESTED',
  FILES_DETAILS_LOADED: 'FILES_DETAILS_LOADED',
  FILES_DETAILS_REQUEST_FAILED: 'FILES_DETAILS_REQUEST_FAILED',
  FOLDER_CREATE_REQUESTED: 'FOLDER_CREATE_REQUESTED',
  FOLDER_CREATED: 'FOLDER_CREATED',
  FOLDER_CREATE_FAILED: 'FOLDER_CREATE_FAILED',
  ASSET_EDIT_REQUESTED: 'ASSET_EDIT_REQUESTED',
  ASSET_EDITED: 'ASSET_EDITED',
  ASSET_EDIT_FAILED: 'ASSET_EDIT_FAILED',
  FOLDER_VIEWED: 'FOLDER_VIEWED',
  RESET_QUERY_REQUESTED: 'RESET_QUERY_REQUESTED',
  ASSETS_MOVE_REQUESTED: 'ASSETS_MOVE_REQUESTED',
  ASSETS_MOVED: 'ASSETS_MOVED',
  ASSETS_MOVE_FAILED: 'ASSETS_MOVE_FAILED',
  FETCH_MISSING_FILES: 'FETCH_MISSING_FILES',
  FILES_STATE_CHANGE_REQUESTED: 'FILES_STATE_CHANGE_REQUESTED',
  FILES_STATE_CHANGED: 'FILES_STATE_CHANGED',
  ASSETS_DELETE_REQUESTED: 'ASSETS_DELETE_REQUESTED',
  ASSETS_DELETED: 'ASSETS_DELETED',
  ASSETS_DELETED_REQUEST_FAILED: 'ASSETS_DELETED_REQUEST_FAILED',
  REMOVE_ASSETS_FROM_FOLDER_REQUESTED: 'REMOVE_ASSETS_FROM_FOLDER_REQUESTED',
  ASSETS_REMOVED_FROM_FOLDER: 'ASSETS_REMOVED_FROM_FOLDER',
  REMOVE_ASSETS_FROM_FOLDER_REQUEST_FAILED:
    'REMOVE_ASSETS_FROM_FOLDER_REQUEST_FAILED',

  // these will replace FILES_DOWNLOAD_*
  QUEUE_FILES_DOWNLOAD: 'QUEUE_FILES_DOWNLOAD',
  FILES_DOWNLOAD_QUEUED: 'FILES_DOWNLOAD_QUEUED',
  QUEUE_FILES_DOWNLOAD_FAILED: 'QUEUE_FILES_DOWNLOAD_FAILED',
}

const { ASSETS } = RESOURCE_TYPES

function extractFilesFromTask({ attachments = [], doerAttachments = [] }) {
  const files = attachments.concat(doerAttachments).map(att => ({
    ...att,
    url: `${getBaseUrl()}files/${att.name}`,
  }))

  return keyBy(files, f => f._id)
}

const resetQuery = () => ({
  type: actionTypes.RESET_QUERY_REQUESTED,
})

const importAssets = (key, body, onComplete, onError) => ({
  type: actionTypes.ASSETS_IMPORT_REQUESTED,
  key,
  body,
  onComplete,
  onError,
})

const createFolder = body => ({
  type: actionTypes.FOLDER_CREATE_REQUESTED,
  body,
})

const editAsset = (id, field, value, isFolder) => ({
  type: actionTypes.ASSET_EDIT_REQUESTED,
  id,
  field,
  value,
  isFolder,
})

const viewLastCreatedFolder = () => ({
  type: actionTypes.FOLDER_VIEWED,
})

const removeFromFolder = (assets, folder) => ({
  type: actionTypes.REMOVE_ASSETS_FROM_FOLDER_REQUESTED,
  assets,
  folder,
})

const moveAssets = (assets, folder) => ({
  type: actionTypes.ASSETS_MOVE_REQUESTED,
  assets,
  folder,
})

const downloadFile = id => ({
  type: actionTypes.FILE_DOWNLOAD_REQUESTED,
  id,
})

const downloadFiles = (files, archiveName = 'files') => ({
  type: actionTypes.FILES_DOWNLOAD_REQUESTED,
  files,
  archiveName,
})

const requestFiles = fileIds => ({
  type: actionTypes.FILES_DETAILS_REQUESTED,
  fileIds,
})

const deleteAssets = (itemIds, callback) => ({
  type: actionTypes.ASSETS_DELETE_REQUESTED,
  itemIds,
  callback,
})

const requestUserAssets = (facet = 'all', query = null, errorCallback) => ({
  type: actionTypes.USER_ASSETS_REQUESTED,
  facet,
  query,
  errorCallback,
})

const requestAssetsFacets = () => ({
  type: actionTypes.ASSETS_FACETS_REQUESTED,
})

const fetchMissingFiles = ids => ({
  type: actionTypes.FETCH_MISSING_FILES,
  ids,
})

const queueFilesDownload = (ids, archiveName, folders) => ({
  type: actionTypes.QUEUE_FILES_DOWNLOAD,
  ids,
  archiveName,
  folders,
})

const getFacetQueryKey = (facet, query) => {
  const queryKey = createQueryKey(query)
  return `query/${facet}${queryKey && queryKey !== '' ? '/' : ''}${queryKey}`
}

const expandFiles = (state, files = []) => ({
  ...state,
  ...files.reduce((acc, file) => {
    acc[file._id] = {
      ...file,
      url: `${getBaseUrl()}files/${file.name}`,
    }
    return acc
  }, {}),
})

// reducer -----
const reducer = (state = {}, action) => {
  switch (action.type) {
    case complianceActionTypes.COMPLETIONS_LOADED: {
      return action.completions.reduce(
        (files, task) => ({
          ...files,
          ...extractFilesFromTask(task),
        }),
        { ...state },
      )
    }

    case actionTypes.ASSETS_IMPORTED: {
      const { files, uploadedTo } = action
      if (!files) return state

      const filesArray = Object.values(files).map(({ item, acl }) => ({
        ...item,
        acl,
      }))
      let newState = expandFiles(state, filesArray)
      if (newState.lastQuery && uploadedTo === 'files') {
        const fileIds = filesArray.map(({ _id }) => _id)
        const facetQueryKey = getFacetQueryKey(
          newState.facet,
          newState.lastQuery,
        )

        newState[facetQueryKey] = [...fileIds, ...newState[facetQueryKey]]
        newState[`${facetQueryKey}/pages`] = []
        newState[`${facetQueryKey}/files`] = [
          ...fileIds,
          ...(newState[`${facetQueryKey}/files`] || []),
        ]

        sortFilesInFacet(newState, facetQueryKey)

        newState = createQueryPages(
          newState,
          newState.facet,
          { ...newState.lastQuery, page: 1 },
          newState[facetQueryKey],
          newState[`${facetQueryKey}/count`] + fileIds.length,
        )
      }

      return newState
    }

    case actionTypes.FILES_DETAILS_LOADED: {
      const { files } = action
      if (!files) return state
      const filesArray = files.map(({ item, acl, tags }) => ({
        ...item,
        acl,
        tags,
      }))
      return expandFiles(state, filesArray)
    }

    case actionTypes.USER_ASSETS_REQUESTED: {
      const { facet, query } = action
      if (query) {
        const facetQueryKey = getFacetQueryKey(facet, query)
        return {
          ...state,
          [`${facetQueryKey}/status`]: { loading: true },
        }
      }
      return state
    }

    case actionTypes.USER_ASSETS_LOADED: {
      const { facet, query, items, total, folderData, acl } = action
      if (!items) return state

      const { assetIds, files, folders } = items.reduce(
        (acc, asset) => {
          acc.assetIds.push(asset._id)
          if (asset.isFolder) acc.folders.push(asset._id)
          else acc.files.push(asset._id)
          return acc
        },
        { assetIds: [], files: [], folders: [] },
      )
      let newState = expandFiles(state, items)

      if (folderData) {
        newState.folderData = folderData
        newState[folderData?._id] = {
          ...state[folderData?._id],
          ...folderData,
          url: `${getBaseUrl()}folders/${folderData?._id}`,
          acl,
        }
      } else {
        newState.folderData = undefined
      }

      if (query) {
        const facetQueryKey = getFacetQueryKey(facet, query)
        const prevFiles = state[`${facetQueryKey}/files`] || []
        newState = createQueryPages(newState, facet, query, assetIds, total)
        newState[`${facetQueryKey}/files`] = prevFiles.concat(files)
        newState[`${facetQueryKey}/folders`] = folders
        newState[`${facetQueryKey}/status`] = {
          loading: false,
        }
        newState.facet = facet
        newState.lastQuery = query
      }
      return newState
    }

    case actionTypes.USER_ASSETS_REQUEST_FAILED: {
      const { facet, query } = action
      if (query) {
        const facetQueryKey = getFacetQueryKey(facet, query)
        return {
          ...state,
          [`${facetQueryKey}/status`]: { loading: false },
        }
      }
      return state
    }

    case actionTypes.ASSETS_FACETS_LOADED: {
      return {
        ...state,
        facets: action.facets,
      }
    }

    case actionTypes.FOLDER_CREATED: {
      const { item, acl } = action
      const { _id } = item

      if (!_id) {
        return state
      }

      let newState = {
        ...state,
        lastCreatedFolderId: _id,
        [_id]: {
          ...item,
          acl,
          url: `${getBaseUrl()}folders/${_id}`,
        },
      }

      if (newState.lastQuery) {
        const facetQueryKey = getFacetQueryKey(
          newState.facet,
          newState.lastQuery,
        )

        newState[facetQueryKey] = [_id, ...newState[facetQueryKey]]
        newState[`${facetQueryKey}/pages`] = []
        newState[`${facetQueryKey}/folders`] = [
          _id,
          ...(newState[`${facetQueryKey}/folders`] || []),
        ]

        sortFilesInFacet(newState, facetQueryKey)

        newState = createQueryPages(
          newState,
          newState.facet,
          { ...newState.lastQuery, page: 1 },
          newState[facetQueryKey],
          newState[`${facetQueryKey}/count`] + 1,
        )
      }

      return newState
    }

    case actionTypes.ASSET_EDITED: {
      const { field, value, _id } = action
      const newState = { ...state }
      newState[_id] = {
        ...newState[_id],
        [field]: value,
      }

      return newState
    }

    case actionTypes.FOLDER_VIEWED: {
      return { ...state, lastCreatedFolderId: undefined }
    }

    case actionTypes.RESET_QUERY_REQUESTED: {
      const newState = { ...state }
      newState.lastQuery = {}

      return newState
    }

    case actionTypes.ASSETS_MOVED: {
      const { assets, folder } = action
      return {
        ...state,
        ...removeAssetsFromQueryKey(state, ['all', 'overview'], assets),
      }
    }

    case actionTypes.ASSETS_REMOVED_FROM_FOLDER: {
      const { assets, folder } = action

      return {
        ...state,
        ...removeAssetsFromQueryKey(state, ['all', 'overview'], assets, key =>
          key.includes(`parent:${folder}`),
        ),
      }
    }

    case actionTypes.ASSETS_DELETED: {
      const { itemIds } = action
      return {
        ...omit(state, itemIds),
        ...removeAssetsFromQueryKey(state, ['all'], itemIds),
      }
    }

    default:
      return state
  }
}

const isImage = item => item.mimeType && item.mimeType.startsWith('image/')

// selectors
const selectFile = (state, fileId) => getProp(state, `files.${fileId}`)

const selectAssetsById = (state, ids) => {
  return ids.map(id => getProp(state, `files.${id}`))
}

const selectAllFiles = state => getProp(state, 'files')

const selectAssetsByFacetQuery = (state, query, facet = 'all') =>
  getProp(state, ['files', getFacetQueryKey(facet, query)])

const selectFilesByFacetQuery = (state, query, facet = 'all') =>
  getProp(state, ['files', `${getFacetQueryKey(facet, query)}/files`])

const selectAssetsCountByFacetQuery = (state, query, facet = 'all') =>
  getProp(state, ['files', `${getFacetQueryKey(facet, query)}/count`])

const selectAssetsAreLoading = (state, query, facet = 'all') =>
  isBootstrapping(state) ||
  getProp(state, [
    'files',
    `${getFacetQueryKey(facet, query)}/status`,
    'loading',
  ])

const selectAssetsFacets = state => getProp(state, 'files.facets')
const selectAssetsFacet = (state, key) =>
  selectAssetsFacets(state)?.find(facet => facet.key === key)
const selectLastQuery = state => getProp(state, 'files.lastQuery')
const selectFolderData = state => getProp(state, 'files.folderData')

const selectLastCreatedFolderId = state =>
  getProp(state, `files.lastCreatedFolderId`)

// sagas
function* importAssetsSaga({ key, body, onComplete, onError }) {
  try {
    const files = yield call(assetsApi.importAssets, body)
    const distributionItems = Object.values(files).reduce(
      (acc, { distribution }) => {
        if (distribution) acc.push(distribution)
        return acc
      },
      [],
    )

    yield put({
      type: actionTypes.ASSETS_IMPORTED,
      key,
      files,
      uploadedTo: body?.uploadedTo,
    })
    yield put({
      type: distributionActionTypes.FACETS_DISTRIBUTION_LOADED,
      items: distributionItems,
    })
    if (onComplete)
      yield call(
        onComplete,
        Object.values(files).map(({ item }) => item),
      )
  } catch (error) {
    yield put({
      type: actionTypes.ASSETS_IMPORT_FAILED,
      key,
      error,
      body,
    })
    if (onError) yield call(onError, error)
  }
}

function* createFolderSaga({ body }) {
  try {
    const { item, acl } = yield call(assetsApi.createFolder, body)
    yield put({
      type: actionTypes.FOLDER_CREATED,
      item,
      acl,
    })
  } catch (error) {
    yield put({
      type: actionTypes.FOLDER_CREATE_FAILED,
      error,
    })
  }
}

function* editAssetSaga(action) {
  try {
    const { id, field, value, isFolder } = action
    const updateApi = isFolder ? assetsApi.updateFolder : assetsApi.updateAsset
    yield call(updateApi, id, { [field]: value })
    yield put({
      type: actionTypes.ASSET_EDITED,
      _id: id,
      field,
      value,
    })
  } catch (error) {
    yield put({
      type: actionTypes.ASSET_EDIT_FAILED,
      error,
    })
  }
}

function* moveAssetsSaga({ assets, folder }) {
  try {
    yield call(assetsApi.moveAssets, assets, folder)
    yield put({
      type: actionTypes.ASSETS_MOVED,
      assets,
      folder,
    })
  } catch (error) {
    yield put({
      type: actionTypes.ASSETS_MOVE_FAILED,
      assets,
      folder,
      error,
    })
  }
}

function* removeAssetsFromFolderSaga({ assets, folder }) {
  try {
    yield call(assetsApi.removeAssets, assets, folder)
    yield put({
      type: actionTypes.ASSETS_REMOVED_FROM_FOLDER,
      assets,
      folder,
    })
  } catch (error) {
    yield put({
      type: actionTypes.REMOVE_ASSETS_FROM_FOLDER_REQUEST_FAILED,
      assets,
      folder,
      error,
    })
  }
}

function* fetchFilesFromApi(action) {
  const { fileIds } = action
  try {
    const files = yield call(assetsApi.fetchAssets, fileIds)
    yield put({
      type: actionTypes.FILES_DETAILS_LOADED,
      files,
    })

    const { distributionItems, tagItems } = Object.values(files).reduce(
      (acc, { item, distribution, tags }) => {
        const { _id } = item
        if (distribution) acc.distributionItems.push(distribution)
        if (!!tags?.items?.length)
          acc.tagItems[generateUrn(ASSETS, _id)] = tags.items
        return acc
      },
      {
        distributionItems: [],
        tagItems: {},
      },
    )

    if (distributionItems.length) {
      yield put({
        type: distributionActionTypes.FACETS_DISTRIBUTION_LOADED,
        items: distributionItems,
      })
    }

    yield put({
      type: tagActionTypes.FACETS_RESOURCE_TAGS_LOADED,
      tags: tagItems,
    })
  } catch (error) {
    yield put({
      type: actionTypes.FILES_DETAILS_REQUEST_FAILED,
      ids: fileIds,
      error,
    })
  }
}

function* processFileDownload({ id }) {
  try {
    yield* fetchMissingFilesSaga(fetchMissingFiles([id]))
    const file = yield select(selectFile, id)
    const data = yield call(filesApi.downloadFile, file)
    yield put({
      type: actionTypes.FILE_DOWNLOAD_SUCCESS,
      id,
      file,
      data,
    })
  } catch (error) {
    yield put({
      type: actionTypes.FILE_DOWNLOAD_FAILED,
      id,
      error,
    })
  }
}

/**
 * This saga supports 2 different formats for the input files to download:
 * - A simple array with the files to be downloaded
 * - An object with keys that represents labels (e.g. folder names) and values arrays of file ids
 *
 * @param {*} action
 */
function* processFilesDownload({ files, archiveName }) {
  let filesToDownload
  try {
    const isArray = Array.isArray(files)
    // first we get a list of all the file ids
    // files can be either a flat array of files
    // or an object with this schema: { [label]: <Array of file ids> }
    filesToDownload = isArray
      ? files
      : Object.values(files).reduce((acc, ids) => acc.concat(ids), [])
    // then we load the missing files
    // (this can be removed if we add an api to the backend to download files by id)
    let start = 0
    let filesBatch = filesToDownload.slice(start, 100)
    while (filesBatch?.length) {
      yield* fetchMissingFilesSaga(fetchMissingFiles(filesBatch))
      start += 100
      filesBatch = filesToDownload.slice(start, start + 100)
    }

    const allFiles = yield all(
      filesToDownload.map(id => select(selectFile, id)),
    )
    // and then download them in batches
    start = 0
    filesBatch = allFiles.slice(start, BATCH_DOWNLOAD_SIZE)
    let data = []

    while (filesBatch.length > 0) {
      const dataBatch = yield retry(
        DOWNLOAD_RETRIES,
        RETRY_DOWNLOAD_DELAY,
        filesApi.downloadFiles,
        filesBatch,
      )
      data = [...data, ...dataBatch]
      start += BATCH_DOWNLOAD_SIZE
      filesBatch = allFiles.slice(start, start + BATCH_DOWNLOAD_SIZE)
    }

    let mappedFiles = {}
    let mappedData = {}
    if (isArray) {
      // if files is a flat array we simply return the array of hydrated files
      // and the corresponding binary data
      mappedFiles = allFiles
      mappedData = data
    } else {
      // if files is a dictionary we have to return an object with the same structure but hydrated files instead of ids
      // and the same goes for data
      const filesDict = keyBy(allFiles, f => f._id)
      const dataDict = allFiles.reduce((acc, file, idx) => {
        acc[file._id] = data[idx]
        return acc
      }, {})
      mappedFiles = mapValues(files, ids => ids.map(id => filesDict[id]))
      mappedData = mapValues(files, ids => ids.map(id => dataDict[id]))
    }
    yield put({
      type: actionTypes.FILES_DOWNLOAD_SUCCESS,
      fileIds: filesToDownload,
      files: mappedFiles,
      data: mappedData,
      archiveName,
    })
  } catch (error) {
    yield put({
      type: actionTypes.FILES_DOWNLOAD_FAILED,
      fileIds: filesToDownload,
      error,
    })
  }
}

function* fetchUserAssetsFromApi(action) {
  const { facet, query, errorCallback } = action
  try {
    let assets
    let folderData

    if (query.parent) {
      const filesAndFolder = yield all([
        call(assetsApi.fetchAssetsByFacet, facet, query),
        call(assetsApi.fetchFolderData, query.parent, query.group),
      ])

      assets = filesAndFolder[0]
      folderData = filesAndFolder[1]
    } else {
      assets = yield call(assetsApi.fetchAssetsByFacet, facet, query)
    }

    const { items, total } = assets
    const {
      topics,
      distributionItems,
      coverImages,
      assetItems,
      tags,
    } = mapItemsToProperties(items)
    if (items.length) {
      yield put({
        type: checkinsActionTypes.CHECKIN_QUERY_REQUESTED,
        topics,
      })
      yield put({
        type: distributionActionTypes.FACETS_DISTRIBUTION_LOADED,
        items: distributionItems,
      })
      if (coverImages.length) {
        yield put(fetchMissingFiles(coverImages))
      }
    }
    const userIds = items.map(l => l.createdBy)
    yield put({
      type: actionTypes.USER_ASSETS_LOADED,
      facet,
      query,
      folderData: folderData?.item || folderData,
      acl: folderData?.acl,
      items: assetItems,
      total,
    })
    yield put({
      type: resourceActionTypes.RESOURCES_LOADED,
      items: assetItems,
      total,
      query,
    })
    if (folderData?.distribution) {
      yield put({
        type: distributionActionTypes.DISTRIBUTION_LOADED,
        resourceId: folderData?.item?._id,
        resourceType: ASSETS,
        distribution: folderData?.distribution,
      })
    }
    yield put({
      type: tagActionTypes.FACETS_RESOURCE_TAGS_LOADED,
      tags,
    })
    yield put(fetchMissingUsers(userIds))
  } catch (error) {
    yield put({
      type: actionTypes.USER_ASSETS_REQUEST_FAILED,
      facet,
      query,
      error,
      errorCallback,
    })
  }
}

function* fetchAssetsFacets() {
  try {
    const facets = yield call(assetsApi.fetchAssetsFacets)
    yield put({
      type: actionTypes.ASSETS_FACETS_LOADED,
      facets,
    })
  } catch (e) {
    yield put({
      type: actionTypes.ASSETS_FACETS_REQUEST_FAILED,
      error: e,
    })
  }
}

function* fetchMissingFilesSaga({ ids }) {
  const allFiles = yield select(selectAllFiles)
  const missingFilesIds = ids.filter(fileId => !allFiles[fileId])
  if (missingFilesIds.length) {
    try {
      const files = Object.values(
        yield call(assetsApi.fetchAssets, missingFilesIds),
      )
      const { distributionItems, tagItems } = Object.values(files).reduce(
        (acc, { _id, distribution, tags }) => {
          if (distribution) acc.distributionItems.push(distribution)
          if (!!tags?.items?.length)
            acc.tagItems[generateUrn(ASSETS, _id)] = tags.items
          return acc
        },
        {
          distributionItems: [],
          tagItems: {},
        },
      )
      yield put({
        type: actionTypes.FILES_DETAILS_LOADED,
        files,
      })
      if (distributionItems.length) {
        yield put({
          type: distributionActionTypes.FACETS_DISTRIBUTION_LOADED,
          items: distributionItems,
        })
      }
      if (Object.keys(tagItems).length) {
        yield put({
          type: tagActionTypes.FACETS_RESOURCE_TAGS_LOADED,
          tags: tagItems,
        })
      }
    } catch (error) {
      yield put({
        type: actionTypes.FILES_DETAILS_REQUEST_FAILED,
        error,
        ids,
      })
    }
  }
}

function* deleteAssetsSaga({ itemIds, callback }) {
  try {
    const assets = yield select(selectAssetsById, itemIds)

    const pathMap = assets.reduce((acc, asset) => {
      const { _id, isFolder = false } = asset
      acc[_id] = isFolder ? 'folders' : ASSETS
      return acc
    }, {}) // TODO: remove when folders is migrated to tags

    yield all(itemIds.map(id => call(assetsApi.deleteAsset, pathMap[id], id)))
    yield put({
      type: actionTypes.ASSETS_DELETED,
      itemIds,
    })
    if (!!callback) yield call(callback)
  } catch (error) {
    yield put({
      type: actionTypes.ASSETS_DELETED_REQUEST_FAILED,
      error: error?.response?.data?.message,
      itemIds,
    })
  }
}

function* queueFilesDownloadSaga({ ids, archiveName, folders }) {
  try {
    yield call(assetsApi.download, ids, archiveName, folders)
    yield put({
      type: actionTypes.FILES_DOWNLOAD_QUEUED,
      ids,
      archiveName,
    })
  } catch (error) {
    yield put({
      type: actionTypes.QUEUE_FILES_DOWNLOAD_FAILED,
      error: error?.response?.data?.message,
      ids,
      folders,
      archiveName,
    })
  }
}

function* saga() {
  yield takeEvery(actionTypes.FILES_DETAILS_REQUESTED, fetchFilesFromApi)
  yield takeEvery(actionTypes.FILE_DOWNLOAD_REQUESTED, processFileDownload)
  yield takeEvery(actionTypes.FILES_DOWNLOAD_REQUESTED, processFilesDownload)
  yield takeEvery(actionTypes.ASSETS_DELETE_REQUESTED, deleteAssetsSaga)
  yield takeEvery(actionTypes.USER_ASSETS_REQUESTED, fetchUserAssetsFromApi)
  yield takeEvery(actionTypes.ASSETS_FACETS_REQUESTED, fetchAssetsFacets)
  yield takeEvery(actionTypes.FOLDER_CREATE_REQUESTED, createFolderSaga)
  yield takeEvery(actionTypes.ASSET_EDIT_REQUESTED, editAssetSaga)
  yield takeEvery(actionTypes.ASSETS_MOVE_REQUESTED, moveAssetsSaga)
  yield takeEvery(actionTypes.FETCH_MISSING_FILES, fetchMissingFilesSaga)
  yield takeEvery(actionTypes.ASSETS_IMPORT_REQUESTED, importAssetsSaga)
  yield takeEvery(
    actionTypes.REMOVE_ASSETS_FROM_FOLDER_REQUESTED,
    removeAssetsFromFolderSaga,
  )
  yield takeEvery(actionTypes.QUEUE_FILES_DOWNLOAD, queueFilesDownloadSaga)
}

export {
  actionTypes,
  importAssets,
  createFolder,
  deleteAssets,
  downloadFile,
  downloadFiles,
  editAsset,
  fetchMissingFiles,
  moveAssets,
  removeFromFolder,
  requestAssetsFacets,
  requestFiles,
  requestUserAssets,
  resetQuery,
  viewLastCreatedFolder,
  queueFilesDownload,
  reducer,
  selectFile,
  isImage,
  selectAllFiles,
  selectAssetsByFacetQuery,
  selectAssetsCountByFacetQuery,
  selectAssetsAreLoading,
  selectAssetsFacets,
  selectAssetsFacet,
  selectFilesByFacetQuery,
  selectFolderData,
  selectLastCreatedFolderId,
  selectLastQuery,
  selectAssetsById,
  saga,
  importAssetsSaga,
  createFolderSaga,
  deleteAssetsSaga,
  editAssetSaga,
  fetchAssetsFacets,
  fetchFilesFromApi,
  fetchMissingFilesSaga,
  fetchUserAssetsFromApi,
  moveAssetsSaga,
  removeAssetsFromFolderSaga,
  processFileDownload,
  processFilesDownload,
  queueFilesDownloadSaga,
  expandFiles,
  BATCH_DOWNLOAD_SIZE, //exporting for tests
  DOWNLOAD_RETRIES,
  RETRY_DOWNLOAD_DELAY,
}
