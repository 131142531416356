import transport from './transport'

async function addAttachments(resourceType, id, files) {
  const url = `/v3/${resourceType}/${id}/attachments`
  const response = await transport.post(url, {
    attachments: files,
  })
  return response.data
}

async function removeAttachment(resourceType, id, fileId) {
  const url = `/v3/${resourceType}/${id}/attachments/${fileId}`
  const response = await transport.delete(url)
  return response.data
}

export default {
  addAttachments,
  removeAttachment,
}
