import transport from './transport'

async function fetchAssetsByFacet(facet, query) {
  const url = `/v3/assets/facets/${facet}`
  const conf = {
    params: query,
  }
  const response = await transport.get(url, conf)
  return response.data
}

async function fetchFolderData(folderId, groupId) {
  const url = `/v3/folders/${folderId}${groupId ? `?group=${groupId}` : ''}`
  const response = await transport.get(url)
  return response.data
}

async function fetchAssetsFacets() {
  const response = await transport.get('/v3/assets/facets')
  return response.data
}

async function fetchAssets(itemIds) {
  const response = await transport.post(`/v3/assets`, { itemIds })
  return response.data
}

async function createFolder(body) {
  const response = await transport.post('/v3/folders', body)
  return response.data
}

async function importAssets(body) {
  const response = await transport.post('/v3/assets/import', body)
  return response.data
}

async function updateAsset(id, params) {
  const response = await transport.patch(`/v3/assets/${id}`, params)
  return response.data
}

async function updateFolder(id, params) {
  const response = await transport.patch(`/v3/folders/${id}`, params)
  return response.data
}

async function moveAssets(ids, folderId) {
  const response = await transport.post(`/v3/folders/${folderId}/move`, { ids })
  return response.data
}

async function removeAssets(ids, folderId) {
  const response = await transport.post(`/v3/folders/${folderId}/remove`, {
    ids,
  })
  return response.data
}

async function distribute(audience, strategy, workflow, urn) {
  const response = await transport.post('/distribute', {
    audience,
    strategy,
    workflow,
    urn,
  })
  return response.data
}

async function deleteAsset(path, id) {
  const response = await transport.delete(`/v3/${path}/${id}`)
  return response.data
}

async function download(ids, archiveName, folders) {
  const response = await transport.post('/assets/download', {
    ids,
    archiveName,
    folders,
  })
  return response.data
}

export default {
  fetchAssetsByFacet,
  fetchFolderData,
  fetchAssetsFacets,
  fetchAssets,
  importAssets,
  createFolder,
  updateAsset,
  updateFolder,
  moveAssets,
  removeAssets,
  distribute,
  deleteAsset,
  download,
}
