import api from './api/annotations'
import { call, put, takeEvery } from 'redux-saga/effects'
import { get as getProp } from 'lodash'
import { actionTypes as documentsActionTypes } from './documents'

const actionTypes = {
  ADD_ATTACHMENTS_REQUESTED: 'ADD_ATTACHMENTS_REQUESTED',
  ATTACHMENTS_ADDED: 'ATTACHMENTS_ADDED',
  ADD_ATTACHMENTS_REQUEST_FAILED: 'ADD_ATTACHMENTS_REQUEST_FAILED',
  REMOVE_ATTACHMENT_REQUESTED: 'REMOVE_ATTACHMENT_REQUESTED',
  ATTACHMENT_REMOVED: 'ATTACHMENT_REMOVED',
  REMOVE_ATTACHMENT_REQUEST_FAILED: 'REMOVE_ATTACHMENT_REQUEST_FAILED',
}

const addAttachments = (resourceType, id, files) => ({
  type: actionTypes.ADD_ATTACHMENTS_REQUESTED,
  resourceType,
  id,
  files,
})

const removeAttachment = (resourceType, id, fileId) => ({
  type: actionTypes.REMOVE_ATTACHMENT_REQUESTED,
  resourceType,
  id,
  fileId,
})

function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.ATTACHMENTS_ADDED: {
      const { resourceType, id, files } = action
      const resourceTypeState = state[resourceType] || {}
      const document = resourceTypeState[id] || {}
      const attachments = document?.attachments || []
      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [id]: {
            ...document,
            attachments: [...attachments, ...files],
          },
        },
      }
    }

    case actionTypes.ATTACHMENT_REMOVED: {
      const { resourceType, id, fileId } = action
      const resourceTypeState = state[resourceType]
      const document = resourceTypeState[id]
      const attachments = document?.attachments?.filter(i => i !== fileId)
      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [id]: {
            ...document,
            attachments,
          },
        },
      }
    }

    // TODO: attachments are still returned with the document
    // refactor when annotations will be extracted from documents
    case documentsActionTypes.DOCUMENT_LOADED: {
      const { resourceType, item } = action
      const resourceTypeState = state[resourceType] || {}
      const document = resourceTypeState[item._id] || {}
      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [item._id]: {
            ...document,
            attachments: item.attachments,
          },
        },
      }
    }

    default:
      return state
  }
}

const selectAttachments = (state, resourceType, id) =>
  getProp(state, `annotations.${resourceType}.${id}.attachments`)

function* addAttachmentsSaga({ resourceType, id, files }) {
  try {
    // TODO: create file through assets api

    // TODO: v3 endpoints return the whole document with update ACLs
    // reduce new acls
    yield call(api.addAttachments, resourceType, id, files)
    yield put({
      type: actionTypes.ATTACHMENTS_ADDED,
      resourceType,
      id,
      files,
    })
  } catch (error) {
    yield put({
      type: actionTypes.ADD_ATTACHMENTS_REQUEST_FAILED,
      resourceType,
      id,
      files,
      error,
    })
  }
}

function* removeAttachmentSaga({ resourceType, id, fileId }) {
  try {
    yield call(api.removeAttachment, resourceType, id, fileId)
    yield put({
      type: actionTypes.ATTACHMENT_REMOVED,
      resourceType,
      id,
      fileId,
    })
  } catch (error) {
    yield put({
      type: actionTypes.REMOVE_ATTACHMENT_REQUEST_FAILED,
      resourceType,
      id,
      fileId,
      error,
    })
  }
}

function* saga() {
  yield takeEvery(actionTypes.ADD_ATTACHMENTS_REQUESTED, addAttachmentsSaga)
  yield takeEvery(actionTypes.REMOVE_ATTACHMENT_REQUESTED, removeAttachmentSaga)
}

export {
  actionTypes,
  addAttachments,
  removeAttachment,
  reducer,
  saga,
  addAttachmentsSaga,
  removeAttachmentSaga,
  selectAttachments,
}
