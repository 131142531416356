import transport from './transport'

async function checkin(topic, facet) {
  const response = await transport.post(`/checkin/${topic}`, {
    [facet]: true,
  })
  return response.data
}

async function queryCheckins(topics) {
  const response = await transport.post('/checkin/query', {
    topics,
    facets: {
      details: true,
      attachments: true,
      timeline: true,
      approvals: true,
    },
  })
  return response.data
}

async function fetchResourceCheckins(query) {
  const url = `/checkin/search`
  const conf = {
    params: query,
  }
  const response = await transport.get(url, conf)
  return response.data
}

export default {
  checkin,
  queryCheckins,
  fetchResourceCheckins,
}
