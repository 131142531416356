export function keyTagsByType(tags = []) {
  return tags.reduce((acc, tag) => {
    const { _id, tagType } = tag
    acc[tagType] = {
      ...acc[tagType],
      [_id]: tag,
    }
    return acc
  }, {})
}

export function keyTagIdsByType(tags = []) {
  return tags.reduce((acc, tag) => {
    const { tagType, _id } = tag
    const current = acc[tagType] || []
    acc[tagType] = [...current, _id]
    return acc
  }, {})
}

export function getResourceTags(tagsKeyedByUrn = {}) {
  return Object.keys(tagsKeyedByUrn).reduce((acc, urn) => {
    acc[urn] = keyTagIdsByType(tagsKeyedByUrn[urn])
    return acc
  }, {})
}
