import { call, put, takeEvery } from 'redux-saga/effects'
import { get as getProp, keyBy } from 'lodash'
import tasksApi from './api/tasks'

// actions -----
const actionTypes = {
  TASK_NOTES_REQUESTED: 'TASK_NOTES_REQUESTED',
  TASK_NOTES_LOADED: 'TASK_NOTES_LOADED',
  NOTE_EDIT_REQUESTED: 'NOTE_EDIT_REQUESTED',
  NOTE_EDITED: 'NOTE_EDITED',
  NOTE_REMOVE_REQUESTED: 'NOTE_REMOVE_REQUESTED',
  NOTE_REMOVED: 'NOTE_REMOVED',
  NOTE_ADD_REQUESTED: 'NOTE_ADD_REQUESTED',
  NOTE_ADDED: 'NOTE_ADDED',
  ADD_NOTE_FAILED: 'ADD_NOTE_FAILED',
  EDIT_NOTE_FAILED: 'EDIT_NOTE_FAILED',
  REMOVE_NOTE_FAILED: 'REMOVE_NOTE_FAILED',
}

const normaliseNote = note => ({
  ...note,
  createdBy: note.createdBy._id,
})

// reducer -----
const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TASK_NOTES_LOADED: {
      const notes = action.taskNotes.map(note => normaliseNote(note))
      return {
        ...state,
        ...keyBy(notes, n => n._id),
        [`task-${action.id}`]: notes.map(n => n._id),
      }
    }

    default:
      return state
  }
}

// action creators -----
const requestTaskNotes = taskId => ({
  type: actionTypes.TASK_NOTES_REQUESTED,
  taskId,
})

const edit = (taskId, note) => ({
  type: actionTypes.NOTE_EDIT_REQUESTED,
  taskId,
  noteId: note._id,
  note: note.text,
})

const remove = (taskId, noteId) => ({
  type: actionTypes.NOTE_REMOVE_REQUESTED,
  taskId,
  noteId,
})

const add = (taskId, note) => ({
  type: actionTypes.NOTE_ADD_REQUESTED,
  taskId,
  note,
})

// selectors -----
const selectTaskNotes = (state, taskId) =>
  getProp(state, `taskNotes.task-${taskId}`, [])
const selectNote = (state, noteId) => getProp(state, `taskNotes.${noteId}`)

// sagas -----
function* fetchTaskNotesFromApi(action) {
  try {
    const { additionalNotes } = yield call(
      tasksApi.fetchTaskNotes,
      action.taskId,
    )
    yield put({
      type: actionTypes.TASK_NOTES_LOADED,
      id: action.taskId,
      taskNotes: additionalNotes,
    })
  } catch (e) {
    console.error(e)
  }
}

function* editNote(action) {
  const { taskId, note, noteId } = action
  try {
    yield call(tasksApi.editNote, taskId, noteId, note)
    yield put({
      type: actionTypes.NOTE_EDITED,
      taskId,
      noteId,
    })
  } catch (e) {
    yield put({
      type: actionTypes.EDIT_NOTE_FAILED,
      error: e.message,
      note,
      taskId,
      noteId,
    })
  }
}

function* removeNote(action) {
  const { taskId, noteId } = action
  try {
    yield call(tasksApi.removeNote, taskId, noteId)
    yield put({
      type: actionTypes.NOTE_REMOVED,
      taskId,
    })
  } catch (e) {
    yield put({
      type: actionTypes.REMOVE_NOTE_FAILED,
      error: e.message,
      noteId,
      taskId,
    })
  }
}

function* addNote(action) {
  const { taskId, note } = action
  try {
    yield call(tasksApi.addNote, taskId, note)
    yield put({
      type: actionTypes.NOTE_ADDED,
      taskId,
    })
  } catch (e) {
    yield put({
      type: actionTypes.ADD_NOTE_FAILED,
      error: e.message,
      note,
      taskId,
    })
  }
}

function* saga() {
  yield takeEvery(actionTypes.TASK_NOTES_REQUESTED, fetchTaskNotesFromApi)
  yield takeEvery(actionTypes.NOTE_EDITED, fetchTaskNotesFromApi)
  yield takeEvery(actionTypes.NOTE_REMOVED, fetchTaskNotesFromApi)
  yield takeEvery(actionTypes.NOTE_ADDED, fetchTaskNotesFromApi)
  yield takeEvery(actionTypes.NOTE_EDIT_REQUESTED, editNote)
  yield takeEvery(actionTypes.NOTE_REMOVE_REQUESTED, removeNote)
  yield takeEvery(actionTypes.NOTE_ADD_REQUESTED, addNote)
}

export {
  actionTypes,
  add,
  addNote,
  edit,
  editNote,
  fetchTaskNotesFromApi,
  reducer,
  remove,
  removeNote,
  requestTaskNotes,
  saga,
  selectNote,
  selectTaskNotes,
}
