import uuid from 'uuid/v4'

export const regenerateIds = fields => {
  return (fields || []).map(field => ({
    ...field,
    id: uuid(),
    items: (field.items || []).map(item => ({
      ...item,
      id: uuid(),
    })),
  }))
}

export const removeNullValues = obj => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null) acc[key] = value
    return acc
  }, {})
}
