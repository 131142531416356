// maybe rename file to resourceFields or documentFields as 'forms' is ambigious
import { call, put, select, takeEvery } from 'redux-saga/effects'
import getProp from 'lodash/get'
import formsApi from './api/forms'
import { maintainIndexes } from './helpers/forms'
import {
  FIELD_TYPES,
  REQUIRED_TYPES,
  ITEM_TYPES,
  generateUrn,
} from '@concrete/resource'
import { selectDocument } from './documents'

const FIELD_CREATED = 'FIELD_CREATED'
const CREATE_FIELD_REQUESTED = 'CREATE_FIELD_REQUESTED'
const CREATE_FIELD_FAILED = 'CREATE_FIELD_FAILED'
const DELETE_FIELD = 'DELETE_FIELD'
const DELETE_FIELD_FAILED = 'DELETE_FIELD_FAILED'
const UPDATE_FIELD = 'UPDATE_FIELD'
const UPDATE_FIELD_FAILED = 'UPDATE_FIELD_FAILED'
const CREATE_FIELD_ITEM_REQUESTED = 'CREATE_FIELD_ITEM_REQUESTED'
const FIELD_ITEM_CREATED = 'FIELD_ITEM_CREATED'
const CREATE_FIELD_ITEM_FAILED = 'CREATE_FIELD_ITEM_FAILED'
const DELETE_FIELD_ITEM = 'DELETE_FIELD_ITEM'
const DELETE_FIELD_ITEM_FAILED = 'DELETE_FIELD_ITEM_FAILED'
const UPDATE_FIELD_ITEM = 'UPDATE_FIELD_ITEM'
const UPDATE_FIELD_ITEM_FAILED = 'UPDATE_FIELD_ITEM_FAILED'
const REORDER_FIELD_ITEM = 'REORDER_FIELD_ITEM'
const REORDER_FIELD_ITEM_FAILED = 'REORDER_FIELD_ITEM_FAILED'
const REORDER_FIELDS = 'REORDER_FIELDS'
const REORDER_FIELDS_FAILED = 'REORDER_FIELDS_FAILED'
const UPDATE_ANSWER_REQUESTED = 'UPDATE_ANSWER_REQUESTED'
const ANSWER_UPDATED = 'ANSWER_UPDATED'
const UPDATE_ANSWER_FAILED = 'UPDATE_ANSWER_FAILED'

const { MULTIPLE_CHOICE_SINGLE, SHORT_ANSWER } = FIELD_TYPES
const { MULTIPLE_CHOICE_OTHER } = ITEM_TYPES

const { MATCH, MATCH_MANY, ONE, SOME, NONE } = REQUIRED_TYPES

const actionTypes = {
  CREATE_FIELD_REQUESTED,
  FIELD_CREATED,
  CREATE_FIELD_FAILED,
  DELETE_FIELD,
  DELETE_FIELD_FAILED,
  UPDATE_FIELD,
  UPDATE_FIELD_FAILED,
  CREATE_FIELD_ITEM_REQUESTED,
  FIELD_ITEM_CREATED,
  CREATE_FIELD_ITEM_FAILED,
  DELETE_FIELD_ITEM,
  DELETE_FIELD_ITEM_FAILED,
  UPDATE_FIELD_ITEM,
  UPDATE_FIELD_ITEM_FAILED,
  REORDER_FIELD_ITEM,
  REORDER_FIELD_ITEM_FAILED,
  REORDER_FIELDS,
  REORDER_FIELDS_FAILED,
  UPDATE_ANSWER_REQUESTED,
  ANSWER_UPDATED,
  UPDATE_ANSWER_FAILED,
}

const validationTypes = {
  CODE() {
    return MATCH_MANY
  },
  CONTENT() {
    return ONE
  },
  DATE() {
    return ONE
  },
  LONG_ANSWER() {
    return ONE
  },
  MULTIPLE_CHOICE_MANY(hasAnswer) {
    return !!hasAnswer ? MATCH : SOME
  },
  MULTIPLE_CHOICE_SINGLE(hasAnswer) {
    return !!hasAnswer ? MATCH : ONE
  },
  SHORT_ANSWER(hasAnswer) {
    return !!hasAnswer ? MATCH : ONE
  },
  UPLOAD() {
    return SOME
  },
}

const createField = (resourceType, formId, field) => ({
  type: CREATE_FIELD_REQUESTED,
  formId,
  field,
  resourceType,
})

const deleteField = (resourceType, formId, fieldId) => ({
  type: DELETE_FIELD,
  formId,
  fieldId,
  resourceType,
})

const updateField = (resourceType, formId, fieldId, key, value) => ({
  type: UPDATE_FIELD,
  formId,
  key,
  fieldId,
  value,
  resourceType,
})

const createFieldItem = (resourceType, formId, fieldId, item) => ({
  type: CREATE_FIELD_ITEM_REQUESTED,
  resourceType,
  formId,
  fieldId,
  item,
})

const updateFieldItem = (
  resourceType,
  formId,
  fieldId,
  itemId,
  key,
  value,
) => ({
  type: UPDATE_FIELD_ITEM,
  resourceType,
  formId,
  fieldId,
  itemId,
  key,
  value,
})

const deleteFieldItem = (resourceType, formId, fieldId, itemId) => ({
  type: DELETE_FIELD_ITEM,
  resourceType,
  formId,
  fieldId,
  itemId,
})

const reorderFieldItem = (resourceType, formId, fieldId, itemId, position) => ({
  type: REORDER_FIELD_ITEM,
  resourceType,
  formId,
  fieldId,
  itemId,
  position,
})

const reorderFields = (resourceType, formId, fieldId, position) => ({
  type: REORDER_FIELDS,
  resourceType,
  formId,
  fieldId,
  position,
})

const updateAnswer = (
  resourceType,
  formId,
  completionId,
  fieldId,
  itemId,
  value,
) => ({
  type: UPDATE_ANSWER_REQUESTED,
  resourceType,
  formId,
  completionId,
  fieldId,
  itemId,
  value,
})

function reducer(state = {}, action) {
  switch (action.type) {
    case FIELD_CREATED: {
      const { formId, field, resourceType } = action
      let form = state[resourceType][formId]
      if (!form) form = { id: formId }
      if (!form.fields) form.fields = []

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [formId]: {
            ...form,
            fields: [...form.fields, field],
          },
        },
      }
    }

    case DELETE_FIELD: {
      const { formId, fieldId, resourceType } = action
      const form = state[resourceType][formId]
      if (!form || !form.fields) return state
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [formId]: {
            ...form,
            fields: form.fields.filter(f => f.id !== fieldId),
          },
        },
      }
    }

    case UPDATE_FIELD: {
      const { formId, key, fieldId, value, resourceType } = action
      const form = state[resourceType][formId]
      if (!form || !form.fields) return state
      const field = form.fields.find(f => f.id === fieldId)
      const fields = maintainIndexes(form, { ...field, [key]: value })
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [formId]: {
            ...form,
            fields,
          },
        },
      }
    }

    case FIELD_ITEM_CREATED: {
      const { formId, fieldId, item, resourceType } = action
      const form = state[resourceType][formId]
      const field = form?.fields?.find(f => f.id === fieldId)
      if (!form || !form.fields || !field) return state
      if (!field.items) field.items = []
      const itemsLength = field.items.length
      const hasOther = field.items.filter(i => i.type === MULTIPLE_CHOICE_OTHER)
        .length
      hasOther
        ? field.items.splice(itemsLength - 1, 0, item)
        : field.items.push(item)
      const fields = maintainIndexes(form, field)

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [formId]: {
            ...form,
            fields,
          },
        },
      }
    }

    case DELETE_FIELD_ITEM: {
      const { formId, fieldId, itemId, resourceType } = action
      const form = state[resourceType][formId]
      const field = form?.fields?.find(f => f.id === fieldId)
      if (!form || !form.fields || !field) return state
      const items = field.items.filter(item => item.id !== itemId)
      const fields = maintainIndexes(form, { ...field, items })

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [formId]: {
            ...form,
            fields,
          },
        },
      }
    }

    case UPDATE_FIELD_ITEM: {
      const { formId, fieldId, itemId, key, value, resourceType } = action
      const form = state[resourceType][formId]
      const field = form?.fields?.find(f => f.id === fieldId)
      if (!form || !form.fields || !field) return state
      const item = field.items.find(item => item.id === itemId)
      const updatedItem = {
        ...item,
        [key]: value,
      }
      const fields = maintainIndexes(form, field, updatedItem)

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [formId]: {
            ...form,
            fields,
          },
        },
      }
    }

    case REORDER_FIELD_ITEM: {
      const { formId, fieldId, itemId, position, resourceType } = action
      const form = state[resourceType][formId]
      const field = form?.fields?.find(f => f.id === fieldId)
      if (!form || !form.fields || !field) return state
      const item = field.items.find(item => item.id === itemId)
      let items = field.items.filter(item => item.id !== itemId)
      const otherItem = field.items.find(i => i.type === MULTIPLE_CHOICE_OTHER)
      items.splice(position, 0, item)
      if (!!otherItem && items.indexOf(otherItem) !== items.length) {
        items = items.filter(item => item.id !== otherItem.id)
        items.splice(items.length, 0, otherItem)
      }
      const updatedField = {
        ...field,
        items,
      }
      const fields = maintainIndexes(form, updatedField)

      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [formId]: {
            ...form,
            fields,
          },
        },
      }
    }

    case REORDER_FIELDS: {
      const { formId, fieldId, position, resourceType } = action
      const form = state[resourceType][formId]
      if (!form || !form.fields) return state
      const field = form.fields.find(f => f.id === fieldId)
      const fields = form.fields.filter(f => f.id !== fieldId)
      fields.splice(position, 0, field)
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [formId]: {
            ...form,
            fields,
          },
        },
      }
    }

    default:
      return state
  }
}

const selectFields = (state, id, resourceType) => {
  const fields = getProp(state, `documents.${resourceType}.${id}.fields`)
  return (fields || []).map?.(f => f.id)
}

const selectField = (state, id, fieldId, resourceType, completionId) => {
  const fields = completionId
    ? getProp(state, `completions.${completionId}.fields`)
    : getProp(state, `documents.${resourceType}.${id}.fields`)
  const field = fields?.find(f => f.id === fieldId)
  return field
}

const selectFieldItems = (state, id, fieldId, resourceType) => {
  const field = getProp(state, `documents.${resourceType}.${id}.fields`).find(
    f => f.id === fieldId,
  )
  return (field.items || []).map(i => i.id)
}

const selectFieldItem = (
  state,
  id,
  fieldId,
  itemId,
  resourceType,
  completionId,
) => {
  const fields = completionId
    ? getProp(state, `completions.${completionId}.fields`)
    : getProp(state, `documents.${resourceType}.${id}.fields`)
  const field = fields?.find(f => f.id === fieldId)
  return field?.items.find(i => i.id === itemId)
}

const selectHasFieldItemOther = (state, id, fieldId, resourceType) => {
  const field = getProp(state, `documents.${resourceType}.${id}.fields`).find(
    f => f.id === fieldId,
  )
  const otherPresent = field.items.filter(
    item => item?.type === MULTIPLE_CHOICE_OTHER,
  ).length
  return !!otherPresent
}

function* createFieldSaga(action) {
  const { formId, field, resourceType } = action
  const urn = generateUrn(resourceType, formId)
  try {
    const { item } = yield call(formsApi.createField, urn, field)
    const { fields } = item
    const state = yield select()
    const fieldIds = yield selectFields(state, formId, resourceType)
    const newField = fields.find(f => !fieldIds.includes(f.id))
    yield put({
      type: FIELD_CREATED,
      resourceType,
      field: newField,
      formId,
    })
  } catch (e) {
    yield put({
      type: CREATE_FIELD_FAILED,
      formId,
      field,
      error: e.message,
    })
  }
}

function* deleteFieldSaga(action) {
  const { formId, fieldId, resourceType } = action
  const urn = generateUrn(resourceType, formId)
  try {
    yield call(formsApi.deleteField, urn, fieldId)
  } catch (e) {
    yield put({
      type: DELETE_FIELD_FAILED,
      formId,
      fieldId,
      error: e.message,
    })
  }
}

function* updateFieldSaga(action) {
  const { formId, key, fieldId, value, resourceType } = action
  const urn = generateUrn(resourceType, formId)
  try {
    yield call(formsApi.updateField, urn, fieldId, key, value)
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_FIELD_FAILED,
      formId,
      fieldId,
      key,
      value,
      error: e.message,
    })
  }
}

function* createFieldItemSaga(action) {
  const { formId, fieldId, item: fieldItem, resourceType } = action
  const urn = generateUrn(resourceType, formId)
  try {
    const { item } = yield call(formsApi.createItem, urn, fieldId, fieldItem)
    const state = yield select()
    const itemIds = yield selectFieldItems(state, formId, fieldId, resourceType)
    const field = item.fields.find(f => f.id === fieldId)
    const updatedItem = field.items.find(i => !itemIds.includes(i.id))
    yield put({
      type: FIELD_ITEM_CREATED,
      resourceType,
      formId,
      fieldId,
      item: updatedItem,
    })
  } catch (e) {
    yield put({
      type: CREATE_FIELD_ITEM_FAILED,
      formId,
      fieldId,
      item: fieldItem,
      error: e.message,
    })
  }
}

function* deleteFieldItemSaga(action) {
  const { formId, fieldId, itemId, resourceType } = action
  const urn = generateUrn(resourceType, formId)
  try {
    yield call(formsApi.deleteItem, urn, fieldId, itemId)
  } catch (e) {
    yield put({
      type: DELETE_FIELD_ITEM_FAILED,
      formId,
      fieldId,
      itemId,
      error: e.message,
    })
  }
}

function* updateFieldItemSaga(action) {
  const { formId, fieldId, itemId, key, value, resourceType } = action
  const { _id } = yield select(selectDocument, resourceType, formId)
  const urn = generateUrn(resourceType, _id)
  try {
    yield call(formsApi.updateItem, urn, fieldId, itemId, key, value)
    const { type, items } = yield select(
      selectField,
      formId,
      fieldId,
      resourceType,
    ) || {}
    const requiredItems = items?.filter(i => !!i.answer).length
    const setRequiredAsFalse = requiredItems === 0 && !value
    const setRequiredAsTrue = requiredItems === 1 && !!value
    // required needs to be set automatically for short answer
    if (
      key === 'answer' &&
      (setRequiredAsFalse || setRequiredAsTrue || type === SHORT_ANSWER)
    ) {
      yield put({
        type: actionTypes.UPDATE_FIELD,
        formId,
        key: 'required',
        fieldId,
        value: !value ? NONE : validationTypes[type](key),
        resourceType,
      })
    }
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_FIELD_ITEM_FAILED,
      taskId: _id,
      fieldId,
      itemId,
      key,
      value,
      error: e.message,
    })
  }
}

function* reorderFieldItemSaga(action) {
  const { formId, fieldId, resourceType } = action
  const state = yield select()
  const itemIds = yield selectFieldItems(state, formId, fieldId, resourceType)
  const urn = generateUrn(resourceType, formId)
  try {
    yield call(formsApi.reorderItem, urn, fieldId, itemIds)
  } catch (e) {
    yield put({
      type: actionTypes.REORDER_FIELD_ITEM_FAILED,
      formId,
      fieldId,
      itemIds,
    })
  }
}

function* reorderFieldsSaga(action) {
  const { formId, fieldId, resourceType } = action
  const urn = generateUrn(resourceType, formId)
  const state = yield select()
  const fieldIds = yield selectFields(state, formId, resourceType)
  try {
    yield call(formsApi.reorderFields, urn, fieldIds)
  } catch (e) {
    yield put({
      type: actionTypes.REORDER_FIELDS_FAILED,
      formId,
      fieldId,
      fieldIds,
    })
  }
}

function* updateAnswerSaga(action) {
  const { formId, completionId, fieldId, itemId, value, resourceType } = action
  try {
    const field = yield select(
      selectField,
      formId,
      fieldId,
      resourceType,
      completionId,
    )
    const urn = generateUrn(resourceType, formId)
    if (field.type === MULTIPLE_CHOICE_SINGLE && value === true) {
      const selectedItem = field.items.find(item => !!item.value)
      if (selectedItem && selectedItem.id !== itemId) {
        yield call(
          formsApi.updateAnswer,
          urn,
          completionId,
          fieldId,
          selectedItem.id,
          false,
        )
      }
    }
    yield call(formsApi.updateAnswer, urn, completionId, fieldId, itemId, value)
    yield put({
      type: ANSWER_UPDATED,
      completionId,
      fieldId,
      itemId,
      value,
    })
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_ANSWER_FAILED,
      formId,
      fieldId,
      itemId,
      error: e.message,
    })
  }
}

function* saga() {
  yield takeEvery(CREATE_FIELD_REQUESTED, createFieldSaga)
  yield takeEvery(DELETE_FIELD, deleteFieldSaga)
  yield takeEvery(UPDATE_FIELD, updateFieldSaga)
  yield takeEvery(CREATE_FIELD_ITEM_REQUESTED, createFieldItemSaga)
  yield takeEvery(DELETE_FIELD_ITEM, deleteFieldItemSaga)
  yield takeEvery(UPDATE_FIELD_ITEM, updateFieldItemSaga)
  yield takeEvery(REORDER_FIELD_ITEM, reorderFieldItemSaga)
  yield takeEvery(UPDATE_ANSWER_REQUESTED, updateAnswerSaga)
  yield takeEvery(REORDER_FIELDS, reorderFieldsSaga)
}

export {
  actionTypes,
  validationTypes,
  createField,
  deleteField,
  updateField,
  createFieldItem,
  updateFieldItem,
  deleteFieldItem,
  reorderFieldItem,
  reorderFields,
  updateAnswer,
  reducer,
  selectFields,
  selectField,
  selectFieldItems,
  selectFieldItem,
  selectHasFieldItemOther,
  createFieldSaga,
  deleteFieldSaga,
  updateFieldSaga,
  createFieldItemSaga,
  deleteFieldItemSaga,
  updateFieldItemSaga,
  reorderFieldItemSaga,
  updateAnswerSaga,
  reorderFieldsSaga,
  saga,
}
