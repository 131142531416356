import * as URN from 'urn-lib'
import uuid from 'uuid/v4'

const prefix = 'urn:concrete:'

const generateUrn = (type, id = uuid()) => `${prefix}${type}:${id}`

const urnParser = URN.create('urn', {
  components: ['nid', 'type', 'id'],
  separator: ':',
  allowEmpty: false,
})

const parseUrn = urnParser.parse

export { generateUrn, parseUrn }
