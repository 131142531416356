(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("@concrete/resource"), require("@concrete/authorization"), require("redux"), require("@concrete/compliance"), require("redux-saga"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "@concrete/resource", "@concrete/authorization", "redux", "@concrete/compliance", "redux-saga", "axios"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("lodash"), require("@concrete/resource"), require("@concrete/authorization"), require("redux"), require("@concrete/compliance"), require("redux-saga"), require("axios")) : factory(root["lodash"], root["@concrete/resource"], root["@concrete/authorization"], root["redux"], root["@concrete/compliance"], root["redux-saga"], root["axios"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__163__, __WEBPACK_EXTERNAL_MODULE__166__, __WEBPACK_EXTERNAL_MODULE__167__) {
return 