import { isAfter, parseISO } from 'date-fns'
import { get as getProp } from 'lodash'
import { normaliseId } from '../utils'
import { normaliseForm } from './forms'
import { RESOURCE_STATUSES } from '@concrete/resource'

const { SKETCH, DRAFT, IN_PROGRESS, TODO, REVIEW, REJECTED } = RESOURCE_STATUSES

export const hasAudience = task =>
  getProp(task, 'audience.groups.length', 0) > 0 ||
  getProp(task, 'audience.users.length', 0) > 0

export const isDraft = task => [SKETCH, DRAFT].includes(task.status)

export function isActive(task) {
  return [TODO, IN_PROGRESS, REJECTED, REVIEW].includes(task.status)
}

export function canBeReminded(task) {
  if (isActive(task) && task.status !== REVIEW) {
    if (!task.reminder || !task.reminder.date) {
      return true
    }

    const limit = 1000 * 60 * 60 * 24 // 24 hours
    return new Date() - new Date(task.reminder.date) >= limit
  }
  return false
}

export function canBeCancelled(task) {
  return isActive(task) && !isDeFactoCancelled(task)
}

export function isDeFactoCancelled(task) {
  if (
    isActive(task) &&
    task.distributed?.total > 0 &&
    task.distributed?.total === task.distributed?.cancelled
  ) {
    return true
  }
  return false
}

export function hasTimelineUpdates(task, lastCheckin) {
  if (!task.latestActivity) return false
  return (
    !lastCheckin ||
    isAfter(parseISO(task.latestActivity), parseISO(lastCheckin))
  )
}

export function hasNewAttachments(task, lastCheckin) {
  if (!task.latestUpload) return false
  return (
    !lastCheckin || isAfter(parseISO(task.latestUpload), parseISO(lastCheckin))
  )
}

export function hasNewApprovals(task, lastCheckin, currentUserId) {
  return (
    task.status === 'review' &&
    task.currentApprover === currentUserId &&
    (!lastCheckin ||
      isAfter(parseISO(task.transitionedAt), parseISO(lastCheckin)))
  )
}

export function hasNewComments(stats, lastCheckin) {
  if (!stats || !stats.latestComment) return false
  return (
    !lastCheckin ||
    isAfter(parseISO(stats.latestComment), parseISO(lastCheckin))
  )
}

const sortByNewest = (a, b) => new Date(b.createdAt) - new Date(a.createdAt)

export function processTaskAttachments(originalTask) {
  const task = {
    ...originalTask,
  }

  if (task.attachments) {
    task.attachments = task.attachments.sort(sortByNewest)
    task['attachments/images'] = task.attachments
      .filter(
        attachment =>
          attachment.mimeType && attachment.mimeType.startsWith('image/'),
      )
      .map(attachment => attachment._id)

    task['attachments/documents'] = task.attachments
      .filter(
        attachment =>
          attachment.mimeType && !attachment.mimeType.startsWith('image/'),
      )
      .map(attachment => attachment._id)

    task.attachments = task.attachments.map(normaliseId)
  }

  if (task.doerAttachments) {
    task.doerAttachments = task.doerAttachments.sort(sortByNewest)
    task['doerAttachments/images'] = task.doerAttachments
      .filter(
        attachment =>
          attachment.mimeType && attachment.mimeType.startsWith('image/'),
      )
      .map(attachment => attachment._id)

    task['doerAttachments/documents'] = task.doerAttachments
      .filter(
        attachment =>
          attachment.mimeType && !attachment.mimeType.startsWith('image/'),
      )
      .map(attachment => attachment._id)

    task['doerAttachments/status'] = task.doerAttachments.reduce((dict, a) => {
      if (a.status) {
        dict[a._id] = {
          forReview: !!a.forReview,
          rejectionReason: a.rejectionReason,
          status: a.status,
          reviewedBy: a.reviewedBy,
          transitionedAt: a.transitionedAt,
        }
      }
      return dict
    }, {})

    task.doerAttachments = task.doerAttachments.map(normaliseId)
  }

  return task
}

export function processTaskApprovers(task, currentUserId) {
  const { approvers = [] } = task
  const approversIds = []
  const blacklistedApprovers = []
  approvers.forEach(approver => {
    if (approver.user) approversIds.push(normaliseId(approver.user))
    if (approver.createdBy && approver.createdBy !== currentUserId) {
      blacklistedApprovers.push(normaliseId(approver.user))
    }
    if (!approver.user && !approver.createdBy) approversIds.push(approver)
  })
  return {
    ...task,
    approvers: approversIds,
    blacklistedApprovers,
  }
}

export function processTaskAssignees(task) {
  const audience = {
    ...task.audience,
  }
  audience.groups = (audience.groups || []).map(g => normaliseId(g))
  audience.users = (audience.users || []).map(u => normaliseId(u))

  return {
    ...task,
    audience,
  }
}

export function normaliseTask(task, currentUserId) {
  const taskWithAttachments = processTaskAttachments(task)
  const taskWithApprovers = processTaskApprovers(
    taskWithAttachments,
    currentUserId,
  )
  const taskWithAssignees = processTaskAssignees(taskWithApprovers)
  if (taskWithAssignees.assignedTo && taskWithAssignees.assignedTo._id) {
    taskWithAssignees.assignedTo = taskWithAssignees.assignedTo._id
  }

  if (task.createdBy) {
    taskWithAssignees.createdBy = normaliseId(task.createdBy)
  }

  if (task.rejectedBy) {
    taskWithAssignees.rejectedBy = normaliseId(task.rejectedBy)
  }

  if (task.scheduledBy) {
    taskWithAssignees.scheduledBy = normaliseId(task.scheduledBy)
  }

  if (task.cancelledBy) {
    taskWithAssignees.cancelledBy = normaliseId(task.cancelledBy)
  }

  if (task.currentApprover) {
    taskWithAssignees.currentApprover = normaliseId(task.currentApprover)
  }

  taskWithAssignees.distributed = task.distributed

  return normaliseForm(taskWithAssignees)
}

export const getNumberOfAssignees = (task, audienceKey = 'audience') =>
  getProp(task, `${audienceKey}.groups.length`, 0) ||
  getProp(task, `${audienceKey}.users.length`, 0)

export const removeQueries = state =>
  Object.keys(state).reduce((acc, key) => {
    if (!key.startsWith('query/')) {
      acc[key] = state[key]
    }
    return acc
  }, {})

const removeTaskFromDateBucket = (taskId, state, acc, key) => {
  const task = state[taskId]
  const buckets = state[key]
  if (task && task.dueDate) {
    const dueDate = task.dueDate.substring(0, 10)
    const taskIds = [...buckets[dueDate]]
    const indexToRemove = taskIds.indexOf(taskId)
    if (indexToRemove > -1) {
      taskIds.splice(indexToRemove, 1)
      acc[key] = {
        ...buckets,
        [dueDate]: taskIds,
      }
      acc[`${key}/count`] = state[`${key}/count`] - 1
    }
  }
}

const removeTaskFromQueryArray = (taskId, state, acc, key) => {
  const taskIds = [...state[key]]
  const indexToRemove = taskIds.indexOf(taskId)
  if (indexToRemove > -1) {
    taskIds.splice(indexToRemove, 1)
    acc[key] = taskIds
    acc[`${key}/count`] = state[`${key}/count`] - 1
  }
}

export const removeTaskFromQueryKey = (
  state,
  facetsToRemoveFrom = [],
  taskId,
  facetsToAddTo = [],
) =>
  Object.keys(state).reduce((acc, key) => {
    if (key.startsWith('query/') && !key.endsWith('/count')) {
      if (facetsToRemoveFrom.some(f => key.includes(`/${f}`))) {
        if (key.endsWith('/pages')) {
          // if it's the pages object, remove the taskId from the right page
          acc[key] = Object.keys(state[key]).reduce((newPages, page) => {
            newPages[page] = state[key][page].filter(id => id !== taskId)
            return newPages
          }, {})
        } else if (Array.isArray(state[key])) {
          // a plain array is the list of task loaded for that facet/query
          removeTaskFromQueryArray(taskId, state, acc, key)
        } else {
          // it should be a date buckets object
          removeTaskFromDateBucket(taskId, state, acc, key)
        }
      } else if (facetsToAddTo.some(f => key.includes(`/${f}`))) {
        acc[key] = undefined
        acc[`${key}/count`] = undefined
      }
    }
    return acc
  }, {})

const replaceTaskInQueryArray = (state, taskId, newTaskId, acc, key) => {
  const taskIndex = state[key].indexOf(taskId)
  if (taskIndex > -1) {
    const tasks = [...state[key]]
    tasks.splice(taskIndex, 1, newTaskId)
    acc[key] = tasks
  }
}

export const replaceTaskInQueryKeys = (state, facets, taskId, newTaskId) =>
  Object.keys(state).reduce((acc, key) => {
    if (
      facets.some(f => key.includes(`query/${f}`)) &&
      !key.endsWith('/count')
    ) {
      if (Array.isArray(state[key])) {
        replaceTaskInQueryArray(state, taskId, newTaskId, acc, key)
      } else {
        acc[key] = Object.keys(state[key]).reduce((dates, dateKey) => {
          replaceTaskInQueryArray(state[key], taskId, newTaskId, dates, dateKey)
          return dates
        }, {})
      }
    }
    return acc
  }, {})

const updateTotalsKey = (task, key, increment) => ({
  ...task,
  totals: {
    ...task.totals,
    [key]: (task.totals[key] || 0) + increment,
  },
})

export const updateTotals = (task, key = 'done', increment = 1) => {
  if (task && task.totals) {
    return updateTotalsKey(task, key, increment)
  }
  return task
}

export const isFormCopy = resource => {
  return !!resource.fromTemplateId && !!resource.copiedFrom
}
