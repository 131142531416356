import { get as getProp, isString } from 'lodash'
import { FIELD_TYPES, REQUIRED_TYPES } from '@concrete/resource'

const { ALL, MATCH, MATCH_MANY, NONE, ONE, SOME } = REQUIRED_TYPES
const { UPLOAD } = FIELD_TYPES
const { MULTIPLE_CHOICE_SINGLE, MULTIPLE_CHOICE_MANY } = FIELD_TYPES

const checkItemValue = ({ value }) =>
  (isString(value) && !!value.trim().length) || (!isString(value) && value)

// TODO: the backend should validate the fields as well
// otherwise exposing the api to 3rd parties could lead to a mess
export const isFieldValid = field => {
  const { items, required, type } = field || {}
  let isValid
  const filteredItems = items && items.filter(item => item.id)
  if (type === UPLOAD) {
    return (
      !required ||
      required === NONE ||
      !!(
        filteredItems.length &&
        filteredItems[0].value &&
        filteredItems[0].value.length
      )
    )
  }

  const format = value => {
    return typeof value === 'string' ? value.toLowerCase() : !!value
  }

  switch (required) {
    case ALL:
      isValid = filteredItems.every(checkItemValue)
      break
    // TODO: introduce maybe multiple match required types like MATCH_ALL, MATCH_SOME, etc.?
    // current MATCH implementation will act as a match all
    case MATCH:
      isValid = filteredItems.every(({ answer, value }) => {
        return format(answer) === format(value)
      })
      break
    case MATCH_MANY:
      const { answers, values } = filteredItems.reduce(
        (acc, item) => {
          return {
            ...acc,
            answers: [...acc.answers, item.answer],
            values: [...acc.values, item.value],
          }
        },
        { answers: [], values: [] },
      )

      isValid = values.every(response => {
        if (answers.includes(response)) {
          answers.splice(answers.indexOf(response), 1)
          return true
        }
        return false
      })
      break
    case NONE:
      isValid = true
      break
    case ONE:
      isValid = filteredItems.filter(checkItemValue).length === 1
      break
    case SOME:
      isValid = filteredItems.some(checkItemValue)
      break
    default:
      isValid = true
      break
  }
  return isValid
}

export const getUploads = (form, completionId) => {
  if (!form) return []
  const { fields, responses } = form
  return (fields || []).reduce((acc, field) => {
    if (field.type === UPLOAD) {
      const item = (field.items || [])[0]
      const itemId = item ? item.id : null
      acc.push.apply(
        acc,
        getProp(responses, `${field.id}.${completionId}.${itemId}`, []),
      )
    }
    return acc
  }, [])
}

export const normaliseForm = (form, completionId, checkValidity) => {
  let isValid = true
  const responses = form.responses
  const fields =
    form.fields?.map?.(field => {
      const items = (field.items || []).map(item => {
        const response = getProp(
          responses,
          `${field.id}.${completionId}.${item.id}`,
        )
        if (!response) return item
        return { ...item, value: response }
      })

      const response = { ...field, items }
      if (checkValidity) {
        response.fieldValid = isFieldValid({
          ...field,
          items,
        })
        if (!response.fieldValid) isValid = false
      }

      return response
    }) || []

  const response = form
  if (!!fields.length) response.fields = fields
  if (checkValidity) response.isValid = isValid

  return response
}

export const maintainIndexes = (form, field, item) => {
  const fields = form.fields.filter(f => f.id !== field.id)
  const fieldIndex = form.fields.findIndex(f => f.id === field.id)

  if (!!item) {
    const items = field.items.filter(i => i.id !== item.id)
    const itemIndex = field.items.findIndex(i => i.id === item.id)
    items.splice(itemIndex, 0, item)
    field.items = items
  }
  fields.splice(fieldIndex, 0, field)
  return fields
}
