import { call, put, takeEvery } from 'redux-saga/effects'
import reportsApi from './api/reports'

const DASHBOARD_REQUESTED = 'DASHBOARD_REQUESTED'
const DASHBOARD_LOADED = 'DASHBOARD_LOADED'
const DASHBOARD_LOAD_FAILED = 'DASHBOARD_LOAD_FAILED'

const actionTypes = {
  DASHBOARD_REQUESTED,
  DASHBOARD_LOADED,
  DASHBOARD_LOAD_FAILED,
}

const reducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_LOADED: {
      return {
        ...state,
        dashboard: action.dashboard,
      }
    }

    default:
      return state
  }
}

const requestDashboard = () => ({
  type: DASHBOARD_REQUESTED,
})

const selectDashboard = state => state.reports

function* fetchDashboardFromApi() {
  try {
    const dashboard = yield call(reportsApi.getDashboard)
    yield put({
      type: DASHBOARD_LOADED,
      dashboard,
    })
  } catch (e) {
    yield put({
      type: DASHBOARD_LOAD_FAILED,
      error: e.message,
    })
  }
}

function* saga() {
  yield takeEvery(DASHBOARD_REQUESTED, fetchDashboardFromApi)
}

export {
  actionTypes,
  fetchDashboardFromApi,
  reducer,
  requestDashboard,
  saga,
  selectDashboard,
}
