import transport from './transport'
import { getMimeTypeFromExtension } from '../helpers/assets'

async function uploadFile(file, parent, config, metadata) {
  const data = new FormData()
  let fileToUpload = file
  if (!file.type) {
    const filenameParts = file.name.split('.')
    const extension = filenameParts[filenameParts.length - 1]
    fileToUpload = new Blob([file], {
      type: getMimeTypeFromExtension(extension),
    })
  }
  data.append('file', fileToUpload, file.name)

  if (parent) {
    data.append('parent', parent)
  }

  if (metadata) {
    Object.entries(metadata).forEach(entry => data.append(...entry))
  }

  const response = await transport.post('/files', data, config)
  return response.data.file
}

async function uploadFileBase64(file, parent, config) {
  if (parent) {
    file.parent = parent
  }

  const response = await transport.post('/files', file, config)
  return response.data
}

async function downloadFile(file) {
  const response = await transport.get(`/files/${file.name}?type=download`, {
    responseType: 'arraybuffer',
  })
  return response.data
}

async function downloadFiles(files) {
  const urlResponses = await Promise.all(
    files.map(file =>
      transport.get(`/assets/files/${file._id}/download?redirect=false`),
    ),
  )
  // using fetch in an attempt to prevent error thrown by axios:
  // Uncaught DOMException: Failed to read the 'responseText' property from 'XMLHttpRequest': The value is only accessible if the object's 'responseType' is '' or 'text' (was 'arraybuffer')
  const dataResponses = await Promise.all(
    urlResponses.map((r, i) =>
      window.fetch(r.data.assetUrl, {
        method: 'GET',
        credentials: 'omit',
        headers: { accept: files[i].mimeType },
      }),
    ),
  )
  const responses = Promise.all(dataResponses.map(r => r.arrayBuffer()))
  return responses
}

export default {
  uploadFile,
  uploadFileBase64,
  downloadFile,
  downloadFiles,
}
