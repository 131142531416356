import { call, put, takeLatest, all, cancel } from 'redux-saga/effects'
import getProp from 'lodash/get'
import { getContextFromToken } from '@concrete/authorization'
import { TAG_TYPES } from '@concrete/resource'
import groupsApi from './api/groups'
import rolesApi from './api/roles'
import { actionTypes as roleActionTypes } from './roles'
import { requestTags } from './tags'
import { requestConnectedGroups, requestConnectedUsers } from './connect'
import {
  authenticateUser,
  fetchSession,
  fetchFirebaseToken,
} from './api/session'
import { start as startSession } from './session'
import { actionTypes as groupActionTypes, registerRootGroup } from './groups'

const { CATEGORIES } = TAG_TYPES

const actionTypes = {
  BOOTSTRAP_REQUESTED: 'BOOTSTRAP_REQUESTED',
  BOOTSTRAP_FAILED: 'BOOTSTRAP_FAILED',
  APPLICATION_BOOTSTRAPPED: 'APPLICATION_BOOTSTRAPPED',
  AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
  AUTHENTICATION_PARTIAL_FAIL: 'AUTHENTICATION_PARTIAL_FAIL',
  AUTHENTICATION_USER_NOT_FOUND: 'AUTHENTICATION_USER_NOT_FOUND',
}

function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.APPLICATION_BOOTSTRAPPED: {
      return {
        ...state,
        loading: false,
        timestamp: new Date().getTime(),
      }
    }

    case actionTypes.BOOTSTRAP_REQUESTED: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.AUTHENTICATION_USER_NOT_FOUND:
    case actionTypes.AUTHENTICATION_PARTIAL_FAIL: {
      return {
        ...state,
        error: true,
      }
    }

    default:
      return state
  }
}

const bootstrap = (
  token,
  isSsoToken = false,
  isMobile = false,
  provider = 'auth0',
) => ({
  type: actionTypes.BOOTSTRAP_REQUESTED,
  token,
  isSsoToken,
  isMobile,
  provider,
})

const isComplete = state => !!state.bootstrap?.timestamp
const isError = state => state.bootstrap?.error
const isBootstrapping = state => state.bootstrap?.loading
const selectBootstrapTime = state => state.bootstrap?.timestamp

function* bootstrapApplication(action) {
  try {
    if (action.isSsoToken) {
      try {
        yield call(authenticateUser, action.provider || 'auth0', action.token)
      } catch (error) {
        let dispatchAction

        switch (error.response?.status) {
          case 401:
            dispatchAction = actionTypes.AUTHENTICATION_FAILED
            break
          case 403:
            dispatchAction = actionTypes.AUTHENTICATION_PARTIAL_FAIL
            break
          case 404:
            dispatchAction = actionTypes.AUTHENTICATION_USER_NOT_FOUND
            break
        }

        if (!dispatchAction) {
          throw error
        }

        yield put({
          type: dispatchAction,
          error: error.response?.data?.message,
          errorCode: error.response?.status,
        })

        yield cancel()
      }
    }

    const sessionInfo = yield call(fetchSession)
    const { firebaseToken, channel } = yield call(fetchFirebaseToken)
    const { roles } = getContextFromToken(sessionInfo.token)
    const userSession = {
      ...sessionInfo,
      ...sessionInfo.user,
      roles,
      firebaseToken,
      channel,
    }
    userSession.user = undefined
    yield put(startSession(userSession))

    if (!action.isMobile) {
      const calls = [call(groupsApi.fetchGroups), call(rolesApi.fetchRoles)]

      const [allGroups, allRoles] = yield all(calls)
      yield put({
        type: groupActionTypes.GROUPS_LOADED,
        groups: allGroups,
      })

      yield put(
        registerRootGroup({
          _id: getProp(userSession, 'relations[0].group'),
        }),
      )

      yield put({
        type: roleActionTypes.ROLES_LOADED,
        roles: allRoles,
      })

      yield put(requestTags(CATEGORIES))
    }

    if (userSession.features?.includes('connect')) {
      yield put(requestConnectedGroups())
      yield put(requestConnectedUsers())
    }

    yield put({
      type: actionTypes.APPLICATION_BOOTSTRAPPED,
    })
  } catch (e) {
    yield put({
      type: actionTypes.BOOTSTRAP_FAILED,
      error: e.message,
    })
  }
}

function* saga() {
  yield takeLatest(actionTypes.BOOTSTRAP_REQUESTED, bootstrapApplication)
}

export {
  actionTypes,
  bootstrap,
  reducer,
  saga,
  isBootstrapping,
  isComplete,
  isError,
  selectBootstrapTime,
  bootstrapApplication,
}
