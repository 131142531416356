import axios from 'axios'
import axiosRetry from 'axios-retry'

const transport = axios.create()
transport.defaults.timeout = 30000
transport.defaults.withCredentials = true

const retryCondition = error =>
  axiosRetry.isNetworkOrIdempotentRequestError(error) ||
  error.code === 'ECONNABORTED' // Allow retrying after timeouts

axiosRetry(transport, { retries: 3, retryCondition })

function setBaseUrl(url) {
  transport.defaults.baseURL = url
}

function getBaseUrl() {
  return transport.defaults.baseURL
}

function registerUnauthorizedCallback(cb) {
  transport.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status === 401) {
        cb(error)
      }
      return Promise.reject(error)
    },
  )
}

function disableTransport() {
  const errorFunc = (...args) => {
    throw new Error(
      `Oops, you tried to make an API call: ${JSON.stringify(args)}`,
    )
  }
  transport.get = errorFunc
  transport.post = errorFunc
  transport.patch = errorFunc
  transport.delete = errorFunc
}

function useAdapter(adapter) {
  transport.defaults.adapter = adapter
}

export {
  disableTransport,
  setBaseUrl,
  getBaseUrl,
  registerUnauthorizedCallback,
  useAdapter,
}

export default transport
