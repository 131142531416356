import { RESOURCE_TYPES } from '@concrete/resource'
import {
  hasTimelineUpdates,
  hasNewApprovals,
  hasNewAttachments,
  hasNewComments,
} from './tasks'

const tasksFilters = {
  hasTimelineUpdates: ({ completion, timeline }) =>
    hasTimelineUpdates(completion, timeline),
  hasNewApprovals: ({ completion, approvals, currentUser }) =>
    hasNewApprovals(completion, approvals, currentUser._id),
  hasNewAttachments: ({ completion, attachments }) =>
    hasNewAttachments(completion, attachments),
  hasNewComments: ({ engagementStats, comments }) =>
    hasNewComments(engagementStats, comments),
  hasUploads: ({ completion }) => !!completion?.uploads.length,
}

export default {
  [RESOURCE_TYPES.TASKS]: tasksFilters,
}
