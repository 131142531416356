import {
  all,
  call,
  debounce,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects'
import getProp from 'lodash/get'
import isAfter from 'date-fns/isAfter'
import parseISODate from 'date-fns/parseISO'
import notificationsApi from './api/notifications'
import { selectCurrentUserId } from './session'
import {
  actionTypes as checkinsActionTypes,
  selectCheckin,
  checkinQuery,
  queryResource,
} from './checkins'
import { processCommentMessage } from './comments'
import { processConnectMessage } from './connect'

const NOTIFICATIONS_UPDATE_DEBOUNCE_RATE = 2000
const NOTIFICATIONS_UPDATE_OFFSET = 5000

const actionTypes = {
  INIT_EVENT_STREAM: 'INIT_EVENT_STREAM',
  INIT_EVENT_STREAM_FAILED: 'INIT_EVENT_STREAM_FAILED',
  EVENT_STREAM_ERROR: 'EVENT_STREAM_ERROR',
  READ_NOTIFICATION_REQUESTED: 'READ_NOTIFICATION_REQUESTED',
  NOTIFICATION_READ: 'NOTIFICATION_READ',
  READ_NOTIFICATION_FAILED: 'READ_NOTIFICATION_FAILED',
  REQUEST_NOTIFICATIONS: 'REQUEST_NOTIFICATIONS',
  REQUEST_NOTIFICATIONS_FAILED: 'REQUEST_NOTIFICATIONS_FAILED',
  LOAD_NOTIFICATIONS: 'LOAD_NOTIFICATIONS',
  NOTIFICATIONS_UPDATE: 'NOTIFICATIONS_UPDATE',
  NOTIFICATIONS_UPDATE_FAILED: 'NOTIFICATIONS_UPDATE_FAILED',
  NOTIFICATIONS_UPDATED: 'NOTIFICATIONS_UPDATED',
}

const notificationsUpdate = timestamp => ({
  type: actionTypes.NOTIFICATIONS_UPDATE,
  timestamp,
})

const readNotification = eventId => ({
  type: actionTypes.READ_NOTIFICATION_REQUESTED,
  eventId,
})

const requestNotifications = () => ({
  type: actionTypes.REQUEST_NOTIFICATIONS,
})

const loadNotifications = (notifications, lastCheckin) => ({
  type: actionTypes.LOAD_NOTIFICATIONS,
  notifications,
  lastCheckin,
})

const chainProcessors = (...processFunctions) => {
  return (message, baseDate, channel, params) => {
    let actions = []
    processFunctions.forEach(processFn => {
      const newActions = processFn(message, baseDate, channel, params)
      if (newActions) {
        actions = [...actions, ...newActions]
      }
    })
    return actions
  }
}

/**
 * This function processes a single notification that could trigger
 * realtime updates.
 * It returns an array of redux actions that will
 * be automatically dispatched after processing
 *
 * @param {*} message Message
 */
const processIncomingMessage = message =>
  chainProcessors(processCommentMessage, processConnectMessage)(message)

const reducer = (
  state = { all: [], lastNotification: new Date().toISOString() },
  action,
) => {
  switch (action.type) {
    case actionTypes.LOAD_NOTIFICATIONS: {
      const { notifications, lastCheckin } = action
      if (!notifications?.length) return state
      const all = [...state.all]
      const checkin = lastCheckin ? parseISODate(lastCheckin).getTime() : 0
      let newCount = 0
      const newNotifications = {}
      notifications
        .slice()
        .reverse() // oldest first
        .forEach(notification => {
          if (!state[notification.eventId]) {
            newNotifications[notification.eventId] = notification
            all.unshift(notification.eventId)
          }
          if (
            !notification.params?.read &&
            parseISODate(notification.createdAt).getTime() >= checkin
          ) {
            newCount++
          }
        })

      return {
        ...state,
        ...newNotifications,
        all,
        newCount,
      }
    }

    case actionTypes.NOTIFICATION_READ: {
      const { eventId } = action
      const notification = state[eventId]
      if (!notification) return state

      return {
        ...state,
        [eventId]: {
          ...notification,
          params: { ...notification.params, read: true },
        },
      }
    }

    case actionTypes.NOTIFICATIONS_UPDATED: {
      const { messages } = action

      let lastNotification = state.lastNotification
      if (
        !lastNotification ||
        isAfter(
          parseISODate(messages[messages.length - 1].createdAt),
          parseISODate(lastNotification),
        )
      ) {
        lastNotification = messages[messages.length - 1].createdAt
      }

      return {
        ...state,
        lastNotification,
      }
    }

    case checkinsActionTypes.CHECKIN_LOADED: {
      if (!action.topic.includes('notifications')) return state
      const newCount = Object.values(state.all).filter(
        m =>
          !m.params?.read &&
          isAfter(
            parseISODate(m.createdAt),
            parseISODate(action[action.facet]),
          ),
      ).length
      return {
        ...state,
        newCount,
      }
    }
    default:
      return state
  }
}

function* readNotificationSaga({ eventId }) {
  try {
    yield call(notificationsApi.readNotification, eventId)
    yield put({
      type: actionTypes.NOTIFICATION_READ,
      eventId,
    })
  } catch (e) {
    yield put({
      type: actionTypes.READ_NOTIFICATION_FAILED,
      eventId,
      error: e.message,
    })
  }
}

function* requestNotificationsSaga() {
  try {
    const currentUserId = yield select(selectCurrentUserId)
    // yield the whole query resource saga so we're sure to have loaded
    // the checkins before notifications
    yield* checkinQuery(queryResource([currentUserId], 'notifications'))
    const lastCheckin = yield select(
      selectCheckin,
      'notifications',
      currentUserId,
    )
    const notifications = yield call(notificationsApi.fetchNotifications)
    yield put(loadNotifications(notifications, lastCheckin?.viewed))
  } catch (error) {
    yield put({
      type: actionTypes.REQUEST_NOTIFICATIONS_FAILED,
      error,
    })
  }
}

function* notificationsUpdateSaga({ timestamp }) {
  try {
    const currentUserId = yield select(selectCurrentUserId)
    const lastCheckin = yield select(
      selectCheckin,
      'notifications',
      currentUserId,
    )
    const createdAt = yield select(selectLastNotificationTime)
    const time =
      (createdAt ? new Date(createdAt).getTime() : timestamp) -
      NOTIFICATIONS_UPDATE_OFFSET

    const messages = yield call(
      notificationsApi.fetchNotifications,
      true,
      new Date(time).toISOString(),
      'createdAt',
    )

    if (messages?.length) {
      yield put({
        type: actionTypes.NOTIFICATIONS_UPDATED,
        timestamp,
        messages,
      })

      const notifications = messages.filter(m => !m.silent)
      if (notifications?.length) {
        yield put(loadNotifications(notifications, lastCheckin?.viewed))
      }
      const actions = messages.reduce((acc, m) => {
        return acc.concat(processIncomingMessage(m) || [])
      }, [])
      yield all(actions.map(a => put(a)))
    }
  } catch (error) {
    yield put({
      type: actionTypes.NOTIFICATIONS_UPDATE_FAILED,
      timestamp,
      error,
    })
  }
}

function* saga() {
  yield takeLatest(
    actionTypes.READ_NOTIFICATION_REQUESTED,
    readNotificationSaga,
  )
  yield takeEvery(actionTypes.REQUEST_NOTIFICATIONS, requestNotificationsSaga)
  yield debounce(
    NOTIFICATIONS_UPDATE_DEBOUNCE_RATE,
    actionTypes.NOTIFICATIONS_UPDATE,
    notificationsUpdateSaga,
  )
}

const selectNotifications = state => getProp(state, 'notifications.all')
const selectNotification = (state, id) => getProp(state, ['notifications', id])
const selectLastNotificationTime = state =>
  getProp(state, 'notifications.lastNotification')

const selectNotificationsCount = state =>
  getProp(state, 'notifications.newCount', 0)

export {
  NOTIFICATIONS_UPDATE_DEBOUNCE_RATE,
  NOTIFICATIONS_UPDATE_OFFSET,
  actionTypes,
  notificationsUpdate,
  readNotification,
  loadNotifications,
  requestNotifications,
  reducer,
  readNotificationSaga,
  requestNotificationsSaga,
  notificationsUpdateSaga,
  saga,
  selectNotifications,
  selectNotification,
  selectNotificationsCount,
  selectLastNotificationTime,
  processIncomingMessage,
  chainProcessors,
}
