import { RESOURCE_TYPES } from '@concrete/resource'
import transport from './transport'

const { STORIES } = RESOURCE_TYPES

async function fetchEvents(resourceType, query, facet) {
  // TODO: replace with fetchCalendarDocuments
  const url = `/v3/${resourceType}/facets/${facet}/calendar`
  if (resourceType === STORIES) {
    delete query.dueDate
  }
  const conf = {
    params: query,
  }
  const response = await transport.get(url, conf)
  return response.data
}

export default { fetchEvents }
