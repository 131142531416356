import transport from './transport'
import { RESOURCE_TYPES } from '@concrete/resource'

const { ALL, ASSETS } = RESOURCE_TYPES

async function distribute(id, resourceType, audience) {
  const type = resourceType === ASSETS ? 'folders' : resourceType
  const response = await transport.post(`/v3/${type}/${id}/distribute`, {
    audience,
  })
  return response.data
}

async function schedule(resourceType, id) {
  const response = await transport.post(`/v3/${resourceType}/${id}/schedule`)
  return response.data
}

async function notify(resourceType, id, message) {
  const url = `/v3/${resourceType}/${id}/notify`
  const response = await transport.put(url, {
    message,
  })
  return response.data
}

async function fetchAssignmentViewers(resourceType, resourceId, completionId) {
  const response = await transport.get(
    completionId
      ? `/v3/${resourceType}/${resourceId}/completions/${completionId}/viewers`
      : `/v3/${resourceType}/${resourceId}/distribution/viewers`,
  )
  return response.data
}

async function fetchResources(query) {
  const { facet, resourceType, ...params } = query
  const response = await transport.get(
    `/v3/${resourceType === ALL ? '' : `${resourceType}/`}facets/${facet}`,
    {
      params,
    },
  )
  return response.data
}

async function fetchFacets(type, group) {
  const response = await transport.get(
    `/v3/${type === ALL ? '' : `${type}/`}facets?group=${group}`,
  )
  return response.data
}

export default {
  fetchResources,
  fetchFacets,
  fetchAssignmentViewers,
  distribute,
  schedule,
  notify,
}
