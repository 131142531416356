import transport from './transport'

async function generateCode({
  expiresAt,
  canViewGroup,
  codeExpiresAt,
  userId,
  groupId,
}) {
  const response = await transport.post(`/connect/generateCode`, {
    expiresAt,
    canViewGroup,
    codeExpiresAt,
    userId,
    groupId,
  })
  return response.data.code
}

async function checkIn(code) {
  const response = await transport.post(`/connect/checkIn`, { code })
  return response.data
}

async function deactivateCode(code) {
  const response = await transport.post(`/connect/deactivateCode`, { code })
  return response.data
}

async function fetchConnectedUsers(query) {
  const response = await transport.get(`/connect/users`, {
    params: query,
  })
  return response.data
}

async function fetchConnectedGroups() {
  const response = await transport.get(`/connect/groups`)
  return response.data
}

export default {
  generateCode,
  checkIn,
  deactivateCode,
  fetchConnectedUsers,
  fetchConnectedGroups,
}
