import transport from './transport'

async function fetchDistributionLists(type) {
  const url = `/distributionLists/${type}`
  const response = await transport.get(url)
  return response.data && response.data.distributionLists
}

async function saveDistributionList(type, name, recipients) {
  const response = await transport.post(`/distributionLists/${type}`, {
    name,
    recipients,
  })
  return response.data
}

async function editDistributionList(id, name) {
  const response = await transport.patch(`/distributionLists/${id}`, { name })
  return response.data
}

async function deleteDistributionList(id) {
  const response = await transport.delete(`/distributionLists/${id}`)
  return response.data
}

export default {
  fetchDistributionLists,
  saveDistributionList,
  editDistributionList,
  deleteDistributionList,
}
