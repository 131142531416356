import transport from './transport'

async function fetchTemplates(params) {
  const url = '/v3/templates'
  const response = await transport.get(url, { params })
  return response.data
}

async function fetchTemplate(id) {
  const response = await transport.get(`/v3/templates/${id}`)
  return response.data
}

async function createTemplate(template) {
  const response = await transport.post('/v3/templates', template)
  return response.data
}

async function utiliseTemplate(id) {
  const response = await transport.post(`/v3/templates/${id}/utilise`)
  return response.data
}

async function renameTemplate(id, name) {
  const response = await transport.patch(`/v3/templates/${id}`, {
    name,
  })
  return response.data
}

async function deleteTemplate(id) {
  const response = await transport.delete(`/v3/templates/${id}`)
  return response.data
}

export default {
  fetchTemplate,
  fetchTemplates,
  createTemplate,
  renameTemplate,
  deleteTemplate,
  utiliseTemplate,
}
