import transport from './transport'

async function fetchTags(tagType, query = null) {
  const url = `/v3/${tagType}/facets/all`
  const { data } = await transport.get(url, { params: query })
  return data
}

async function associateTag(tagType, tagId, resourceType, resourceId) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/${tagType}/${tagId}/associate`,
  )
  return response.data
}

async function unassociateTag(tagType, tagId, resourceType, resourceId) {
  const response = await transport.post(
    `/v3/${resourceType}/${resourceId}/${tagType}/${tagId}/unassociate`,
  )
  return response.data
}

async function createTag(tagType, body) {
  const response = await transport.post(`/v3/${tagType}`, body)
  return response.data
}

export default { fetchTags, createTag, associateTag, unassociateTag }
