import { concat, get as getProp } from 'lodash'
import { normaliseId } from '../utils'
import { getBaseUrl } from '../api/transport'

export function extractTaskUsers(task) {
  if (!task) return []
  const approvers = task.approvers ? task.approvers.map(a => a.user) : []
  const assignees = task.audience ? task.audience.users : []
  const uploadsReviewers = task.doerAttachments
    ? task.doerAttachments.filter(a => a.reviewedBy).map(a => a.reviewedBy)
    : []

  const allUsers = concat(
    approvers,
    assignees,
    uploadsReviewers,
    task.assignedTo && task.assignedTo._id ? [task.assignedTo] : [],
    task.currentApprover && task.currentApprover._id
      ? [task.currentApprover]
      : [],
    task.createdBy && task.createdBy._id ? [task.createdBy] : [],
    task.rejectedBy && task.rejectedBy._id ? [task.rejectedBy] : [],
    task.scheduledBy && task.scheduledBy._id ? [task.scheduledBy] : [],
    task.cancelledBy && task.cancelledBy._id ? [task.cancelledBy] : [],
    task.sittingWith && task.sittingWith.type === 'user'
      ? [{ ...task.sittingWith, type: undefined }]
      : [],
  )
  return allUsers.map(u => normaliseUser(u))
}

export const extractAvatar = user =>
  user?.profileImg?._id
    ? `${getBaseUrl()}assets/files/${user.profileImg._id}/thumbnail?width=90`
    : undefined

export function normaliseUser(user, defaults = {}) {
  const firstRelation = getProp(user, 'relations[0]', {
    group: defaults.groupId,
    role: defaults.roleId,
  })
  const groupId = normaliseId(firstRelation.group)
  const roleId = normaliseId(firstRelation.role)
  return {
    ...defaults,
    ...user,
    features: undefined,
    groupId,
    roleId,
    avatar: extractAvatar(user),
  }
}
