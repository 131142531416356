import { get as getProp } from 'lodash'
import uuid from 'uuid'
import { actionTypes as commentsActionTypes } from './comments'

const NEW_MARKUP_CREATED = 'NEW_MARKUP_CREATED'
const NEW_MARKUP_REMOVED = 'NEW_MARKUP_REMOVED'

const actionTypes = {
  NEW_MARKUP_CREATED,
  NEW_MARKUP_REMOVED,
}

const createNewMarkup = (key, point) => {
  if (!point.x || !point.y) {
    throw new Error('point should have both x and y coordinates specified')
  }
  return {
    type: NEW_MARKUP_CREATED,
    key,
    point,
    id: uuid(),
  }
}

const removeNewMarkup = () => ({
  type: NEW_MARKUP_REMOVED,
})

const selectNewMarkup = state => getProp(state, 'markups.newPoint')
const selectMarkups = (state, key) => getProp(state, `markups.${key}`)

const extractMarkupPoint = comment => getProp(comment, 'metadata.markup')

const reducer = (state = {}, action) => {
  switch (action.type) {
    case commentsActionTypes.COMMENTS_LOADED: {
      const oldState = {
        ...state,
      }
      const extractedMarkups = action.comments.reduce((markups, comment) => {
        if (!comment.entities) return markups
        const markup = extractMarkupPoint(comment)
        if (markup && markup.key) {
          markups[markup.key] = (markups[markup.key] || []).concat(comment.id)
        }
        return markups
      }, {})

      return {
        ...oldState,
        ...extractedMarkups,
      }
    }

    case commentsActionTypes.COMMENT_ADDED: {
      const { comment } = action
      const markup = extractMarkupPoint(comment)
      const newState = {
        ...state,
      }

      if (markup && markup.key) {
        newState[markup.key] = (newState[markup.key] || []).concat(comment.id)
        if (state.newPoint && markup.id === state.newPoint.id) {
          newState.newPoint = null
        }
      }

      return newState
    }

    case commentsActionTypes.COMMENT_DELETED: {
      const { comment } = action
      const markup = extractMarkupPoint(comment)
      if (!markup) return state
      return {
        ...state,
        [markup.key]: state[markup.key].filter(
          commentId => commentId !== comment.id,
        ),
      }
    }

    case NEW_MARKUP_CREATED: {
      const { id, key, point } = action
      return {
        ...state,
        newPoint: {
          id,
          key,
          ...point,
        },
      }
    }

    case NEW_MARKUP_REMOVED: {
      return {
        ...state,
        newPoint: null,
      }
    }

    default:
      return state
  }
}

export {
  actionTypes,
  reducer,
  selectNewMarkup,
  selectMarkups,
  createNewMarkup,
  removeNewMarkup,
  extractMarkupPoint,
}
