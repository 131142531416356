import transport from './transport'

async function authenticateUser(authProvider, ssoToken) {
  const response = await transport.post('/session', {
    provider: authProvider,
    token: ssoToken,
  })

  return response.data
}

async function fetchSession() {
  const { data } = await transport.get('/session')
  return data
}

async function fetchFirebaseToken() {
  const res = await transport.post('/firebaseToken/browser')
  const { firebaseToken, channel } = res.data

  return { firebaseToken, channel }
}

export { authenticateUser, fetchSession, fetchFirebaseToken }
