import { normaliseId } from '../utils'
import { canBeReminded, canBeCancelled } from './tasks'

export const computeRollupNode = (node, completion) => {
  const { status } = completion
  const assignees = node?.assignees || []
  // assignees roll up
  const assignedTo = normaliseId(completion.assignedTo)
  let userFacets = { ...node?.users?.[assignedTo] }
  if (assignedTo) {
    if (!node?.users?.[assignedTo]) assignees.push(assignedTo)
    userFacets = {
      [assignedTo]: {
        ...userFacets,
        [status]: (userFacets[status] || 0) + 1,
        total: (userFacets.total || 0) + 1,
      },
    }
  }
  return {
    ...(node || {}),
    total: (node?.total || 0) + 1,
    remindable: (node?.remindable || 0) + (canBeReminded(completion) ? 1 : 0),
    cancellable:
      (node?.cancellable || 0) + (canBeCancelled(completion) ? 1 : 0),
    facets: {
      ...node?.facets,
      [status]: (node?.facets?.[status] || 0) + 1,
    },
    assignees,
    users: {
      ...node?.users,
      ...userFacets,
    },
  }
}

/**
 * Computes the counts for statuses, etc. for the groups in a distribution
 * hierarchy
 *
 * @param {Array} completions An array of completions
 * @param {Object} groups A map of groupId -> group rollup
 */
export const rollupCompletions = (completions, groups) => {
  return [...completions].reduce((acc, completion) => {
    const { group } = completion
    // skip completions for which the group is not in the store
    // it's likely the group has been archived
    if (!groups[group]) return acc
    // for each completion calculate the counts for its group
    const node = computeRollupNode(acc[group], completion)
    // keep a reference to completions for groups
    const completions = node?.completions || []
    completions.push(completion._id)
    acc[group] = {
      ...node,
      completions,
    }
    // rollup the counts for each ancestor group too
    groups[group].ancestors?.forEach(ancestorGroup => {
      acc[ancestorGroup] = computeRollupNode(acc[ancestorGroup], completion)
    })
    return acc
  }, {})
}
