import { difference } from 'lodash'
import { RESOURCE_TYPES, generateUrn } from '@concrete/resource'
import { normaliseId } from '../utils'

const { ASSETS } = RESOURCE_TYPES

const MIME_TYPE_BY_EXTENSION = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  svg: 'image/svg+xml',
  bmp: 'image/bmp',
  webp: 'image/webp',
  ras: 'image/cmu-raster',
  ico: 'image/x-icon',
  dwg: 'image/vnd.dwg',
  dxf: 'image/vnd.dwg',
  pct: 'image/x-pict',
  tif: 'image/tiff',
  tga: 'image/tga',
  mp3: 'audio/mp3',
  m4a: 'audio/m4a',
  ogg: 'audio/ogg',
  wav: 'audio/wav',
  wma: 'audio/wma',
  mp4: 'video/mp4',
  m4v: 'video/m4v',
  mov: 'video/mov',
  wmv: 'video/wmv',
  avi: 'video/avi',
  mpg: 'video/mpg',
  mpeg: 'video/mpeg',
  ogv: 'video/ogv',
  '3gp': 'video/3gp',
  flv: 'video/flv',
  txt: 'text/plain',
  csv: 'text/csv',
  pdf: 'application/pdf',
  rtf: 'application/rtf',
  doc: 'application/msword',
  dot: 'application/msword',
  docx:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ppt: 'application/vnd.ms-powerpoint',
  pps: 'application/vnd.ms-powerpoint',
  pptx:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ppsx:
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  xlt: 'application/excel',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  odt: 'application/vnd.oasis.opendocument.text',
  odx: 'application/odx',
  key: 'application/pkcs8',
  epub: 'application/epub+zip',
  zip: 'application/zip',
  dwf: 'model/vnd.dwf',
  pcl: 'application/vnd.hp-pcl',
  ai: 'application/postscript',
  eps: 'application/postscript',
  wpg: 'application/wordperfect5.1',
}

export const getMimeTypeFromExtension = extension =>
  MIME_TYPE_BY_EXTENSION[extension]

export const removeAssetsFromQueryKey = (
  state,
  facetsToRemoveFrom = [],
  assets = [],
  predicate = () => true,
) =>
  Object.keys(state).reduce((acc, key) => {
    if (
      key.startsWith('query/') &&
      !key.endsWith('/count') &&
      facetsToRemoveFrom.some(f => key.includes(`/${f}`)) &&
      predicate(key)
    ) {
      if (key.endsWith('/pages')) {
        acc[key] = {}
        Object.keys(state[key]).forEach(page => {
          acc[key][page] = difference(state[key][page], assets)
        })
      } else {
        acc[key] = difference(state[key], assets)
        acc[`${key}/count`] = state[`${key}/count`] - assets.length
      }
    }
    return acc
  }, {})

export const mapItemsToProperties = items => {
  return items.reduce(
    (acc, item) => {
      const {
        _id,
        isFolder,
        distribution,
        coverImage,
        tags: tagItems = [],
      } = item
      if (coverImage) acc.coverImages.push(normaliseId(coverImage))
      if (isFolder) acc.topics.push(`${ASSETS}.${_id}`)
      if (distribution) acc.distributionItems.push(distribution)
      const asset = item
      delete asset.distribution
      delete asset.compliance
      delete asset.tags

      acc.assetItems.push(asset)
      acc.tags[generateUrn(ASSETS, _id)] = tagItems
      return acc
    },
    {
      topics: [],
      distributionItems: [],
      coverImages: [],
      tags: {},
      assetItems: [],
    },
  )
}
