import { RESOURCE_TYPES, FACET_NAMES } from '@concrete/resource'
import { call, put, takeEvery, select } from 'redux-saga/effects'
import { get as getProp, keyBy, omit } from 'lodash'
import api from './api/documents'
import {
  createQueryKey,
  createQueryPages,
  removeResourceFromQueryKey,
} from './utils'
import { isBootstrapping } from './bootstrap'
import { actionTypes as checkinsActionTypes } from './checkins'
import { queryResourceStats } from './comments'
import { actionTypes as complianceActionTypes } from './compliance'
import { actionTypes as tagActionTypes } from './tags'
import { actionTypes as resourceActionTypes } from './resource'
import { selectAll } from './groups'
import { actionTypes as distributionActionTypes } from './distribution'
import { selectCurrentGroupId } from './session'
import { fetchMissingUsers } from './users'
import { fetchMissingFiles } from './files'
import { requestTaskNotes } from './taskNotes'
import { rollupDistribution } from './helpers/distribution'
import { normaliseForm } from './helpers/forms'
import moment from 'moment'
import {
  mapItemsToProperties,
  keyPropertiesByResourceType,
} from './helpers/documents'

const { TASKS, FORMS, STORIES, EVENTS } = RESOURCE_TYPES
const {
  ALL,
  FEED,
  DRAFTS,
  SENT,
  SCHEDULED,
  ARCHIVED,
  OVERVIEW,
  PUBLISHED,
  MY,
  UNASSIGNED,
  AWAITING_APPROVAL,
} = FACET_NAMES

const actionTypes = {
  DOCUMENTS_REQUESTED: 'DOCUMENTS_REQUESTED',
  DOCUMENTS_REQUEST_FAILED: 'DOCUMENTS_REQUEST_FAILED',
  DOCUMENTS_LOADED: 'DOCUMENTS_LOADED',
  DOCUMENT_FACETS_REQUESTED: 'DOCUMENT_FACETS_REQUESTED',
  DOCUMENT_FACETS_REQUEST_FAILED: 'DOCUMENT_FACETS_REQUEST_FAILED',
  DOCUMENT_FACETS_LOADED: 'DOCUMENT_FACETS_LOADED',
  DOCUMENT_FACETS_COUNTS_REQUESTED: 'DOCUMENT_FACETS_COUNT_REQUESTED',
  DOCUMENT_FACETS_COUNTS_LOADED: 'DOCUMENT_FACETS_COUNTS_LOADED',
  DOCUMENT_FACETS_COUNTS_REQUEST_FAILED:
    'DOCUMENT_FACETS_COUNTS_REQUEST_FAILED',
  DOCUMENT_CREATED: 'DOCUMENT_CREATED',
  CREATE_DOCUMENT_FAILED: 'CREATE_DOCUMENT_FAILED',
  CREATE_DOCUMENT_REQUESTED: 'CREATE_DOCUMENT_REQUESTED',
  DOCUMENT_REQUESTED: 'DOCUMENT_REQUESTED',
  DOCUMENT_LOADED: 'DOCUMENT_LOADED',
  DOCUMENT_REQUEST_FAILED: 'DOCUMENT_REQUEST_FAILED',
  DOCUMENT_VIEWED: 'DOCUMENT_VIEWED',
  DELETE_DOCUMENT_REQUESTED: 'DELETE_DOCUMENT_REQUESTED',
  DELETE_DOCUMENT_FAILED: 'DELETE_DOCUMENT_FAILED',
  DOCUMENT_DELETED: 'DOCUMENT_DELETED',
  UPDATE_DOCUMENT_FIELDS_REQUESTED: 'UPDATE_DOCUMENT_FIELDS_REQUESTED',
  DOCUMENT_FIELDS_UPDATE_FAILED: 'DOCUMENT_FIELDS_UPDATE_FAILED',
  PIN_DOCUMENT: 'PIN_DOCUMENT',
  PIN_DOCUMENT_FAILED: 'PIN_DOCUMENT_FAILED',
  UNPIN_DOCUMENT: 'UNPIN_DOCUMENT',
  UNPIN_DOCUMENT_FAILED: 'UNPIN_DOCUMENT_FAILED',
  DOCUMENT_COPY_REQUESTED: 'DOCUMENT_COPY_REQUESTED',
  DOCUMENT_COPY_FAILED: 'DOCUMENT_COPY_FAILED',
  DOCUMENT_COPIED: 'DOCUMENT_COPIED',
  DOCUMENT_CREATED_FROM_TEMPLATE: 'DOCUMENT_CREATED_FROM_TEMPLATE',
  DOCUMENT_FORWARD_REQUESTED: 'DOCUMENT_FORWARD_REQUESTED',
  DOCUMENT_FORWARDED: 'DOCUMENT_FORWARDED',
  DOCUMENT_FORWARD_REQUEST_FAILED: 'DOCUMENT_FORWARD_REQUEST_FAILED',
  DOCUMENT_SKETCH_SUBMIT_REQUESTED: 'DOCUMENT_SKETCH_SUBMIT_REQUESTED',
  DOCUMENT_SKETCH_SUBMITTED: 'DOCUMENT_SKETCH_SUBMITTED',
  DOCUMENT_SKETCH_SUBMIT_REQUEST_FAILED:
    'DOCUMENT_SKETCH_SUBMIT_REQUEST_FAILED',
}

const requestDocuments = query => ({
  type: actionTypes.DOCUMENTS_REQUESTED,
  query,
})

const submitSketchDocument = (resourceType, id) => ({
  type: actionTypes.DOCUMENT_SKETCH_SUBMIT_REQUESTED,
  resourceType,
  id,
})

const createDocument = (resourceType, documentBody) => ({
  type: actionTypes.CREATE_DOCUMENT_REQUESTED,
  resourceType,
  documentBody,
})

const requestDocument = (resourceType, id) => ({
  type: actionTypes.DOCUMENT_REQUESTED,
  resourceType,
  id,
})

const requestDocumentFacets = (resourceType, groupId) => ({
  type: actionTypes.DOCUMENT_FACETS_REQUESTED,
  resourceType,
  groupId,
})

const requestDocumentFacetsCounts = (resourceType, groupId) => ({
  type: actionTypes.DOCUMENT_FACETS_COUNTS_REQUESTED,
  resourceType,
  groupId,
})

const forwardDocument = (resourceType, id, audience) => ({
  type: actionTypes.DOCUMENT_FORWARD_REQUESTED,
  resourceType,
  id,
  audience,
})

const viewLastCreatedDocument = resourceType => ({
  type: actionTypes.DOCUMENT_VIEWED,
  resourceType,
})

const deleteDocument = (resourceType, id) => ({
  type: actionTypes.DELETE_DOCUMENT_REQUESTED,
  resourceType,
  id,
})

const updateDocumentFields = (resourceType, id, fields) => ({
  type: actionTypes.UPDATE_DOCUMENT_FIELDS_REQUESTED,
  resourceType,
  id,
  fields,
})

const pinDocument = (resourceType, id, pinnedUntil) => ({
  type: actionTypes.PIN_DOCUMENT,
  resourceType,
  id,
  pinnedOn: moment().toISOString(),
  pinnedUntil:
    pinnedUntil ||
    moment()
      .add(30, 'days')
      .toISOString(),
})

const unpinDocument = (resourceType, id) => ({
  type: actionTypes.UNPIN_DOCUMENT,
  resourceType,
  id,
})

const copyDocument = (resourceType, id, namePrefix, callback) => ({
  type: actionTypes.DOCUMENT_COPY_REQUESTED,
  resourceType,
  id,
  namePrefix,
  callback,
})

function reducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.DOCUMENTS_REQUESTED: {
      const { query } = action
      const { resourceType } = query
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [`${getQueryKey(query)}/status`]: {
            loading: true,
          },
        },
      }
    }

    case actionTypes.DOCUMENTS_LOADED: {
      const { query, total, items = [] } = action
      const { facet, resourceType } = query

      const {
        itemsKeyedByType,
        aclsKeyedByType,
        idsKeyedByType,
      } = keyPropertiesByResourceType(items)

      const resourceTypes = items.length
        ? Object.keys(itemsKeyedByType)
        : [resourceType]

      const newState = resourceTypes.reduce((acc, type) => {
        const currentState = state[type] || {}
        acc[type] = {
          ...currentState,
          ...createQueryPages(
            currentState,
            facet,
            query,
            idsKeyedByType[type] || [],
            total,
          ),
          ...itemsKeyedByType[type],
          ...aclsKeyedByType[type],
          [`${getQueryKey(query)}/status`]: {
            loading: false,
          },
        }
        return acc
      }, {})
      return { ...state, ...newState }
    }

    case actionTypes.DOCUMENTS_REQUEST_FAILED: {
      const { query, error } = action
      const { resourceType } = query
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [`${getQueryKey(query)}/status`]: {
            loading: false,
            error,
          },
        },
      }
    }

    case actionTypes.DOCUMENT_REQUESTED: {
      const { resourceType, id } = action
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [`${id}/status`]: {
            loading: true,
          },
        },
      }
    }

    case actionTypes.DOCUMENT_REQUEST_FAILED: {
      const { resourceType, id, error } = action
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [`${id}/status`]: {
            loading: false,
            error,
          },
        },
      }
    }

    case actionTypes.DOCUMENT_LOADED: {
      const { resourceType, item, acls } = action
      const { _id } = item
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [_id]: {
            ...item,
            attachments: undefined,
          },
          [`${_id}/acls`]: acls,
          [`${_id}/status`]: {
            loading: false,
          },
        },
      }
    }

    case actionTypes.DOCUMENT_CREATED:
    case actionTypes.DOCUMENT_CREATED_FROM_TEMPLATE: {
      const { resourceType, item } = action
      const { _id } = item
      return {
        ...state,
        lastCreatedType: resourceType,
        [resourceType]: {
          ...state[resourceType],
          lastCreatedDocumentId: _id,
          [_id]: item,
          [`${_id}/status`]: {
            loading: false,
          },
        },
      }
    }

    case actionTypes.DOCUMENT_VIEWED: {
      const { resourceType } = action
      return {
        ...state,
        [resourceType]: {
          ...(state[resourceType] || {}),
          lastCreatedDocumentId: undefined,
        },
      }
    }

    case actionTypes.DOCUMENT_FACETS_REQUESTED: {
      const { resourceType, groupId } = action
      const resourceTypeState = state[resourceType] || {}
      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [`facets/${groupId}/status`]: {
            ...resourceTypeState[`facets/${groupId}/status`],
            loading: true,
          },
        },
      }
    }

    case actionTypes.DOCUMENT_FACETS_COUNTS_REQUESTED: {
      const { resourceType, groupId } = action
      const resourceTypeState = state[resourceType] || {}
      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [`facets/${groupId}/count/status`]: {
            ...resourceTypeState[`facets/${groupId}/count/status`],
            loading: true,
          },
        },
      }
    }

    case actionTypes.DOCUMENT_FACETS_COUNTS_REQUEST_FAILED: {
      const { resourceType, groupId, error } = action
      const resourceTypeState = state[resourceType] || {}
      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [`facets/${groupId}/count/status`]: {
            ...resourceTypeState[`facets/${groupId}/count/status`],
            error,
            loading: false,
          },
        },
      }
    }

    case actionTypes.DOCUMENT_FACETS_LOADED: {
      const { resourceType, groupId, facets } = action
      const resourceTypeState = state[resourceType] || {}
      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [`facets/${groupId}`]: keyBy(facets, 'key'),
          [`facets/${groupId}/all`]: facets.map(({ key }) => key),
          [`facets/${groupId}/status`]: {
            ...resourceTypeState[`facets/${groupId}/status`],
            error: undefined,
            loading: false,
          },
        },
      }
    }

    case actionTypes.DOCUMENT_FACETS_REQUEST_FAILED: {
      const { resourceType, groupId, error } = action
      const resourceTypeState = state[resourceType] || {}
      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [`facets/${groupId}/status`]: {
            ...resourceTypeState[`facets/${groupId}/status`],
            error,
            loading: false,
          },
        },
      }
    }

    case actionTypes.DOCUMENT_FACETS_COUNTS_LOADED: {
      const { resourceType, counts, groupId } = action
      const resourceTypeState = state[resourceType] || {}
      const facetState = resourceTypeState[`facets/${groupId}`] || {}

      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [`facets/${groupId}`]: {
            ...facetState,
            ...counts.items.reduce((acc, item) => {
              acc[item.name] = {
                ...facetState[item.name],
                total: item.total,
              }
              return acc
            }, {}),
          },
          [`facets/${groupId}/count/status`]: {
            ...resourceTypeState[`facets/${groupId}/count/status`],
            error: undefined,
            loading: false,
          },
        },
      }
    }
    case actionTypes.DOCUMENT_SKETCH_SUBMITTED:
    case distributionActionTypes.DISTRIBUTE_RESOURCE_REQUESTED: {
      const { resourceType, id, commitPayload } = action
      if (
        ![TASKS, FORMS, STORIES].includes(resourceType) ||
        commitPayload?.isDistributed
      )
        return state
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [`${id}/status`]: {
            loading: true,
          },
        },
      }
    }
    case complianceActionTypes.COMPLETION_APPROVERS_UPDATED:
    case complianceActionTypes.COMPLETION_ATTACHMENTS_UPDATED:
    case complianceActionTypes.COMPLETION_CANCELLED:
    case complianceActionTypes.COMPLETION_REJECTED:
    case complianceActionTypes.COMPLETION_APPROVED:
    case complianceActionTypes.COMPLETION_SUBMITTED_FOR_REVIEW:
    case complianceActionTypes.COMPLIANCE_APPROVERS_UPDATED:
    case complianceActionTypes.COMPLETION_UNASSIGNED:
    case complianceActionTypes.COMPLETION_STARTED:
    case complianceActionTypes.COMPLETION_COMPLETED:
    case complianceActionTypes.COMPLIANCE_CLEANUP_COMPLETED:
    case complianceActionTypes.COMPLETIONS_LOADED: {
      const { acl, resourceType, id } = action
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [`${id}/acls`]: acl,
          [`${id}/status`]: {
            loading: false,
          },
        },
      }
    }
    case distributionActionTypes.DISTRIBUTION_FIELDS_UPDATED:
    case distributionActionTypes.AUDIENCE_UPDATED: {
      const { acl, resourceType, id } = action
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [`${id}/acls`]: acl,
        },
      }
    }

    case distributionActionTypes.SCHEDULE_RESOURCE_FAILED:
    case distributionActionTypes.RESOURCE_SCHEDULED:
    case distributionActionTypes.DISTRIBUTE_RESOURCE_FAILED:
    case distributionActionTypes.RESOURCE_DISTRIBUTED: {
      const { resourceType, id, groupId } = action
      if (![TASKS, FORMS, STORIES].includes(resourceType)) return state
      const resourceTypeState = state[resourceType]
      const facetState = resourceTypeState[`facets/${groupId}`] || {}

      const updatedFacetState = {
        [FEED]: {
          ...facetState[FEED],
          total: facetState[FEED]?.total + 1 || 1,
        },
        [SENT]: {
          ...facetState[SENT],
          total: facetState[SENT]?.total + 1 || 1,
        },
      }

      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          ...removeResourceFromQueryKey(state[resourceType], [DRAFTS], id),
          lastCreatedDocumentId: undefined,
          [`facets/${groupId}`]: {
            ...facetState,
            ...updatedFacetState,
            [DRAFTS]: {
              ...facetState[DRAFTS],
              total: facetState[DRAFTS]?.total - 1 || 0,
            },
          },
          [`${id}/status`]: {
            loading: false,
          },
        },
      }
    }
    case complianceActionTypes.COMPLIANCE_CANCEL_REQUESTED:
    case complianceActionTypes.COMPLETION_CANCEL_REQUESTED:
    case complianceActionTypes.REJECT_COMPLETION_REQUESTED:
    case complianceActionTypes.APPROVE_COMPLETION_REQUESTED:
    case complianceActionTypes.SUBMIT_COMPLETION_REQUESTED:
    case complianceActionTypes.ADD_COMPLETION_ATTACHMENTS_REQUESTED:
    case complianceActionTypes.REMOVE_COMPLETION_ATTACHMENT_REQUESTED:
    case complianceActionTypes.COMPLETION_UNASSIGN_REQUESTED:
    case complianceActionTypes.COMPLETION_START_REQUESTED:
    case complianceActionTypes.COMPLETION_COMPLETE_REQUESTED:
    case complianceActionTypes.REJECT_COMPLETION_REQUESTED:
    case actionTypes.DELETE_DOCUMENT_REQUESTED: {
      const { resourceType, id } = action
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [`${id}/status`]: {
            loading: true,
          },
        },
      }
    }
    case complianceActionTypes.UPDATE_COMPLETION_APPROVERS_FAILED:
    case complianceActionTypes.ADD_COMPLETION_ATTACHMENTS_FAILED:
    case complianceActionTypes.REMOVE_COMPLETION_ATTACHMENT_FAILED:
    case complianceActionTypes.COMPLIANCE_CANCEL_REQUEST_FAILED:
    case complianceActionTypes.COMPLETION_CANCEL_REQUEST_FAILED:
    case complianceActionTypes.COMPLETION_REJECTION_REQUEST_FAILED:
    case complianceActionTypes.COMPLETION_APPROVAL_FAILED:
    case complianceActionTypes.COMPLETION_SUBMIT_FAILED:
    case complianceActionTypes.UPDATE_COMPLIANCE_APPROVERS_FAILED:
    case complianceActionTypes.COMPLETION_UNASSIGN_FAILED:
    case complianceActionTypes.COMPLETION_START_REQUEST_FAILED:
    case complianceActionTypes.COMPLETION_COMPLETE_REQUEST_FAILED:
    case complianceActionTypes.COMPLETION_REJECTION_REQUEST_FAILED:
    case actionTypes.DELETE_DOCUMENT_FAILED: {
      const { resourceType, id, error } = action
      return {
        ...state,
        [resourceType]: {
          ...state[resourceType],
          [`${id}/status`]: {
            loading: false,
            error,
          },
        },
      }
    }
    case complianceActionTypes.COMPLIANCE_COMPLETED: {
      const { resourceType, id, acl } = action
      const resourceState = state[resourceType] || {}
      return {
        ...state,
        [resourceType]: {
          ...resourceState,
          ...removeResourceFromQueryKey(
            resourceState,
            [OVERVIEW, MY, UNASSIGNED, AWAITING_APPROVAL],
            id,
          ),
          [`${id}/acls`]: acl,
        },
      }
    }
    case actionTypes.DOCUMENT_DELETED: {
      const { resourceType, id } = action
      const resourceState = state[resourceType] || {}
      return {
        ...state,
        [resourceType]: {
          ...omit(resourceState, [id]),
          ...removeResourceFromQueryKey(
            resourceState,
            [DRAFTS, SCHEDULED, FEED, SENT, ALL],
            id,
          ),
          [`${id}/status`]: {
            loading: false,
          },
        },
      }
    }

    case actionTypes.UPDATE_DOCUMENT_FIELDS_REQUESTED: {
      const { resourceType, id, fields } = action
      const resourceTypeState = state[resourceType] || {}
      const document = resourceTypeState[id]

      const { document: fieldsDoc, ...restFields } = fields

      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [id]: {
            ...document,
            ...fieldsDoc,
            ...restFields,
          },
        },
      }
    }

    case actionTypes.PIN_DOCUMENT: {
      const { resourceType, id, pinnedOn, pinnedUntil } = action
      const resourceTypeState = state[resourceType] || {}
      const document = resourceTypeState[id]
      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [id]: {
            ...document,
            pin: {
              ...document?.pin,
              pinnedOn,
              pinnedUntil,
            },
          },
        },
      }
    }

    case actionTypes.UNPIN_DOCUMENT: {
      const { resourceType, id } = action
      const resourceTypeState = state[resourceType] || {}
      const document = resourceTypeState[id]
      return {
        ...state,
        [resourceType]: {
          ...resourceTypeState,
          [id]: {
            ...document,
            pin: {},
          },
        },
      }
    }

    case actionTypes.DOCUMENT_COPIED: {
      const { document } = action
      const { item, acl } = document
      const { _id, type } = item
      return {
        ...state,
        lastCreatedType: type,
        [type]: {
          ...state[type],
          [_id]: {
            ...item,
          },
          [`${_id}/acls`]: acl,
          lastCreatedDocumentId: _id,
        },
      }
    }

    case complianceActionTypes.COMPLIANCE_CANCELLED: {
      const { resourceType, id, item, acl } = action
      const resourceState = state[resourceType] || {}
      return {
        ...state,
        [resourceType]: {
          ...resourceState,
          ...removeResourceFromQueryKey(
            resourceState,
            [SCHEDULED, OVERVIEW, FEED, SENT, PUBLISHED],
            id,
            [ARCHIVED],
          ),
          [id]: item,
          [`${id}/acls`]: acl,
          [`${id}/status`]: {
            loading: false,
          },
        },
      }
    }

    default:
      return state
  }
}

const getQueryKey = query => {
  const { facet } = query
  const queryKey = createQueryKey(query)
  return `query/${facet}/${queryKey}`
}

const selectDocuments = (state, query) => {
  const queryKey = getQueryKey(query)
  return getProp(state, ['documents', query.resourceType, queryKey])
}

const selectDocumentsCount = (state, query) => {
  const queryKey = `${getQueryKey(query)}/count`
  return getProp(state, ['documents', query.resourceType, queryKey])
}

const selectDocument = (state, resourceType, id) =>
  state?.documents?.[resourceType]?.[id]

const selectDocumentFacets = (state, resourceType, groupId) =>
  getProp(state, `documents.${resourceType}.facets/${groupId}/all`)

const selectDocumentFacet = (state, resourceType, groupId, key) =>
  getProp(state, `documents.${resourceType}.facets/${groupId}.${key}`)

const selectDocumentAcls = (state, resourceType, id) =>
  getProp(state, `documents.${resourceType}.${id}/acls`)

const selectDocumentsAreLoading = (state, query) => {
  const { resourceType } = query
  const queryKey = getQueryKey(query)
  return (
    isBootstrapping(state) ||
    getProp(state, `documents.${resourceType}.${queryKey}/status.loading`)
  )
}

const selectDocumentIsLoading = (state, id, resourceType) =>
  getProp(state, `documents.${resourceType}.${id}/status.loading`)

const selectDocumentFacetsAreLoading = (state, resourceType, groupId) => {
  return getProp(
    state,
    `documents.${resourceType}.facets/${groupId}/status.loading`,
  )
}

function selectLastCreatedDocumentId(state, resourceType) {
  return getProp(state, `documents.${resourceType}.lastCreatedDocumentId`)
}

function selectLastCreatedType(state) {
  return getProp(state, `documents.lastCreatedType`)
}

function selectCoverImage(state, resourceType, id) {
  return getProp(state, `documents.${resourceType}.${id}.coverImage`)
}

function* fetchDocumentsSaga({ query }) {
  try {
    // TODO: eventually we'll import and use application-api generated api client
    // rather than our own handwritten ones
    const { resourceType, dueDate } = query
    const isCalendar = !!dueDate
    let documentsApi
    if (isCalendar) {
      documentsApi = api.fetchCalendarDocuments
    } else {
      documentsApi = api.fetchDocuments
    }
    const groups = yield select(selectAll)
    const { total = 0, items = [] } = yield call(documentsApi, query)
    const {
      documentItems,
      complianceItems,
      distributionItems,
      userIds,
      files,
      topics,
      tags,
    } = mapItemsToProperties(items)
    if (resourceType === STORIES) {
      yield put(
        queryResourceStats(
          documentItems.filter(i => i.type === STORIES).map(i => i._id),
          STORIES,
        ),
      )
    }
    yield put(fetchMissingUsers([...new Set(userIds)]))
    yield put(fetchMissingFiles(files))
    yield put({
      type: checkinsActionTypes.CHECKIN_QUERY_REQUESTED,
      topics,
    })
    yield put({
      type: actionTypes.DOCUMENTS_LOADED,
      query,
      total,
      items: documentItems,
    })
    yield put({
      type: distributionActionTypes.FACETS_DISTRIBUTION_LOADED,
      items: distributionItems,
    })
    yield put({
      type: complianceActionTypes.FACETS_COMPLIANCE_LOADED,
      items: complianceItems,
      groups,
    })
    yield put({
      type: tagActionTypes.FACETS_RESOURCE_TAGS_LOADED,
      tags,
    })
    yield put({
      type: resourceActionTypes.RESOURCES_LOADED,
      items,
      total,
      query,
    })
  } catch (e) {
    yield put({
      type: actionTypes.DOCUMENTS_REQUEST_FAILED,
      query,
      error: e.message,
    })
  }
}

function* createDocumentSaga({ resourceType, documentBody }) {
  try {
    let body = documentBody
    if (resourceType === EVENTS) {
      body = {
        ...documentBody,
        startDate:
          body.startDate ||
          moment()
            .startOf('day')
            .add(1, 'm'),
        endDate: body.endDate || moment().endOf('day'),
      }
    }
    const { item = {} } = yield call(api.createDocument, resourceType, body)
    yield put({
      type: actionTypes.DOCUMENT_CREATED,
      resourceType,
      item,
    })
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_DOCUMENT_FAILED,
      error,
    })
  }
}

function* fetchDocumentSaga({ resourceType, id }) {
  try {
    const { item, compliance, distribution, tags, acl } = yield call(
      api.fetchDocument,
      resourceType,
      id,
    )
    let mergedCompliance = compliance
    if (!!item?.compliance)
      mergedCompliance = { ...compliance, ...item.compliance }
    const { fields, responses, template, createdBy } = item
    let submissionCompletionId
    if (!!template) {
      submissionCompletionId = compliance.items[0]._id
    }

    yield put({
      type: actionTypes.DOCUMENT_LOADED,
      resourceType,
      item: normaliseForm({
        ...item,
        pin: distribution?.pin,
        submissionCompletionId,
      }), // TODO: remove once BE correctly returns pin object in document
      acls: acl,
    })

    const groups = yield select(selectAll)
    const breakdown = rollupDistribution(distribution.items, groups)
    yield put({
      type: distributionActionTypes.DISTRIBUTION_LOADED,
      resourceId: id,
      resourceType,
      distribution,
      breakdown,
    })

    yield put({
      type: complianceActionTypes.LOAD_COMPLETIONS_REQUESTED,
      id,
      resourceType,
      compliance: mergedCompliance,
      groups,
      fields,
      responses,
      acl,
    })

    yield put({
      type: tagActionTypes.RESOURCE_TAGS_LOADED,
      resourceId: id,
      resourceType,
      tags: tags?.items,
    })

    yield put(fetchMissingUsers([createdBy]))

    if (resourceType === TASKS) {
      yield put(requestTaskNotes(id))
    }
  } catch (error) {
    yield put({
      type: actionTypes.DOCUMENT_REQUEST_FAILED,
      id,
      resourceType,
      error,
    })
  }
}

function* fetchDocumentFacetsSaga({ resourceType, groupId }) {
  try {
    const facets = yield call(api.fetchFacetsMetadata, resourceType, groupId)
    yield put({
      type: actionTypes.DOCUMENT_FACETS_LOADED,
      resourceType,
      facets,
      groupId,
    })
    yield put(requestDocumentFacetsCounts(resourceType, groupId))
  } catch (error) {
    yield put({
      type: actionTypes.DOCUMENT_FACETS_REQUEST_FAILED,
      resourceType,
      groupId,
      error,
    })
  }
}

function* deleteDocumentSaga({ resourceType, id }) {
  try {
    const groupId = yield select(selectCurrentGroupId)
    yield call(api.deleteDocument, resourceType, id)
    yield put({
      type: actionTypes.DOCUMENT_DELETED,
      resourceType,
      id,
    })
    yield put({
      type: actionTypes.DOCUMENT_FACETS_REQUESTED,
      resourceType,
      groupId,
    })
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_DOCUMENT_FAILED,
      resourceType,
      id,
      error,
    })
  }
}

function* fetchDocumentFacetsCountsSaga(action) {
  const { groupId, resourceType } = action
  try {
    const counts = yield call(api.fetchDocumentFacetsCounts, resourceType)
    yield put({
      type: actionTypes.DOCUMENT_FACETS_COUNTS_LOADED,
      resourceType,
      groupId,
      counts,
    })
  } catch (error) {
    yield put({
      type: actionTypes.DOCUMENT_FACETS_COUNTS_REQUEST_FAILED,
      resourceType,
      groupId,
      error,
    })
  }
}

function* forwardDocumentSaga({ resourceType, id, audience }) {
  try {
    const { item, compliance, acl } = yield call(
      api.forwardDocument,
      resourceType,
      id,
      audience,
    )
    yield put({
      type: actionTypes.DOCUMENT_FORWARDED,
      resourceType,
      id,
    })
    const groups = yield select(selectAll)
    const { fields, responses } = item
    yield put({
      type: complianceActionTypes.LOAD_COMPLETIONS_REQUESTED,
      id,
      acl,
      resourceType,
      compliance,
      groups,
      fields,
      responses,
    })
  } catch (error) {
    yield put({
      type: actionTypes.DOCUMENT_FORWARD_REQUEST_FAILED,
      resourceType,
      id,
      error,
    })
  }
}

function* updateDocumentFieldsSaga({ resourceType, id, fields }) {
  try {
    yield call(api.updateDocumentFields, resourceType, id, fields)
  } catch (error) {
    yield put({
      type: actionTypes.DOCUMENT_FIELDS_UPDATE_FAILED,
      resourceType,
      id,
      fields,
      error,
    })
  }
}

function* pinDocumentSaga({ resourceType, id, pinnedOn, pinnedUntil }) {
  try {
    yield call(api.pinDocument, resourceType, id, pinnedOn, pinnedUntil)
  } catch (error) {
    yield put({
      type: actionTypes.PIN_DOCUMENT_FAILED,
      pinnedOn,
      pinnedUntil,
      id,
      resourceType,
      error,
    })
  }
}

function* unpinDocumentSaga({ resourceType, id }) {
  try {
    yield call(api.unpinDocument, resourceType, id)
  } catch (error) {
    yield put({
      type: actionTypes.UNPIN_DOCUMENT_FAILED,
      id,
      resourceType,
      error,
    })
  }
}

function* submitSketchSaga({ resourceType, id }) {
  try {
    yield call(api.submitSketchDocument, resourceType, id)
    yield put({
      type: actionTypes.DOCUMENT_SKETCH_SUBMITTED,
      resourceType,
      id,
    })
  } catch (error) {
    yield put({
      type: actionTypes.DOCUMENT_SKETCH_SUBMIT_REQUEST_FAILED,
      id,
      resourceType,
      error,
    })
  }
}

function* copyDocumentSaga(action) {
  const { resourceType, id, namePrefix, callback } = action
  try {
    const document = yield select(selectDocument, resourceType, id)
    if (document) {
      const name = namePrefix ? `${namePrefix} ${document.name}` : document.name
      const documentBody = { name }
      if (resourceType === EVENTS) {
        documentBody.startDate = moment()
          .startOf('day')
          .add(1, 'm')
        documentBody.endDate = moment().endOf('day')
      }
      const copiedDocument = yield call(
        api.copyDocument,
        resourceType,
        id,
        documentBody,
      )
      yield put({
        type: actionTypes.DOCUMENT_COPIED,
        document: copiedDocument,
      })
      if (!!callback) {
        yield call(callback, { resourceType, id: copiedDocument?.item?._id })
      }
    }
  } catch (error) {
    yield put({
      type: actionTypes.DOCUMENT_COPY_FAILED,
      resourceType,
      id,
      error,
    })
  }
}

function* saga() {
  yield takeEvery(actionTypes.DOCUMENTS_REQUESTED, fetchDocumentsSaga)
  yield takeEvery(actionTypes.CREATE_DOCUMENT_REQUESTED, createDocumentSaga)
  yield takeEvery(actionTypes.DOCUMENT_REQUESTED, fetchDocumentSaga)
  yield takeEvery(
    actionTypes.DOCUMENT_FACETS_REQUESTED,
    fetchDocumentFacetsSaga,
  )
  yield takeEvery(actionTypes.DELETE_DOCUMENT_REQUESTED, deleteDocumentSaga)
  yield takeEvery(
    actionTypes.UPDATE_DOCUMENT_FIELDS_REQUESTED,
    updateDocumentFieldsSaga,
  )
  yield takeEvery(actionTypes.PIN_DOCUMENT, pinDocumentSaga)
  yield takeEvery(actionTypes.UNPIN_DOCUMENT, unpinDocumentSaga)
  yield takeEvery(
    actionTypes.DOCUMENT_FACETS_COUNTS_REQUESTED,
    fetchDocumentFacetsCountsSaga,
  )
  yield takeEvery(actionTypes.DOCUMENT_COPY_REQUESTED, copyDocumentSaga)
  yield takeEvery(actionTypes.DOCUMENT_FORWARD_REQUESTED, forwardDocumentSaga)
  yield takeEvery(
    actionTypes.DOCUMENT_SKETCH_SUBMIT_REQUESTED,
    submitSketchSaga,
  )
}

export {
  actionTypes,
  createDocument,
  createDocumentSaga,
  requestDocuments,
  requestDocument,
  requestDocumentFacets,
  requestDocumentFacetsCounts,
  viewLastCreatedDocument,
  copyDocument,
  updateDocumentFields,
  reducer,
  selectDocuments,
  selectDocumentsCount,
  selectDocument,
  selectDocumentFacets,
  selectDocumentFacet,
  selectDocumentsAreLoading,
  selectDocumentIsLoading,
  selectDocumentFacetsAreLoading,
  selectDocumentAcls,
  selectLastCreatedDocumentId,
  saga,
  fetchDocumentsSaga,
  fetchDocumentSaga,
  fetchDocumentFacetsSaga,
  deleteDocument,
  deleteDocumentSaga,
  updateDocumentFieldsSaga,
  pinDocument,
  unpinDocument,
  pinDocumentSaga,
  unpinDocumentSaga,
  copyDocumentSaga,
  selectCoverImage,
  fetchDocumentFacetsCountsSaga,
  selectLastCreatedType,
  forwardDocument,
  submitSketchDocument,
  forwardDocumentSaga,
  submitSketchSaga,
}
