import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { get as getProp, escapeRegExp, keyBy, mapKeys } from 'lodash'
import { actionTypes as distributionActionTypes } from './distribution'
import {
  generateUrn,
  RESOURCE_TYPES,
  FIELD_TYPES,
  decodeUrn,
  RESOURCE_STATUSES,
} from '@concrete/resource'
import complianceApi from './api/compliance'
import { queryResource, selectCheckin } from './checkins'
import { queryResourceStats, selectStats } from './comments'
import { selectConnectedGroups, requestConnectedUsers } from './connect'
import { selectIsEnabled } from './features'
import { selectAll, selectGroupName } from './groups'
import { selectCurrentUser, selectCurrentGroupId } from './session'
import { selectUser, fetchMissingUsers } from './users'
import { rollupCompletions } from './helpers/compliance'
import filtersHelper from './helpers/filters'
import { normaliseId } from './utils'
import {
  actionTypes as documentActionTypes,
  requestDocument,
} from './documents'
import {
  hasNewApprovals,
  hasNewAttachments,
  hasNewComments,
} from './helpers/tasks'
import { normaliseForm, getUploads, maintainIndexes } from './helpers/forms'
import { downloadFiles, queueFilesDownload } from './files'
import { actionTypes as fieldActionTypes } from './forms'
import api from './api/documents'

const { TASKS, FORMS, TEMPLATES } = RESOURCE_TYPES
const { MULTIPLE_CHOICE_SINGLE } = FIELD_TYPES
const { CANCELLED, DONE, IN_PROGRESS, FORWARDED, REVIEW } = RESOURCE_STATUSES

const normaliseApprovers = (approvers = [], currentUserId) =>
  approvers.reduce(
    (acc, { user, createdBy }) => {
      acc.ids.push(user)
      if (createdBy !== currentUserId) acc.readOnly.push(user)
      return acc
    },
    { ids: [], readOnly: [] },
  )

const actionTypes = {
  COMPLETIONS_REQUEST_FAILED: 'COMPLETIONS_REQUEST_FAILED',
  COMPLETIONS_LOADED: 'COMPLETIONS_LOADED',
  COMPLETIONS_ROLLUP_COMPUTED: 'COMPLETIONS_ROLLUP_COMPUTED',
  COMPLETIONS_ROLLUP_COMPUTE_FAILED: 'COMPLETIONS_ROLLUP_COMPUTE_FAILED',
  LOAD_COMPLETIONS_REQUESTED: 'LOAD_COMPLETIONS_REQUESTED',
  COMPLETION_COMPLETE_REQUESTED: 'COMPLETION_COMPLETE_REQUESTED',
  COMPLETION_COMPLETE_REQUEST_FAILED: 'COMPLETION_COMPLETE_REQUEST_FAILED',
  COMPLETION_COMPLETED: 'COMPLETION_COMPLETED',
  COMPLIANCE_COMPLETE_REQUESTED: 'COMPLIANCE_COMPLETE_REQUESTED',
  COMPLIANCE_COMPLETE_REQUEST_FAILED: 'COMPLIANCE_COMPLETE_REQUEST_FAILED',
  COMPLIANCE_COMPLETED: 'COMPLIANCE_COMPLETED',
  COMPLIANCE_CLEANUP_REQUESTED: 'COMPLIANCE_CLEANUP_REQUESTED',
  COMPLIANCE_CLEANUP_REQUEST_FAILED: 'COMPLIANCE_CLEANUP_REQUEST_FAILED',
  COMPLIANCE_CLEANUP_COMPLETED: 'COMPLIANCE_CLEANUP_COMPLETED',
  COMPLETION_START_REQUESTED: 'COMPLETION_START_REQUESTED',
  COMPLETION_START_REQUEST_FAILED: 'COMPLETION_START_REQUEST_FAILED',
  COMPLETION_STARTED: 'COMPLETION_STARTED',
  COMPLETION_UNASSIGN_REQUESTED: 'COMPLETION_UNASSIGN_REQUESTED',
  COMPLETION_UNASSIGNED: 'COMPLETION_UNASSIGNED',
  COMPLETION_UNASSIGN_FAILED: 'COMPLETION_UNASSIGN_FAILED',
  UPDATE_COMPLIANCE_APPROVERS_REQUESTED:
    'UPDATE_COMPLIANCE_APPROVERS_REQUESTED',
  UPDATE_COMPLIANCE_APPROVERS_FAILED: 'UPDATE_COMPLIANCE_APPROVERS_FAILED',
  COMPLIANCE_APPROVERS_UPDATED: 'COMPLIANCE_APPROVERS_UPDATED',
  SUBMIT_COMPLETION_REQUESTED: 'SUBMIT_COMPLETION_REQUESTED',
  COMPLETION_SUBMITTED_FOR_REVIEW: 'COMPLETION_SUBMITTED_FOR_REVIEW',
  COMPLETION_SUBMIT_FAILED: 'COMPLETION_SUBMIT_FAILED',
  APPROVE_COMPLETION_REQUESTED: 'APPROVE_COMPLETION_REQUESTED',
  COMPLETION_APPROVED: 'COMPLETION_APPROVED',
  COMPLETION_APPROVAL_FAILED: 'COMPLETION_APPROVAL_FAILED',
  REJECT_COMPLETION_REQUESTED: 'REJECT_COMPLETION_REQUESTED',
  COMPLETION_REJECTED: 'COMPLETION_REJECTED',
  COMPLETION_REJECTION_REQUEST_FAILED: 'COMPLETION_REJECTION_REQUEST_FAILED',
  COMPLETION_CANCEL_REQUESTED: 'COMPLETION_CANCEL_REQUESTED',
  COMPLETION_CANCEL_REQUEST_FAILED: 'COMPLETION_CANCEL_REQUEST_FAILED',
  COMPLETION_CANCELLED: 'COMPLETION_CANCELLED',
  COMPLIANCE_CANCEL_REQUESTED: 'COMPLIANCE_CANCEL_REQUESTED',
  COMPLIANCE_CANCELLED: 'COMPLIANCE_CANCELLED',
  COMPLIANCE_CANCEL_REQUEST_FAILED: 'COMPLIANCE_CANCEL_REQUEST_FAILED',
  FACETS_COMPLIANCE_LOADED: 'FACETS_COMPLIANCE_LOADED',
  UPDATE_COMPLETION_APPROVERS_REQUESTED:
    'UPDATE_COMPLETION_APPROVERS_REQUESTED',
  UPDATE_COMPLETION_APPROVERS_FAILED: 'UPDATE_COMPLETION_APPROVERS_FAILED',
  COMPLETION_APPROVERS_UPDATED: 'COMPLETION_APPROVERS_UPDATED',
  ADD_COMPLETION_ATTACHMENTS_REQUESTED: 'ADD_COMPLETION_ATTACHMENTS_REQUESTED',
  ADD_COMPLETION_ATTACHMENTS_FAILED: 'ADD_COMPLETION_ATTACHMENTS_FAILED',
  COMPLETION_ATTACHMENTS_UPDATED: 'COMPLETION_ATTACHMENTS_UPDATED',
  REMOVE_COMPLETION_ATTACHMENT_REQUESTED:
    'REMOVE_COMPLETION_ATTACHMENT_REQUESTED',
  REMOVE_COMPLETION_ATTACHMENT_FAILED: 'REMOVE_COMPLETION_ATTACHMENT_FAILED',
  RESOURCE_BREAKDOWN_UPLOADS_REQUESTED: 'RESOURCE_BREAKDOWN_UPLOADS_REQUESTED',
  RESOURCE_BREAKDOWN_UPLOADS_REQUEST_FAILED:
    'RESOURCE_BREAKDOWN_UPLOADS_REQUEST_FAILED',
  UPDATE_COMPLIANCE_FIELD_REQUESTED: 'UPDATE_COMPLIANCE_FIELD_REQUESTED',
  COMPLIANCE_FIELD_UPDATE_FAILED: 'COMPLIANCE_FIELD_UPDATE_FAILED',
}

const completeCompletion = (resourceType, id, completionId, callback) => ({
  type: actionTypes.COMPLETION_COMPLETE_REQUESTED,
  resourceType,
  id,
  completionId,
  callback,
})

const cleanupCompliance = (resourceType, id) => ({
  type: actionTypes.COMPLIANCE_CLEANUP_REQUESTED,
  resourceType,
  id,
})

const completeCompliance = (resourceType, id) => ({
  type: actionTypes.COMPLIANCE_COMPLETE_REQUESTED,
  resourceType,
  id,
})

const cancelCompletion = (resourceType, id, completionId, reason) => ({
  type: actionTypes.COMPLETION_CANCEL_REQUESTED,
  resourceType,
  id,
  completionId,
  reason,
})

const cancelCompliance = (resourceType, id, reason) => ({
  type: actionTypes.COMPLIANCE_CANCEL_REQUESTED,
  resourceType,
  id,
  reason,
})

const startCompletion = (resourceType, id, completionId) => ({
  type: actionTypes.COMPLETION_START_REQUESTED,
  resourceType,
  id,
  completionId,
})

const unassignCompletion = (resourceType, id, completionId) => ({
  type: actionTypes.COMPLETION_UNASSIGN_REQUESTED,
  resourceType,
  id,
  completionId,
})

const updateApprovers = (resourceType, id, userIds, approvalType) => ({
  type: actionTypes.UPDATE_COMPLIANCE_APPROVERS_REQUESTED,
  userIds,
  approvalType,
  resourceType,
  id,
})

const updateCompletionApprovers = (
  resourceType,
  id,
  completionId,
  userIds,
  approvalType,
) => ({
  type: actionTypes.UPDATE_COMPLETION_APPROVERS_REQUESTED,
  resourceType,
  id,
  completionId,
  userIds,
  approvalType,
})

const addCompletionAttachments = (
  resourceType,
  id,
  completionId,
  attachments,
) => ({
  type: actionTypes.ADD_COMPLETION_ATTACHMENTS_REQUESTED,
  resourceType,
  id,
  completionId,
  attachments,
})

const removeCompletionAttachment = (
  resourceType,
  id,
  completionId,
  attachmentId,
) => ({
  type: actionTypes.REMOVE_COMPLETION_ATTACHMENT_REQUESTED,
  resourceType,
  id,
  completionId,
  attachmentId,
})

const submitCompletion = (resourceType, id, completionId, callback) => ({
  type: actionTypes.SUBMIT_COMPLETION_REQUESTED,
  resourceType,
  id,
  completionId,
  callback,
})

const approveCompletion = (resourceType, id, completionId) => ({
  type: actionTypes.APPROVE_COMPLETION_REQUESTED,
  resourceType,
  id,
  completionId,
})

const rejectCompletion = (resourceType, id, completionId, reason) => ({
  type: actionTypes.REJECT_COMPLETION_REQUESTED,
  resourceType,
  id,
  completionId,
  reason,
})

const requestBreakdownUploads = (
  resourceType,
  id,
  archiveName,
  completionIds,
) => ({
  type: actionTypes.RESOURCE_BREAKDOWN_UPLOADS_REQUESTED,
  resourceType,
  id,
  archiveName,
  completionIds,
})

const updateComplianceField = (resourceType, id, field, value) => ({
  type: actionTypes.UPDATE_COMPLIANCE_FIELD_REQUESTED,
  resourceType,
  id,
  field,
  value,
})

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.COMPLIANCE_COMPLETED: {
      const { resourceType, id } = action
      const urn = generateUrn(resourceType, id)
      return {
        ...state,
        [`${urn}/status`]: DONE,
      }
    }
    case actionTypes.COMPLETION_COMPLETE_REQUEST_FAILED:
    case actionTypes.COMPLETION_SUBMIT_FAILED: {
      const { completionId } = action
      const { fields } = state[completionId]
      const { isValid, fields: normalisedFields } = normaliseForm(
        { fields },
        completionId,
        true,
      )

      return {
        ...state,
        [completionId]: {
          ...state[completionId],
          fields: normalisedFields,
          isValid,
        },
      }
    }
    case actionTypes.COMPLIANCE_CLEANUP_COMPLETED: {
      const { resourceType, id, items, status } = action
      const urn = generateUrn(resourceType, id)
      const { completionAcls, completionsByKey } = items.reduce(
        (acc, item) => {
          const { _id, acl } = item
          acc.completionsByKey[`${_id}`] = item
          acc.completionAcls[`${_id}/acls`] = acl
          return acc
        },
        { completionAcls: {}, completionsByKey: {} },
      )
      return {
        ...state,
        ...completionsByKey,
        ...completionAcls,
        [`${urn}/status`]: status,
        [`${urn}/incompleteCompletionAssignees`]: [],
      }
    }
    case actionTypes.COMPLIANCE_CANCELLED: {
      const { completions, status, resourceType, id, completionAcls } = action
      const urn = generateUrn(resourceType, id)
      return {
        ...state,
        ...completions,
        ...completionAcls,
        [`${urn}/status`]: status,
      }
    }
    case actionTypes.COMPLETION_APPROVERS_UPDATED:
    case actionTypes.COMPLETION_ATTACHMENTS_UPDATED:
    case actionTypes.COMPLETION_CANCELLED:
    case actionTypes.COMPLETION_REJECTED:
    case actionTypes.COMPLETION_APPROVED:
    case actionTypes.COMPLETION_SUBMITTED_FOR_REVIEW:
    case actionTypes.COMPLETION_COMPLETED:
    case actionTypes.COMPLETION_UNASSIGNED:
    case actionTypes.COMPLETION_STARTED: {
      const {
        completion,
        status,
        resourceType,
        id,
        completionIds,
        fields,
        responses,
        currentUserId,
      } = action
      const { fields: formFields, isValid } = normaliseForm(
        { fields, responses },
        completion._id,
      )
      const urn = generateUrn(resourceType, id)
      const approvers = normaliseApprovers(completion.approvers, currentUserId)
      return {
        ...state,
        [completion._id]: {
          ...completion,
          fields: formFields,
          isValid,
          uploads: getUploads({ fields, responses }, completion._id),
        },
        [`${completion._id}/acls`]: completion.acl,
        [`${completion._id}/approvers`]: approvers,
        [`${urn}/status`]: status,
        [`${urn}`]: completionIds,
      }
    }

    case actionTypes.COMPLETIONS_LOADED: {
      const {
        id,
        resourceType,
        completions,
        breakdown,
        status,
        cancellationReason,
        approvalType,
        approvers,
        fields,
        responses,
        currentUserId,
        workflow,
      } = action
      const urn = generateUrn(resourceType, id)
      return {
        ...state,
        ...completions.reduce(
          (acc, completion) => {
            const { _id, compliantUrn, assignedTo, status } = completion
            const { fields: formFields, isValid } = normaliseForm(
              { fields, responses },
              _id,
            )
            ![DONE, CANCELLED].includes(status) &&
              assignedTo &&
              acc[`${urn}/incompleteCompletionAssignees`].push(assignedTo)
            acc[urn].push(_id)
            acc[_id] = {
              ...completion,
              ...(compliantUrn &&
                resourceType === FORMS && {
                  formIdToSubmit: decodeUrn(compliantUrn).id,
                }),
              fields: formFields,
              isValid,
              uploads: getUploads({ fields, responses }, _id),
            }
            acc[`${_id}/acls`] = completion.acl
            const approvers = normaliseApprovers(
              completion.approvers,
              currentUserId,
            )
            acc[`${_id}/approvers`] = approvers
            return acc
          },
          { [`${urn}/incompleteCompletionAssignees`]: [], [urn]: [] },
        ),
        [`${urn}/breakdown`]: breakdown,
        [`${urn}/status`]: status,
        [`${urn}/approvalType`]: approvalType,
        [`${urn}/approvers`]: approvers,
        [`${urn}/cancellationReason`]: cancellationReason,
        [`${urn}/workflow`]: workflow,
      }
    }

    case actionTypes.COMPLETIONS_ROLLUP_COMPUTED: {
      const { id, resourceType, breakdown } = action
      const urn = generateUrn(resourceType, id)
      return {
        ...state,
        [`${urn}/breakdown`]: breakdown,
      }
    }

    case actionTypes.FACETS_COMPLIANCE_LOADED: {
      const { items = [], groups } = action
      const { compliance, completions } = items.reduce(
        (acc, item) => {
          const {
            items: completions,
            status,
            startedAt,
            completedAt,
            progress,
            latest,
            earliestRequiringAction,
          } = item || {}

          const { resourceType: type, id } = decodeUrn(item.urn)
          const resourceType = type === TEMPLATES ? FORMS : type // TODO: need to sort this out
          const urn = generateUrn(resourceType, id)

          const filteredCompletions = completions?.filter(
            c => c?.status !== FORWARDED,
          )
          const keyedCompletions = filteredCompletions?.length
            ? keyBy(filteredCompletions, '_id')
            : []
          acc.compliance[`${urn}/latestSubmission`] =
            resourceType === FORMS ? latest : undefined
          acc.compliance[`${urn}/earliestReviewSubmission`] =
            resourceType === FORMS ? earliestRequiringAction : undefined
          acc.compliance[`${urn}/status`] = status
          acc.compliance[`${urn}/startedAt`] = startedAt
          acc.compliance[`${urn}/completedAt`] = completedAt
          acc.compliance[`${urn}/progress`] = progress
          acc.compliance[`${urn}/breakdown`] = rollupCompletions(
            filteredCompletions,
            groups,
          )
          filteredCompletions?.length &&
            (acc.completions[urn] = (filteredCompletions || []).map(c => c._id))
          filteredCompletions?.length &&
            Object.keys(keyedCompletions).forEach(
              id => (acc.completions[id] = keyedCompletions[id]),
            )
          return acc
        },
        {
          compliance: {},
          completions: {},
        },
      )

      return {
        ...state,
        ...compliance,
        ...completions,
      }
    }

    case actionTypes.COMPLIANCE_APPROVERS_UPDATED: {
      const { id, approvers, approvalType, resourceType } = action
      const urn = generateUrn(resourceType, id)
      return {
        ...state,
        [`${urn}/approvalType`]: approvalType,
        [`${urn}/approvers`]: approvers,
      }
    }

    case fieldActionTypes.ANSWER_UPDATED: {
      const { completionId, fieldId, itemId, value } = action
      const form = state[completionId]
      const { fieldValid, ...field } =
        form?.fields?.find(f => f.id === fieldId) || {}
      if (!form || !form.fields || !field || !field.items) return state
      let fields
      const item = field.items.find(item => item.id === itemId)

      if (field.type === MULTIPLE_CHOICE_SINGLE) {
        const selectedItem = field.items.find(
          item => !!item.value && item.id !== itemId,
        )
        if (!!selectedItem) {
          const updatedSelected = {
            ...selectedItem,
            value: false,
          }
          fields = maintainIndexes(form, field, updatedSelected)
        }
      }

      const updatedItem = {
        ...item,
        value,
      }
      fields = maintainIndexes(form, field, updatedItem)

      return {
        ...state,
        [completionId]: {
          ...form,
          fields,
        },
      }
    }

    case actionTypes.UPDATE_COMPLIANCE_FIELD_REQUESTED: {
      const { resourceType, id, field, value } = action
      const urn = generateUrn(resourceType, id)
      return {
        ...state,
        [`${urn}/${field}`]: value,
      }
    }

    default:
      return state
  }
}

// selectors

const selectComplianceStatus = (state, resourceType, resourceId) => {
  const urn = generateUrn(resourceType, resourceId)
  return getProp(state, `completions.${urn}/status`)
}

const selectComplianceStartedAt = (state, resourceType, id) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/startedAt`)
}

const selectComplianceCompletedAt = (state, resourceType, id) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/completedAt`)
}

const selectComplianceApprovers = (state, resourceType, id) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/approvers.ids`)
}

const selectComplianceCancellationReason = (state, resourceType, id) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/cancellationReason`)
}

const selectComplianceLatestSubmission = (state, resourceType, id) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/latestSubmission`)
}

const selectComplianceProgress = (state, resourceType, id) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/progress`)
}

const selectComplianceEarliestSubmissionInReview = (
  state,
  resourceType,
  id,
) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/earliestReviewSubmission`)
}

const selectComplianceWorkflow = (state, resourceType, id) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/workflow`)
}

const selectIncompleteComplianceAssignees = (state, resourceType, id) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/incompleteCompletionAssignees`)
}

const selectComplianceReadOnlyApprovers = (state, resourceType, id) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/approvers.readOnly`)
}

const selectComplianceApprovalType = (state, resourceType, id) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/approvalType`)
}

const selectCompletions = (state, resourceType, id) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}`)
}

const selectRelevantCompletion = (state, resourceType, id) => {
  const completionIds = selectCompletions(state, resourceType, id)
  const completions = completionIds?.map(i => selectCompletion(state, i))
  const currentGroupId = selectCurrentGroupId(state)
  const connectedGroups = selectConnectedGroups(state) || []
  const currentGroups = [currentGroupId, ...connectedGroups]
  const groupCompletionsCount = currentGroups.reduce((count, groupId) => {
    return (
      count +
        selectCompletionsByGroup(state, id, resourceType, groupId)?.length || 0
    )
  }, 0)
  return (
    completions?.find(c => {
      const { assignedTo, group } = c
      if (groupCompletionsCount === 1) return c
      if (groupCompletionsCount > 1) return false
      if (currentGroups.includes(group) && !assignedTo) return c
    }) || {}
  )
}

const selectCompletionsByGroup = (state, id, resourceType, groupId) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/breakdown.${groupId}.completions`)
}

const selectCompletion = (state, id) => {
  return getProp(state, `completions.${id}`)
}

const selectCompletionApprovers = (state, id) => {
  return getProp(state, `completions.${id}/approvers.ids`)
}

const selectCompletionReadOnlyApprovers = (state, id) => {
  return getProp(state, `completions.${id}/approvers.readOnly`)
}

const selectCompletionUploads = (state, id) => {
  return getProp(state, `completions.${id}.uploads`)
}

const selectAllBreakdownUploads = (state, resourceType, id) => {
  const completionIds = selectCompletions(state, resourceType, id) || []
  return completionIds.reduce((acc, i) => {
    const uploads = selectCompletionUploads(state, i) || []
    acc.push(...uploads)
    return acc
  }, [])
}

const selectComplianceBreakdown = (state, id, resourceType) => {
  if (!resourceType || !id) return null
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/breakdown`)
}

const selectComplianceBreakdownFacets = (state, id, resourceType, groupId) => {
  const urn = generateUrn(resourceType, id)
  return getProp(state, `completions.${urn}/breakdown.${groupId}.facets`)
}

const selectFormValidation = (state, id) =>
  getProp(state, `completions.${id}.isValid`)

const matchSearchStatus = ({ completion, search: { status } }) =>
  !status?.length || status.includes(completion.status)

const matchSearchFilter = ({
  state,
  completion,
  search: { filter },
  resourceType,
}) => {
  // at the moment only tasks support filters
  if (
    resourceType !== TASKS ||
    !filter ||
    filter === 'showAll' ||
    !filtersHelper?.[resourceType]?.[filter]
  ) {
    return true
  }
  const checkin = selectCheckin(state, resourceType, completion._id)
  const currentUser = selectCurrentUser(state)
  const engagementStats = selectStats(
    state,
    generateUrn(resourceType, completion._id),
  )
  return filtersHelper?.[resourceType]?.[filter]?.({
    completion,
    ...checkin,
    currentUser,
    engagementStats,
  })
}

const matchSearchQuery = ({ state, completion, search: { q } }) => {
  if (!q) return true
  // search for the query within completion assignee full name, if present
  // and the completion group name or one of its ancestors
  const groups = selectAll(state)
  const user = selectUser(state, completion.assignedTo)
  const userName = `${user?.firstName} ${user?.lastName}`
  const completionGroup = groups[completion.group]
  const ancestors = (completionGroup.ancestors || []).map(id => groups[id].name)
  const searchQueries = [...ancestors, completionGroup.name]
  if (user) searchQueries.push(userName)
  return new RegExp(escapeRegExp(q), 'ig').test(searchQueries.join(' '))
}

/**
 * Returns completions, filtered according to provided search criteria,
 * grouped by their parent groups.
 *
 * @param {Object} state Redux state
 * @param {String} id Id of resource completions belong to
 * @param {String} resourceType Type of resource completions belong to
 * @param {Object} search Filter criteria
 */
const selectFilteredCompletions = (state, id, resourceType, search = {}) => {
  const groups = selectAll(state)
  const completions = selectCompletions(state, resourceType, id) || []
  return completions.reduce((acc, id) => {
    const completion = selectCompletion(state, id)
    if (completion) {
      const params = { state, completion, search, id, resourceType }
      if (
        matchSearchStatus(params) &&
        matchSearchFilter(params) &&
        matchSearchQuery(params)
      ) {
        const completionGroup = groups[completion.group]
        acc[completionGroup.belongsTo] = acc[completionGroup.belongsTo] || []
        acc[completionGroup.belongsTo].push(id)
      }
    }
    return acc
  }, {})
}

// sagas
function* loadCompletionsSaga({
  id,
  resourceType,
  compliance,
  groups,
  fields,
  responses,
  acl,
}) {
  const currentUser = yield select(selectCurrentUser)
  const {
    items: completions,
    status,
    approvers,
    approvalType,
    cancellationReason,
    workflow,
  } = compliance
  const filteredCompletions = completions.filter(c => {
    switch (resourceType) {
      case FORMS:
        return (
          [DONE, CANCELLED, REVIEW].includes(c?.status) ||
          (c?.status === IN_PROGRESS && !c.compliantUrn)
        )
      default:
        return c?.status !== FORWARDED
    }
  })
  const userIds = [...approvers.map(o => o.user)]
  const connectionIds = new Set()
  filteredCompletions.forEach(c => {
    if (c.assignedTo && !c.connectionId) userIds.push(normaliseId(c.assignedTo))
    if (c.assignedTo && c.connectionId) connectionIds.add(c.connectionId)
  })
  yield put(fetchMissingUsers(userIds))
  if (connectionIds.size) {
    yield put(
      requestConnectedUsers({
        connectionIds: [...connectionIds],
        excludeInactive: false,
        includeDescendants: true,
      }),
    )
  }
  const breakdown = rollupCompletions(filteredCompletions, groups)
  const ids = filteredCompletions.map(c => c._id)
  // query checkins for the completions
  yield put(queryResource(ids, resourceType))
  // query comments for the completions
  yield put(queryResourceStats(ids, resourceType))
  yield put({
    type: actionTypes.COMPLETIONS_LOADED,
    id,
    resourceType,
    completions: filteredCompletions,
    breakdown,
    acl,
    status,
    approvalType,
    approvers: normaliseApprovers(approvers, currentUser._id),
    fields,
    responses,
    currentUserId: currentUser._id,
    cancellationReason,
    workflow,
  })
}

function* recomputeResourceBreakdownSaga({ id, resourceType }) {
  try {
    const groups = yield select(selectAll)
    const completionIds = yield select(selectCompletions, resourceType, id)
    const completions = yield all(
      completionIds.map(id => select(selectCompletion, id)),
    )
    const breakdown = rollupCompletions(completions, groups)
    yield put({
      type: actionTypes.COMPLETIONS_ROLLUP_COMPUTED,
      id,
      resourceType,
      breakdown,
    })
  } catch (error) {
    yield put({
      type: actionTypes.COMPLETIONS_ROLLUP_COMPUTE_FAILED,
      id,
      resourceType,
      error: error.message,
    })
  }
}

function* completeSaga({ resourceType, id, completionId, callback }) {
  try {
    const {
      compliance: { items, status },
      acl,
      item: { fields, responses },
    } = yield call(
      complianceApi.completeCompletion,
      resourceType,
      id,
      completionId,
    )
    const filteredCompletions = items.filter(i => i.status !== FORWARDED)
    yield put({
      type: actionTypes.COMPLETION_COMPLETED,
      resourceType,
      id,
      completion:
        resourceType === FORMS
          ? items[0]
          : items.find(i => i._id === completionId),
      status,
      acl,
      fields,
      responses,
      completionIds: filteredCompletions?.map(i => i._id),
    })
    if (!!callback) yield call(callback)
  } catch (error) {
    yield put({
      type: actionTypes.COMPLETION_COMPLETE_REQUEST_FAILED,
      resourceType,
      id,
      completionId,
      error: error.message,
    })
  }
}

function* completeComplianceSaga({ resourceType, id }) {
  try {
    const { acl } = yield call(
      complianceApi.completeCompliance,
      resourceType,
      id,
    )
    yield put({
      type: actionTypes.COMPLIANCE_COMPLETED,
      resourceType,
      id,
      acl,
    })
  } catch (error) {
    yield put({
      type: actionTypes.COMPLIANCE_COMPLETE_REQUEST_FAILED,
      resourceType,
      id,
      error: error.message,
    })
  }
}

function* rejectCompletionSaga({ resourceType, id, completionId, reason }) {
  try {
    const {
      compliance: { items, status },
      acl,
      item: { fields, responses },
    } = yield call(
      complianceApi.rejectCompletion,
      resourceType,
      id,
      completionId,
      reason,
    )
    yield put({
      type: actionTypes.COMPLETION_REJECTED,
      resourceType,
      id,
      completion: items.find(i => i._id === completionId),
      status,
      acl,
      fields,
      responses,
    })
  } catch (error) {
    yield put({
      type: actionTypes.COMPLETION_REJECTION_REQUEST_FAILED,
      resourceType,
      id,
      error: error.message,
    })
  }
}

function* submitCompletionSaga({ resourceType, id, completionId, callback }) {
  try {
    const {
      compliance: { items, status },
      acl,
      item: { fields, responses },
    } = yield call(
      complianceApi.submitCompletion,
      resourceType,
      id,
      completionId,
    )
    if (resourceType === FORMS) {
      yield put(requestDocument(resourceType, id))
    }
    const completion =
      resourceType === FORMS
        ? items.find(i => decodeUrn(i.compliantUrn).id === id)
        : items.find(i => i._id === completionId)
    yield put({
      type: actionTypes.COMPLETION_SUBMITTED_FOR_REVIEW,
      resourceType,
      id,
      completion,
      status,
      acl,
      fields,
      responses,
    })
    if (!!callback) yield call(callback)
  } catch (error) {
    yield put({
      type: actionTypes.COMPLETION_SUBMIT_FAILED,
      resourceType,
      id,
      completionId,
      error: error.message,
    })
  }
}

function* startSaga({ resourceType, id, completionId }) {
  try {
    const {
      compliance: { items, status },
      distribution,
      acl,
      item: { fields, responses },
    } = yield resourceType === TASKS
      ? call(complianceApi.startCompletion, resourceType, id, completionId)
      : call(complianceApi.startCompliance, resourceType, id)
    const { compliantUrn } = items[0]
    const completion = items.find(i =>
      completionId ? i._id === completionId : i.status !== FORWARDED,
    )
    const filteredCompletions = items.filter(i => i.status !== FORWARDED)
    if (resourceType === TASKS) {
      yield put({
        type: distributionActionTypes.DISTRIBUTION_LOADED,
        resourceType,
        resourceId: id,
        distribution,
      })
    }
    yield put({
      type: actionTypes.COMPLETION_STARTED,
      resourceType,
      id,
      status,
      acl,
      fields,
      responses,
      completion: {
        ...completion,
        ...(resourceType === FORMS && {
          formIdToSubmit: decodeUrn(compliantUrn).id,
        }),
      },
      completionIds: filteredCompletions.map(i => i._id),
    })
  } catch (error) {
    yield put({
      type: actionTypes.COMPLETION_START_REQUEST_FAILED,
      resourceType,
      id,
      error: error.message,
    })
  }
}

function* unassignCompletionSaga({ resourceType, id, completionId }) {
  try {
    const {
      compliance: { items, status },
      acl,
      item: { fields, responses },
    } = yield call(complianceApi.unassign, resourceType, id)
    yield put({
      type: actionTypes.COMPLETION_UNASSIGNED,
      resourceType,
      id,
      completion: items.find(i => i._id === completionId),
      status,
      acl,
      fields,
      responses,
    })
  } catch (error) {
    yield put({
      type: actionTypes.COMPLETION_UNASSIGN_FAILED,
      resourceType,
      id,
      error: error.message,
    })
  }
}

function* cancelCompletionSaga({ resourceType, id, completionId, reason }) {
  try {
    const {
      compliance: { items, status },
      acl,
      item: { fields, responses },
    } = yield call(
      complianceApi.cancelCompletion,
      resourceType,
      id,
      completionId,
      reason,
    )
    const completion =
      resourceType === FORMS
        ? items.find(i => decodeUrn(i.compliantUrn).id === id)
        : items.find(i => i._id === completionId)
    const groupName = yield select(selectGroupName, completion?.group)
    yield put({
      type: actionTypes.COMPLETION_CANCELLED,
      resourceType,
      id,
      completion,
      groupName,
      status,
      acl,
      fields,
      responses,
      completionIds: items.map(i => i._id),
    })
  } catch (e) {
    yield put({
      type: actionTypes.COMPLETION_CANCEL_REQUEST_FAILED,
      error: e.message,
      resourceType,
      id,
    })
  }
}

function* cancelComplianceSaga({ resourceType, id, reason }) {
  try {
    const {
      item,
      compliance: { items, status },
      acl,
    } = yield call(complianceApi.cancelCompliance, resourceType, id, reason)
    const { completionAcls, completionsByKey } = items.reduce(
      (acc, item) => {
        const { _id, acl } = item
        acc.completionsByKey[`${_id}`] = item
        acc.completionAcls[`${_id}/acls`] = acl
        return acc
      },
      { completionAcls: {}, completionsByKey: {} },
    )

    yield put({
      type: actionTypes.COMPLIANCE_CANCELLED,
      resourceType,
      id,
      completions: completionsByKey,
      status,
      completionAcls,
      item,
      acl,
    })
    const groupId = yield select(selectCurrentGroupId)
    yield put({
      type: documentActionTypes.DOCUMENT_FACETS_REQUESTED,
      resourceType,
      groupId,
    })
  } catch (e) {
    yield put({
      type: actionTypes.COMPLIANCE_CANCEL_REQUEST_FAILED,
      error: e.message,
      resourceType,
      id,
    })
  }
}

function* updateApproversSaga({ id, userIds, approvalType, resourceType }) {
  try {
    const currentUser = yield select(selectCurrentUser)
    const {
      compliance = {},
      acl,
      item: { fields, responses, compliance: itemCompliance = {} },
    } = yield call(
      complianceApi.editApprovers,
      resourceType,
      id,
      userIds,
      approvalType,
    )
    const { approvers = [] } = compliance
    const { approvers: itemApprovers } = itemCompliance
    yield put({
      type: actionTypes.COMPLIANCE_APPROVERS_UPDATED,
      id,
      approvers: normaliseApprovers(
        itemApprovers || approvers,
        currentUser._id,
      ),
      approvalType,
      resourceType,
    })

    // we need to refresh the completions as well as approvers cascade down
    if (resourceType !== FORMS) {
      const groups = yield select(selectAll)
      yield put({
        type: actionTypes.LOAD_COMPLETIONS_REQUESTED,
        id,
        resourceType,
        compliance,
        groups,
        acl,
        fields,
        responses,
      })
    }
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_COMPLIANCE_APPROVERS_FAILED,
      error: e.message,
      userIds,
      id,
      resourceType,
      approvalType,
    })
  }
}

function* updateCompletionApproversSaga({
  id,
  completionId,
  userIds,
  approvalType,
  resourceType,
}) {
  try {
    const currentUser = yield select(selectCurrentUser)
    const {
      compliance: { items, status },
      acl,
      item: { fields, responses },
    } = yield call(
      complianceApi.editCompletionApprovers,
      resourceType,
      id,
      completionId,
      userIds,
      approvalType,
    )
    const completion = items.find(i => i._id === completionId)
    yield put({
      type: actionTypes.COMPLETION_APPROVERS_UPDATED,
      id,
      acl,
      status,
      completion,
      currentUserId: currentUser._id,
      resourceType,
      fields,
      responses,
    })
  } catch (e) {
    yield put({
      type: actionTypes.UPDATE_COMPLETION_APPROVERS_FAILED,
      error: e.message,
      userIds,
      id,
      resourceType,
      approvalType,
      completionId,
    })
  }
}

function* addCompletionAttachmentsSaga({
  id,
  completionId,
  resourceType,
  attachments,
}) {
  try {
    const currentUser = yield select(selectCurrentUser)
    const {
      compliance: { items, status },
      acl,
      item: { fields, responses },
    } = yield call(
      complianceApi.addCompletionAttachments,
      resourceType,
      id,
      completionId,
      attachments,
    )
    const completion = items.find(i => i._id === completionId)
    yield put({
      type: actionTypes.COMPLETION_ATTACHMENTS_UPDATED,
      id,
      acl,
      status,
      completion,
      currentUserId: currentUser._id,
      resourceType,
      fields,
      responses,
    })
  } catch (e) {
    yield put({
      type: actionTypes.ADD_COMPLETION_ATTACHMENTS_FAILED,
      error: e.message,
      attachments,
      id,
      resourceType,
      completionId,
    })
  }
}

function* cleanupArchivedSaga({ resourceType, id }) {
  try {
    const {
      compliance: { status, items },
      acl,
    } = yield call(complianceApi.cleanupArchived, resourceType, id)
    yield put({
      type: actionTypes.COMPLIANCE_CLEANUP_COMPLETED,
      resourceType,
      id,
      status,
      acl,
      items,
    })
  } catch (error) {
    yield put({
      type: actionTypes.COMPLIANCE_CLEANUP_REQUEST_FAILED,
      resourceType,
      id,
      error: error.message,
    })
  }
}

function* removeCompletionAttachmentSaga({
  id,
  completionId,
  resourceType,
  attachmentId,
}) {
  try {
    const currentUser = yield select(selectCurrentUser)
    const {
      compliance: { items, status },
      acl,
      item: { fields, responses },
    } = yield call(
      complianceApi.deleteCompletionAttachment,
      resourceType,
      id,
      completionId,
      attachmentId,
    )
    const completion = items.find(i => i._id === completionId)
    yield put({
      type: actionTypes.COMPLETION_ATTACHMENTS_UPDATED,
      id,
      acl,
      status,
      completion,
      currentUserId: currentUser._id,
      resourceType,
      fields,
      responses,
    })
  } catch (e) {
    yield put({
      type: actionTypes.REMOVE_COMPLETION_ATTACHMENT_FAILED,
      error: e.message,
      attachmentId,
      id,
      resourceType,
      completionId,
    })
  }
}

function* approveCompletionSaga({ resourceType, id, completionId }) {
  try {
    const {
      compliance: { items, status },
      acl,
      item: { fields, responses },
    } = yield call(
      complianceApi.approveCompletion,
      resourceType,
      id,
      completionId,
    )
    if (resourceType === FORMS) {
      yield put(requestDocument(resourceType, id))
    }
    const completion =
      resourceType === FORMS
        ? items.find(i => decodeUrn(i.compliantUrn).id === id)
        : items.find(i => i._id === completionId)
    yield put({
      type: actionTypes.COMPLETION_APPROVED,
      resourceType,
      id,
      completion,
      status,
      acl,
      fields,
      responses,
      completionIds: items.map(i => i._id),
    })
  } catch (e) {
    yield put({
      type: actionTypes.COMPLETION_APPROVAL_FAILED,
      error: e.message,
      id,
      resourceType,
    })
  }
}

function* downloadBreakdownUploadsSaga({
  id,
  archiveName,
  resourceType,
  completionIds,
}) {
  try {
    let ids = []
    if (completionIds?.length) {
      // if a list of completion ids is provided, only download uploads for those completions
      ids = completionIds
    } else {
      // download uploads for all completions
      ids = yield select(selectCompletions, resourceType, id)
    }
    const completions = yield all(ids.map(id => select(selectCompletion, id)))
    const groupNames = yield all(
      completions.map(c => select(selectGroupName, c.group)),
    )
    const hydratedCompletions = completions.map((c, i) => {
      return {
        ...c,
        groupName: groupNames[i],
      }
    })
    // create a dictionary with completion id -> completion
    const allResources = keyBy(hydratedCompletions, c => c._id)

    // for each completionId, extract the uploads and key them by id
    const allUploads = (yield all(
      ids.map(id => select(selectCompletionUploads, id)),
    )).reduce((acc, uploads, idx) => {
      acc[ids[idx]] = uploads
      return acc
    }, {})

    // dispatch downloadFiles action, but instead of passing an array
    // of files ids we're going to pass a dictionary keyed by the group
    // and the value is the uploads ids for that recipient
    const mappedUploadsByGroup = mapKeys(allUploads, (value, key) => {
      return allResources[key].groupName
    })

    const bulkDownloadsEnabled = yield select(selectIsEnabled, 'bulk_download')
    if (bulkDownloadsEnabled) {
      yield put(
        queueFilesDownload(undefined, archiveName, mappedUploadsByGroup),
      )
    } else {
      yield put(downloadFiles(mappedUploadsByGroup, archiveName))
    }
  } catch (error) {
    yield put({
      type: actionTypes.RESOURCE_BREAKDOWN_UPLOADS_REQUEST_FAILED,
      id,
      resourceType,
      error: error.message,
    })
  }
}

function* updateComplianceFieldSaga({ resourceType, id, field, value }) {
  try {
    yield call(api.updateDocumentFields, resourceType, id, { [field]: value })
  } catch (error) {
    yield put({
      type: actionTypes.COMPLIANCE_FIELD_UPDATE_FAILED,
      resourceType,
      id,
      field,
      value,
      error: error.message,
    })
  }
}

function selectActions(state, id) {
  const completion = selectCompletion(state, id)
  const stats = selectStats(state, generateUrn(TASKS, id))
  const checkin = selectCheckin(state, TASKS, id)
  const {
    attachments: lastAttachmentsCheckin,
    approvals: lastApprovalsCheckin,
    comments: lastCommentsCheckin,
  } = checkin

  if (completion) {
    return {
      hasNewAttachments: hasNewAttachments(completion, lastAttachmentsCheckin),
      hasNewApprovals: hasNewApprovals(
        completion,
        lastApprovalsCheckin,
        selectCurrentUser(state)._id,
      ),
      hasNewComments: hasNewComments(stats, lastCommentsCheckin),
    }
  }

  return null
}

function* saga() {
  yield takeEvery(
    [
      actionTypes.COMPLETION_CANCELLED,
      actionTypes.COMPLETION_STARTED,
      actionTypes.COMPLETION_UNASSIGNED,
      actionTypes.COMPLETION_COMPLETED,
      actionTypes.COMPLETION_APPROVED,
      actionTypes.COMPLETION_REJECTED,
    ],
    recomputeResourceBreakdownSaga,
  )
  yield takeEvery(actionTypes.LOAD_COMPLETIONS_REQUESTED, loadCompletionsSaga)
  yield takeEvery(actionTypes.COMPLETION_COMPLETE_REQUESTED, completeSaga)
  yield takeEvery(
    actionTypes.COMPLIANCE_COMPLETE_REQUESTED,
    completeComplianceSaga,
  )
  yield takeEvery(actionTypes.COMPLETION_START_REQUESTED, startSaga)
  yield takeEvery(actionTypes.COMPLIANCE_CLEANUP_REQUESTED, cleanupArchivedSaga)
  yield takeEvery(
    actionTypes.COMPLETION_UNASSIGN_REQUESTED,
    unassignCompletionSaga,
  )
  yield takeEvery(
    actionTypes.UPDATE_COMPLIANCE_APPROVERS_REQUESTED,
    updateApproversSaga,
  )
  yield takeEvery(actionTypes.SUBMIT_COMPLETION_REQUESTED, submitCompletionSaga)
  yield takeEvery(
    actionTypes.APPROVE_COMPLETION_REQUESTED,
    approveCompletionSaga,
  )
  yield takeEvery(actionTypes.REJECT_COMPLETION_REQUESTED, rejectCompletionSaga)
  yield takeEvery(actionTypes.COMPLETION_CANCEL_REQUESTED, cancelCompletionSaga)
  yield takeEvery(actionTypes.COMPLIANCE_CANCEL_REQUESTED, cancelComplianceSaga)
  yield takeEvery(
    actionTypes.UPDATE_COMPLETION_APPROVERS_REQUESTED,
    updateCompletionApproversSaga,
  )
  yield takeEvery(
    actionTypes.ADD_COMPLETION_ATTACHMENTS_REQUESTED,
    addCompletionAttachmentsSaga,
  )
  yield takeEvery(
    actionTypes.REMOVE_COMPLETION_ATTACHMENT_REQUESTED,
    removeCompletionAttachmentSaga,
  )
  yield takeEvery(
    actionTypes.RESOURCE_BREAKDOWN_UPLOADS_REQUESTED,
    downloadBreakdownUploadsSaga,
  )
  yield takeEvery(
    actionTypes.UPDATE_COMPLIANCE_FIELD_REQUESTED,
    updateComplianceFieldSaga,
  )
}

export {
  actionTypes,
  completeCompletion,
  completeCompliance,
  startCompletion,
  unassignCompletion,
  updateApprovers,
  submitCompletion,
  approveCompletion,
  rejectCompletion,
  cancelCompletion,
  cancelCompliance,
  updateCompletionApprovers,
  addCompletionAttachments,
  removeCompletionAttachment,
  requestBreakdownUploads,
  reducer,
  selectComplianceStatus,
  selectCompletions,
  selectFilteredCompletions,
  selectCompletionsByGroup,
  selectCompletion,
  selectCompletionApprovers,
  selectCompletionReadOnlyApprovers,
  selectComplianceBreakdown,
  selectComplianceBreakdownFacets,
  selectComplianceEarliestSubmissionInReview,
  selectComplianceApprovers,
  selectComplianceApprovalType,
  selectComplianceReadOnlyApprovers,
  selectActions,
  selectComplianceStartedAt,
  selectComplianceCompletedAt,
  selectRelevantCompletion,
  selectComplianceLatestSubmission,
  selectCompletionUploads,
  selectAllBreakdownUploads,
  selectFormValidation,
  selectComplianceProgress,
  saga,
  loadCompletionsSaga,
  cancelCompletionSaga,
  submitCompletionSaga,
  cancelComplianceSaga,
  updateApproversSaga,
  rejectCompletionSaga,
  unassignCompletionSaga,
  approveCompletionSaga,
  recomputeResourceBreakdownSaga,
  selectComplianceCancellationReason,
  selectComplianceWorkflow,
  selectIncompleteComplianceAssignees,
  completeSaga,
  startSaga,
  cleanupCompliance,
  cleanupArchivedSaga,
  completeComplianceSaga,
  updateCompletionApproversSaga,
  addCompletionAttachmentsSaga,
  removeCompletionAttachmentSaga,
  downloadBreakdownUploadsSaga,
  updateComplianceField,
  updateComplianceFieldSaga,
}
