import transport from './transport'

async function fetchTaskNotes(taskId) {
  const response = await transport.get(`/tasks/${taskId}/notes`)
  return response.data
}

async function editNote(taskId, noteId, text) {
  const response = await transport.patch(`/tasks/${taskId}/notes/${noteId}`, {
    text,
  })
  return response.data
}

async function removeNote(taskId, noteId) {
  const response = await transport.delete(`/tasks/${taskId}/notes/${noteId}`)
  return response.data
}

async function addNote(taskId, note) {
  const response = await transport.post(`/tasks/${taskId}/notes`, { note })
  return response.data
}

async function removeAttachment(taskId, attachmentId) {
  const response = await transport.delete(
    `/tasks/${taskId}/attachments/${attachmentId}`,
  )
  return response.data
}

async function addAttachment(taskId, fileId) {
  const response = await transport.post(`/tasks/${taskId}/attachments`, {
    attachments: [fileId],
  })
  return response.data
}

async function rejectAttachment(taskId, attachmentId, rejectionReason) {
  const response = await transport.post(
    `/tasks/${taskId}/attachments/${attachmentId}/reject`,
    { rejectionReason },
  )
  return response.data
}

async function approveAttachment(taskId, attachmentId) {
  const response = await transport.post(
    `/tasks/${taskId}/attachments/${attachmentId}/approve`,
  )
  return response.data
}

async function removeUpload(taskId, uploadId) {
  const response = await transport.delete(
    `/tasks/${taskId}/uploads/${uploadId}`,
  )
  return response.data
}

async function addUpload(taskId, fileId) {
  const response = await transport.post(`/tasks/${taskId}/uploads`, {
    uploads: [fileId],
  })
  return response.data
}

async function sendReminderForTask(taskId, completionId, reminderReason) {
  const response = await transport.post(`/tasks/${taskId}/reminders`, {
    completionId,
    reminderReason,
  })
  return response.data
}

async function fetchTaskUploads(taskId) {
  const response = await transport.get(`/v2/tasks/${taskId}/uploads`)
  return response.data
}

export default {
  addAttachment,
  addNote,
  editNote,
  removeNote,
  fetchTaskNotes,
  removeAttachment,
  rejectAttachment,
  approveAttachment,
  addUpload,
  removeUpload,
  sendReminderForTask,
  fetchTaskUploads,
}
