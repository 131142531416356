import {
  createStore as createReduxStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import reduxCatch from 'redux-catch'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createSagaMiddleware from 'redux-saga'
import { disableTransport } from './api/transport'
import { reducer as config } from './config'
import { reducer as features } from './features'
import { reducer as session } from './session'
import { reducer as files, saga as fileSaga } from './files'
import { saga as tasksSaga, reducer as tasks } from './tasks'
import { saga as resourceSaga, reducer as resource } from './resource'
import {
  saga as distributionListsSaga,
  reducer as distributionLists,
} from './distributionLists'
import { saga as templatesSaga, reducer as templates } from './templates'
import { saga as taskNotesSaga, reducer as taskNotes } from './taskNotes'
import { saga as groupsSaga, reducer as groups } from './groups'
import { saga as checkinSaga, reducer as checkins } from './checkins'
import { saga as usersSaga, reducer as users } from './users'
import { saga as reportsSaga, reducer as reports } from './reports'
import { saga as rolesSaga, reducer as roles } from './roles'
import { saga as commentsSaga, reducer as comments } from './comments'
import { saga as bootstrapSaga, reducer as bootstrap } from './bootstrap'
import { reducer as markups } from './markups'
import { reducer as forms, saga as formsSaga } from './forms'
import { saga as notificationsSaga } from './notifications'
import {
  saga as distributionSaga,
  reducer as distribution,
} from './distribution'
import { reducer as notifications } from './notifications'
import { saga as eventsSaga, reducer as events } from './events'
import { reducer as completions, saga as complianceSaga } from './compliance'
import { reducer as connect, saga as connectSaga } from './connect'
import { reducer as documents, saga as documentsSaga } from './documents'
import { reducer as annotations, saga as annotationsSaga } from './annotations'
import { reducer as tags, saga as tagsSaga } from './tags'

const sagaMiddleware = createSagaMiddleware()

function chainReducers(...reducers) {
  return (state = {}, action) => {
    let newState = state
    reducers.forEach(reducer => {
      newState = reducer(newState, action)
    })
    return newState
  }
}

function createBasicStore(
  extraReducers = {},
  extraSagas = [],
  middlewares = [],
  initialState = {},
) {
  const composedMiddlewares = composeWithDevTools(
    applyMiddleware(sagaMiddleware, reduxCatch(console.error), ...middlewares),
  )

  const store = createReduxStore(
    combineReducers({
      config,
      files,
      features,
      session,
      tasks,
      resource,
      annotations,
      documents: chainReducers(documents, forms),
      events,
      distributionLists,
      templates,
      taskNotes,
      groups,
      checkins,
      users,
      reports,
      roles,
      comments,
      bootstrap,
      markups,
      notifications,
      completions,
      distribution,
      connect,
      tags,
      ...extraReducers,
    }),
    initialState,
    composedMiddlewares,
  )

  extraSagas.forEach(saga => sagaMiddleware.run(saga))

  return store
}

function applyReduxSagas() {
  const sagas = [
    tasksSaga,
    distributionSaga,
    distributionListsSaga,
    templatesSaga,
    taskNotesSaga,
    groupsSaga,
    checkinSaga,
    reportsSaga,
    rolesSaga,
    commentsSaga,
    usersSaga,
    bootstrapSaga,
    fileSaga,
    formsSaga,
    notificationsSaga,
    complianceSaga,
    resourceSaga,
    connectSaga,
    documentsSaga,
    eventsSaga,
    annotationsSaga,
    tagsSaga,
  ]

  sagas.forEach(saga => sagaMiddleware.run(saga))
}

function createStore(...args) {
  const store = createBasicStore(...args)
  applyReduxSagas()
  return store
}

function createStoreWithoutSagas(...args) {
  disableTransport()
  return createBasicStore(...args)
}

export default createStore

export { createStoreWithoutSagas, createStore }
