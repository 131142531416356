function normaliseId(obj) {
  if (obj && obj._id) {
    return obj._id
  }
  return obj
}

const createQueryKey = query =>
  Object.keys(query || {})
    .filter(k => k !== 'page' && k !== 'pageSize')
    .filter(key => query[key])
    .sort()
    .map(key => `${key}:${query[key]}`)
    .join(',')

const createQueryPages = (state, facet = 'all', query = {}, itemIds, total) => {
  const queryKey = createQueryKey(query)
  const key = `query/${facet}${
    queryKey && queryKey !== '' ? '/' : ''
  }${queryKey}`

  if (!query.page) {
    return {
      ...state,
      [key]: [...new Set((state[key] || []).concat(itemIds))],
      [`${key}/count`]: total,
    }
  }

  const pages = {
    ...state[`${key}/pages`],
    [query.page]: itemIds,
  }

  let buffer = []
  let lastPage = 0
  Object.keys(pages).forEach(k => {
    lastPage = k

    if (buffer.length > 0) {
      pages[k] = [...new Set([...buffer, ...pages[k]])]
    }

    buffer = []

    if (query.pageSize && pages[k].length > query.pageSize) {
      buffer = pages[k].slice(query.pageSize)
      pages[k] = pages[k].slice(0, query.pageSize)
    }
  })

  if (buffer.length > 0) {
    pages[parseInt(lastPage, 10) + 1] = buffer
  }

  return {
    ...state,
    [`${key}/count`]: total,
    [`${key}/pages`]: pages,
    [key]: [
      ...new Set(
        Object.keys(pages).reduce((acc, p) => {
          acc = acc.concat(pages[p])
          return acc
        }, []),
      ),
    ],
  }
}

function sortFilesInFacet(state, facetQueryKey) {
  if (!state.lastQuery.sort) {
    state[facetQueryKey] = state[facetQueryKey].sort((a, b) => {
      if (state[a].isFolder !== state[b].isFolder) {
        return state[a].isFolder ? -1 : 1
      }
      return 0
    })
  } else {
    const ord =
      state.lastQuery.sort && state.lastQuery.sort.startsWith('-') ? 1 : -1
    const sortParam =
      ord > 0 ? state.lastQuery.sort.substring(1) : state.lastQuery.sort

    state[facetQueryKey] = state[facetQueryKey].sort((a, b) => {
      if (state[a].isFolder === state[b].isFolder) {
        return state[a][sortParam] < state[b][sortParam] ? ord : -ord
      }

      if (state[a].isFolder) {
        return -1
      }

      return 1
    })
  }
}

const removeResourceFromQueryArray = (id, state, acc, key) => {
  const ids = [...state[key]]
  const indexToRemove = ids.indexOf(id)
  if (indexToRemove > -1) {
    ids.splice(indexToRemove, 1)
    acc[key] = ids
    acc[`${key}/count`] = state[`${key}/count`] - 1
  }
}

const removeResourceFromDateBucket = (id, state, acc, key) => {
  const resource = state[id]
  const buckets = state[key]
  if (resource?.dueDate) {
    const dueDate = resource.dueDate.substring(0, 10)
    const ids = [...buckets[dueDate]]
    const indexToRemove = ids.indexOf(id)
    if (indexToRemove > -1) {
      ids.splice(indexToRemove, 1)
      acc[key] = {
        ...buckets,
        [dueDate]: ids,
      }
      acc[`${key}/count`] = state[`${key}/count`] - 1
    }
  }
}

const removeResourceFromQueryKey = (
  state,
  facetsToRemoveFrom = [],
  id,
  facetsToAddTo = [],
) =>
  Object.keys(state).reduce((acc, key) => {
    if (key.startsWith('query/') && !key.endsWith('/count')) {
      if (facetsToRemoveFrom.some(f => key.includes(`/${f}`))) {
        if (key.endsWith('/pages')) {
          // if it's the pages object, remove the id from the right page
          acc[key] = Object.keys(state[key]).reduce((newPages, page) => {
            newPages[page] = state[key][page].filter(i => i !== id)
            return newPages
          }, {})
        } else if (Array.isArray(state[key])) {
          // a plain array is the list of task loaded for that facet/query
          removeResourceFromQueryArray(id, state, acc, key)
        } else {
          // it should be a date buckets object
          removeResourceFromDateBucket(id, state, acc, key)
        }
      } else if (facetsToAddTo.some(f => key.includes(`/${f}`))) {
        acc[key] = undefined
        acc[`${key}/count`] = undefined
      }
    }
    return acc
  }, {})

export {
  createQueryKey,
  normaliseId,
  createQueryPages,
  sortFilesInFacet,
  removeResourceFromQueryKey,
}
